import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { MAIN_ACCOUNT_TEXTS } from "constants/text.constants"

import { useState } from "react"

import { tMainAccountRegisterHealth } from "types/sharedTypes"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"

import { putWithBodyAndCsrf } from "services/apiService"

import Button from "components/atoms/button/Button"

type tRebuildRegisterButton = {
    data: tMainAccountRegisterHealth
    setPostError: React.Dispatch<React.SetStateAction<string>>
    setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>
}

const RebuildRegisterButton = ({ data, setPostError, setShowConfirmation }: tRebuildRegisterButton) => {
    const [updatingRegister, setUpdatingRegister] = useState(false)

    const rebuildRegister = (id: string) => {
        setUpdatingRegister(true)
        setPostError("")
        const body = { Id: id }

        prepareCsrfToken().then((csrfToken) =>
            putWithBodyAndCsrf(idMapper(api.rebuildRegisterViewsById, id), csrfToken, JSON.stringify(body))
                .then(() => {
                    setUpdatingRegister(false)

                    setUpdatingRegister(false)
                    setShowConfirmation(false)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    if (err.statusText === "Obehörig") setPostError(MAIN_ACCOUNT_TEXTS.UNATHORIZED)
                    else setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                    setUpdatingRegister(false)
                })
        )
    }

    return (
        <span>
            <Button onClick={() => rebuildRegister(data.Id)} isLoading={updatingRegister}>
                {MAIN_ACCOUNT_TEXTS.ADMINISTRATE_BUTTON_REBUILD}
            </Button>
        </span>
    )
}

export default RebuildRegisterButton
