import ReactModal from "react-modal"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_WAGE_GAP, REGISTER_TEXTS } from "constants/text.constants"

import { IboxplotDiagramData } from "types/sharedTypes"

import { useEffect, useMemo, useRef, useState } from "react"

import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf } from "services/apiService"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import SliderButton from "components/atoms/sliderButton/SliderButton"
import BoxplotDiagram from "components/cores/highcharts/boxplot/BoxplotDiagram"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import { ReactComponent as PDFIcon } from "assets/pdf.icon.svg"
import { ReactComponent as xMark } from "assets/xmark.icon.svg"

import prepareBoxplotDiagramDataSalaryDifference from "../utils/prepareBoxplotDiagramDataSalaryDifference"

type DiagramModalProps = {
    groupIds: string[]
    salaryType: number
    registryName: string
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    groupWithMajorityWomen: string[]
}

type tDiagramData = IboxplotDiagramData

const DiagramModal = ({
    groupIds,
    salaryType,
    registryName,
    showModal,
    setShowModal,
    groupWithMajorityWomen,
}: // matchingGroupsArray,
DiagramModalProps) => {
    const [fetchError, setFetchError] = useState("")
    const [postError, setPostError] = useState("")

    const [diagramData, setDiagramData] = useState<tDiagramData>()
    const [showMinorGridLines, setShowMinorGridLines] = useState(true)

    const [fetchingDiagramData, setFetchingDiagramData] = useState(true)
    const [downloadingPDF, setDownloadingPDF] = useState(false)

    const allRef = useRef<HTMLDivElement>(null)

    const salaryTypes = useMemo(() => [null, "Grundlön", null, null, "Fast lön", "Totallön"], [])
    const salaryTypeString = salaryTypes[salaryType] || ""

    // The reason why we use style = {} here is due to how exportPDF endpoint works, it will catch css styled with "style" but not className
    // We use separate .css files or global css through common.css ; style={} to be used ONLY when you need to export via this endpoint
    // When refactoring backend it would be a good idea to rewrite the functionality so it can work with both className and style.
    const downloadPDF = () => {
        const html: any = []
        let string = ""
        setDownloadingPDF(true)
        setPostError("")

        if (allRef.current) {
            const parent: { id: string }[] = [...allRef.current.children]
            parent.forEach((element) => {
                if (element.id !== "excludeFromPDF") html.push(element)
            })
            html.forEach((el: any) => {
                string += el.outerHTML
            })

            prepareCsrfToken().then((csrfToken) =>
                postWithBodyAndCsrf(api.analyzeSalaryExportPdf, csrfToken, JSON.stringify({ Htmltext: string }), true)
                    .then((response) => {
                        setDownloadingPDF(false)
                        const filename = "Löneskillnader kvinnor & män - Likvärdiga arbeten.pdf"

                        response.blob().then((blob: any) => {
                            const url = window.URL.createObjectURL(new Blob([blob]))
                            const link = document.createElement("a")
                            link.setAttribute("href", url)
                            link.setAttribute("download", filename)
                            document.body.appendChild(link)
                            link.click()
                        })
                    })
                    .catch(() => {
                        setDownloadingPDF(false)
                        setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                    })
            )
        }
    }

    const getMedian = (data: number[][]) => {
        const medianArray: number[][] = []
        // putting the arrays related to the women dominate group inside medianArray
        data.forEach((object) => {
            if (object[0] === 0) {
                medianArray.push(object)
            }
        })

        if (medianArray.length === 1) {
            const item = medianArray[0]
            if (item.length === 2) {
                return item[1]
            }
            if (item.length === 6) {
                return item[3]
            }
            if (item.length === 8) {
                return item[4]
            }
        }

        medianArray.sort()

        if (medianArray.length === 2) {
            const item1 = medianArray[0]
            const item2 = medianArray[1]
            return (item1[1] + item2[1]) / 2
        }
        if (medianArray.length === 3) {
            const item1 = medianArray[1]
            return item1[1]
        }
        if (medianArray.length === 4) {
            const item1 = medianArray[1]
            const item2 = medianArray[2]
            return (item1[1] + item2[1]) / 2
        }
        if (medianArray.length === 5) {
            const item1 = medianArray[2]
            return item1[1]
        }
        if (medianArray.length === 6) {
            const item1 = medianArray[2]
            const item2 = medianArray[3]
            return (item1[1] + item2[1]) / 2
        }
        if (medianArray.length === 7) {
            const item1 = medianArray[3]
            return item1[1]
        }
        if (medianArray.length === 8) {
            const item1 = medianArray[3]
            const item2 = medianArray[4]
            return (item1[1] + item2[1]) / 2
        }
        return null
    }

    useEffect(() => {
        const convertDiagramData = (diagramDataPar: tDiagramData) => {
            const newDiagramData = diagramDataPar
            const median = getMedian(newDiagramData.Data)

            if (median !== null) {
                const data: number[][] = []
                newDiagramData.Data.forEach((item) => {
                    const newItem = [item[0]]
                    // eslint-disable-next-line no-plusplus
                    for (let i = 1; i < item.length; i++) {
                        const Item = parseFloat((item[i] / median).toFixed(4))
                        newItem.push(Item)
                    }
                    data.push(newItem)
                })
                newDiagramData.Data = data

                return newDiagramData
            }

            return newDiagramData
            // self.drawBoxPlotDigram(diagramData);
        }

        const body = { GroupIds: groupIds, SalaryType: salaryType, DiagramType: 1 }
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.analyzeSalaryDiagram, csrfToken, JSON.stringify(body))
                .then((response) => {
                    // setDiagramData(JSON.parse(response.DiagramData))
                    const chartTitle = ANALYZE_WAGE_GAP.S7_DIAGRAM_TITLE
                    const yAxisTitle = `Normaliserad ${salaryTypes[salaryType] || ""}`
                    const preparedDiagramData = prepareBoxplotDiagramDataSalaryDifference(
                        response.DiagramDataList,
                        chartTitle,
                        yAxisTitle,
                        groupWithMajorityWomen[0]
                    )

                    setDiagramData(convertDiagramData(preparedDiagramData))
                    setFetchingDiagramData(false)
                    setFetchError("")
                })
                .catch((err) => {
                    setFetchingDiagramData(false)
                    setFetchError(`${ANALYZE_WAGE_GAP.S7_DIAGRAM_MODAL_ERROR}${err.text()}`)
                })
        )
    }, [groupIds, salaryType, salaryTypes, groupWithMajorityWomen])

    return (
        <ReactModal
            appElement={document.getElementById("root") as HTMLElement}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel={ANALYZE_WAGE_GAP.S7_DIAGRAM_MODAL_CONTENT}
        >
            <div className="headingAndCloseButtonFlex mb8">
                <h1>{ANALYZE_WAGE_GAP.S7_DIAGRAM_MODAL_HEADING}</h1>
                <Button iconRight onClick={() => setShowModal(false)} Icon={xMark}>
                    {ANALYZE_WAGE_GAP.BUTTON_CLOSE}
                </Button>
            </div>
            {fetchingDiagramData && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {postError && (
                <div className="mb16">
                    <Error>{postError}</Error>
                </div>
            )}
            {!fetchingDiagramData && !fetchError && (
                <div ref={allRef}>
                    <div style={{ marginBottom: 8 }}>
                        <div style={{ display: "flex", gap: 16 }}>
                            <p style={{ display: "flex", flexWrap: "wrap", marginRight: 8 }}>
                                <strong style={{ paddingRight: 4 }}>{ANALYZE_WAGE_GAP.S7_DIAGRAM_REGISTER}</strong>
                                {registryName}
                            </p>
                            <p style={{ display: "flex", flexWrap: "wrap" }}>
                                <strong style={{ paddingRight: 4 }}>{ANALYZE_WAGE_GAP.S7_DIAGRAM_SALARY_TYPE}</strong>
                                {salaryTypeString}
                            </p>
                        </div>
                    </div>
                    <div id="excludeFromPDF" className="flex gap32 jc-space-between mb8">
                        <div className="flex ai-flex-start">
                            <SliderButton checked={showMinorGridLines} setChecked={setShowMinorGridLines}>
                                {REGISTER_TEXTS.MINOR_GRID}
                            </SliderButton>
                        </div>
                        <Button Icon={PDFIcon} onClick={downloadPDF} isLoading={downloadingPDF}>
                            {ANALYZE_WAGE_GAP.DIAGRAM_MODAL_EXPORT_PDF}
                        </Button>
                    </div>
                    {!fetchingDiagramData && diagramData && diagramData.Categories.length && (
                        <div className="maxWidth1000">
                            <BoxplotDiagram
                                diagramData={diagramData}
                                showMinorGridLines={showMinorGridLines}
                                normalizedSalaries
                            />
                        </div>
                    )}
                </div>
            )}
        </ReactModal>
    )
}

export default DiagramModal
