/* eslint-disable react/no-this-in-sfc */
import "./Diagram.css"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { Employees, IRegister, tScatterDiagramData } from "types/sharedTypes"

import { useEffect, useMemo, useRef, useState } from "react"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"
import getAmountMenWomen from "utils/Highcharts/getAmountMenWomen"
import getSsnAndNameFromChartData from "utils/Highcharts/getSsnAndNameFromChartData"

import { postWithBodyAndCsrf } from "services/apiService"

import Highcharts from "highcharts"
import Exporting from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import SliderButton from "components/atoms/sliderButton/SliderButton"
import SelectRegistry from "components/cores/selectRegistry/SelectRegistry"
import defaultSetOptions from "components/cores/highcharts/highchartsDefaultSetOptions"

import { ReactComponent as PDFIcon } from "assets/pdf.icon.svg"

type DiagramModalProps = {
    groupInfo: { Name: string; Id: string }
    salaryType: number
    registryName: string
    diagramData: tScatterDiagramData[]
}

const Diagram = ({ groupInfo, salaryType, registryName, diagramData }: DiagramModalProps) => {
    HighchartsMore(Highcharts)
    Exporting(Highcharts)
    Highcharts.setOptions(defaultSetOptions)

    const amounts = getAmountMenWomen(diagramData[3])

    const RegistryId = "0000-0000-0000-0000" // sessionStorage? Update through button?

    const [fetchError, setFetchError] = useState("")
    const [postError, setPostError] = useState("")

    // const [employees, setEmployees] = useState<Employees[]>([])
    const [salaryIncreaseRegistries, setSalaryIncreaseRegistries] = useState<IRegister[]>([])

    const [fetchingEmployeesInAGroup, setFetchingEmployeesInAGroup] = useState(true)
    const [downloadingPDF, setDownloadingPDF] = useState(false)

    const allRef = useRef<HTMLDivElement>(null)

    const [showMinorGridLines, setShowMinorGridLines] = useState(true)
    const [showGrouped, setShowGrouped] = useState(false)

    const salaryTypes = useMemo(() => [null, "Grundlön", null, null, "Fast lön", "Totallön"], [])
    const salaryTypeString = salaryTypes[salaryType] || ""

    const [selectedGrouping, setSelectedGrouping] = useState("Anställda")
    const diagramGrouping = [
        { Key: 1, Value: "Anställda" },
        { Key: 0, Value: "Åldersgrupp" },
        { Key: 2, Value: "Omfattning" },
        { Key: 3, Value: "Anställningstid (År)" },
        { Key: 4, Value: "Kön" },
        { Key: 5, Value: "Ålder" },
        { Key: 1, Value: "Löneförändring" },
    ]
    const groupingOptions = ["Åldersgrupp", "Omfattning", "Anställningstid (År)", "Kön", "Ålder", "Löneförändring"]
    const index = diagramGrouping.findIndex((el) => el.Value === selectedGrouping)

    const [comparisonRegistryId, setComparisonRegistryId] = useState("")

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        chart: {
            animation: false,
            type: "scatter",
        },
        title: { text: ANALYZE_WAGE_GAP.S3_DIAGRAM_TITLE },
        yAxis: {
            title: {
                text: ANALYZE_WAGE_GAP.S3_DIAGRAM_Y_AXIS_TITLE,
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
            minorTickInterval: showMinorGridLines ? "auto" : undefined,
        },
        xAxis: {
            categories: diagramData[diagramGrouping[index].Key].Catagories,
            title: {
                text: diagramGrouping[index].Value,
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
        },
        tooltip: {
            formatter(this: Highcharts.TooltipFormatterContextObject) {
                const x = this.x as number
                const y = this.y as number

                const seriesDef = [
                    // here we use spread operator to make a copy of the original diagramData item, to prevent data manipulation by highchart
                    { data: [...diagramData[diagramGrouping[index].Key].SalariesMen] },
                    { data: [...diagramData[diagramGrouping[index].Key].SalariesWomen] },
                ]
                const categories = diagramData[diagramGrouping[index].Key].Catagories

                const { ssn, name } = getSsnAndNameFromChartData(seriesDef, x, y, categories)
                return `PNR: ${ssn}<br> Namn: ${name} <br><br> Lön: ${this.y} kr`
            },
        },
        series: [
            {
                id: "1",
                type: "scatter",
                name: `Man ${amounts.Men}%`,
                // here we use spread operator to make a copy of the original diagramData item, to prevent data manipulation by highchart
                data: [...diagramData[diagramGrouping[index].Key].SalariesMen],
            },
            {
                id: "2",
                type: "scatter",
                name: `Kvinna ${amounts.Women}%`,
                data: [...diagramData[diagramGrouping[index].Key].SalariesWomen],
            },
        ],
        credits: {
            enabled: false,
        },
    })

    const toggleGrouping = () => {
        setShowGrouped(!showGrouped)
        setSelectedGrouping(showGrouped ? "Anställda" : "Åldersgrupp")
    }

    // The reason why we use style = {} here is due to how exportPDF endpoint works, it will catch css styled with "style" but not className
    // We use separate .css files or global css through common.css ; style={} to be used ONLY when you need to export via this endpoint
    // When refactoring backend it would be a good idea to rewrite the functionality so it can work with both className and style.
    const downloadPDF = () => {
        const html: any = []
        let string = ""
        setDownloadingPDF(true)
        setPostError("")

        if (allRef.current) {
            const parent: { id: string }[] = [...allRef.current.children]
            parent.forEach((element) => {
                if (element.id !== "excludeFromPDF") html.push(element)
            })
            html.forEach((el: any) => {
                string += el.outerHTML
            })

            prepareCsrfToken().then((csrfToken) =>
                postWithBodyAndCsrf(api.analyzeSalaryExportPdf, csrfToken, JSON.stringify({ Htmltext: string }), true)
                    .then((response) => {
                        setDownloadingPDF(false)
                        const filename = "Löneskillnader kvinnor & män - Lika arbete.pdf"

                        response.blob().then((blob: any) => {
                            const url = window.URL.createObjectURL(new Blob([blob]))
                            const link = document.createElement("a")
                            link.setAttribute("href", url)
                            link.setAttribute("download", filename)
                            document.body.appendChild(link)
                            link.click()
                        })
                    })
                    .catch(() => {
                        setDownloadingPDF(false)
                        setPostError(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
                    })
            )
        }
    }

    // Uppdate data dynamically
    useEffect(() => {
        // get pervios options from setState, return copy of it with only property yAxis updated
        setChartOptions((options) => ({
            ...options,
            yAxis: {
                minorTickInterval: showMinorGridLines ? "auto" : undefined,
            },
        }))
    }, [showMinorGridLines])

    // körs vid en rendering, och när man togglar "visa grupperat"
    useEffect(() => {
        // get previous options from setState, return copy of it with only dynamic property updated
        setChartOptions((options) => ({
            ...options,
            xAxis: {
                categories: diagramData[diagramGrouping[index].Key].Catagories,
                title: { text: selectedGrouping },
            },
            tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                    // const x = typeof this.x === "number" ? this.x : -1
                    // const y = typeof this.y === "number" ? this.y : -1
                    const x = this.x as number
                    const y = this.y as number

                    const seriesDef = [
                        { data: diagramData[diagramGrouping[index].Key].SalariesMen },
                        { data: diagramData[diagramGrouping[index].Key].SalariesWomen },
                    ]
                    const categories = diagramData[diagramGrouping[index].Key].Catagories

                    const { ssn, name } = getSsnAndNameFromChartData(seriesDef, x, y, categories)
                    return `PNR: ${ssn}<br> Namn: ${name} <br><br> Lön: ${this.y} kr`
                },
            },
            series: [
                { type: "scatter", data: diagramData[diagramGrouping[index].Key].SalariesMen },
                {
                    type: "scatter",
                    data: diagramData[diagramGrouping[index].Key].SalariesWomen,
                },
            ],
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagramData, selectedGrouping])

    useEffect(() => {
        const body = {
            GroupName: groupInfo.Name,
            PreviousRegistryId: comparisonRegistryId,
            GroupId: groupInfo.Id,
            SalaryType: salaryType,
        }

        const url = api.analyzeWageGapRegistryComparison
            .replace(":groupId", groupInfo.Id)
            .replace(":previousRegistryId", comparisonRegistryId)
        if (comparisonRegistryId)
            prepareCsrfToken().then((csrfToken) =>
                postWithBodyAndCsrf(url, csrfToken, JSON.stringify(body))
                    .then((scatterData: tScatterDiagramData) => {
                        // update categories & data series
                        setChartOptions((options) => ({
                            ...options,
                            xAxis: {
                                categories: scatterData.Catagories,
                            },
                            tooltip: {
                                formatter(this: Highcharts.TooltipFormatterContextObject) {
                                    const x = this.x as number
                                    const y = this.y as number

                                    const seriesDef = [
                                        { data: scatterData.SalariesMen },
                                        { data: scatterData.SalariesWomen },
                                    ]
                                    const categories = scatterData.Catagories

                                    const { ssn, name } = getSsnAndNameFromChartData(seriesDef, x, y, categories)
                                    return `PNR: ${ssn}<br> Namn: ${name} <br><br> Lön: ${this.y} kr`
                                },
                            },
                            series: [
                                { type: "scatter", data: scatterData.SalariesMen },
                                {
                                    type: "scatter",
                                    data: scatterData.SalariesWomen,
                                },
                            ],
                        }))
                        setFetchError("")
                    })
                    .catch(() => {
                        setFetchError(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
                    })
            )
    }, [comparisonRegistryId, groupInfo, salaryType])
    useEffect(() => {
        // const body = { GroupName: groupInfo.Name, RegistryId }
        const body = { GroupName: groupInfo.Name }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(
                idMapper(api.analyzeSalaryIncreaseRegistries, groupInfo.Id),
                csrfToken,
                JSON.stringify(body)
            )
                .then((registries) => {
                    setSalaryIncreaseRegistries(registries)
                    setFetchError("")
                })
                .catch((err) => {
                    setFetchError(`${ANALYZE_WAGE_GAP.S3_DIAGRAM_FETCH_SALARY_REGISTERS_ERROR}${err.text()}`) // fel error
                })
        )
    }, [groupInfo])

    return (
        <div ref={allRef}>
            {fetchError && (
                <div id="excludeFromPDF" className="mb16 mt10">
                    <Error>{fetchError}</Error>
                </div>
            )}
            {postError && (
                <div id="excludeFromPDF" className="mb16 mt10">
                    <Error>{postError}</Error>
                </div>
            )}
            <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
                <p style={{ display: "flex", flexWrap: "wrap", marginRight: 8 }}>
                    <strong style={{ paddingRight: 4 }}>{ANALYZE_WAGE_GAP.S3_DIAGRAM_REGISTER}</strong>
                    {registryName}
                </p>
                <p style={{ display: "flex", flexWrap: "wrap", marginRight: 8 }}>
                    <strong style={{ paddingRight: 4 }}>{ANALYZE_WAGE_GAP.S3_DIAGRAM_GROUP}</strong>
                    {groupInfo.Name}
                </p>
                <p style={{ display: "flex", flexWrap: "wrap", marginRight: 8 }}>
                    <strong style={{ paddingRight: 4 }}>{ANALYZE_WAGE_GAP.S3_DIAGRAM_SALARY_TYPE}</strong>
                    {salaryTypeString}
                </p>
            </div>
            <div id="excludeFromPDF" className="flex wrap gap36 mb16">
                <div className="sliderButtonsContainer">
                    <SliderButton checked={showMinorGridLines} setChecked={setShowMinorGridLines}>
                        {ANALYZE_WAGE_GAP.DIAGRAM_MODAL_MINOR_GRID}
                    </SliderButton>
                    <SliderButton checked={showGrouped} setChecked={toggleGrouping}>
                        {ANALYZE_WAGE_GAP.DIAGRAM_MODAL_SHOW_GROUPED}
                    </SliderButton>
                </div>
                {showGrouped && (
                    <select
                        onChange={(e) => setSelectedGrouping(e.target.value)}
                        name="select"
                        id="select"
                        className="registryDropdown selectMemberSelect"
                    >
                        {groupingOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                )}
                {selectedGrouping === "Löneförändring" && (
                    <SelectRegistry
                        registries={salaryIncreaseRegistries}
                        setRegistryId={setComparisonRegistryId}
                        name={ANALYZE_WAGE_GAP.DIAGRAM_MODAL_LABEL}
                        noDefaultValue
                    />
                )}
                <Button Icon={PDFIcon} onClick={downloadPDF} isLoading={downloadingPDF}>
                    {ANALYZE_WAGE_GAP.DIAGRAM_MODAL_EXPORT_PDF}
                </Button>
            </div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default Diagram
