import { useContext, useEffect, useState } from "react"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_WAGE_GAP, REGISTER_TEXTS } from "constants/text.constants"

import { dispatchNumber, dispatchString, wageGapSavedSelection } from "types/sharedTypes"

import { deleteWithBodyAndCsrf } from "services/apiService"
import { fetchRegistries, fetchSavedSelections } from "services/fetchers"

import { RegistriesContext } from "context/RegistriesContext"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"

import Error from "components/atoms/error/Error"
import Warning from "components/atoms/warning/Warning"
import Confirmation from "components/atoms/confirmation/Confirmation"
import SelectRegistry from "components/cores/selectRegistry/SelectRegistry"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import SelectionsTable from "components/cores/selectionsTable/SelectionsTable"

interface IWageGapRegistriesAndSelections {
    selectedRegistryId: string
    setSelectedRegistryId: dispatchString
    setSelectedSalaryType: dispatchNumber
    analysisId: string
    setAnalysisId: dispatchString
    setActiveStep: dispatchNumber
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
}

const WageGapRegistriesAndSelections = ({
    selectedRegistryId,
    setSelectedRegistryId,
    setSelectedSalaryType,
    analysisId,
    setAnalysisId,
    setActiveStep,
    setActiveSteps,
}: IWageGapRegistriesAndSelections) => {
    const { registries, setRegistries, registriesFetched, setRegistriesFetched } = useContext(RegistriesContext)
    const [noRegisters, setNoRegisters] = useState(!registries.length && registriesFetched)

    const [fetchError, setFetchError] = useState("")

    const [fetchingRegistries, setFetchingRegistries] = useState(!registries.length)
    const [savedSelection, setSavedSelection] = useState<wageGapSavedSelection[]>([])
    const [fetchingSavedSelections, setFetchingSavedSelections] = useState(true)

    const [showWarning, setShowWarning] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const removeCurrentSelection = (id: string) => {
        setAnalysisId(id)
        setPostError("")
        setShowConfirmation(false)
        setShowWarning(true)
    }

    const chooseCurrentSelection = (id: string, state: number, salaryType: number) => {
        setAnalysisId(id)
        const activeSteps = Array.from({ length: state }, (_, i) => i + 1)
        setSelectedSalaryType(salaryType)
        setActiveSteps(activeSteps)
        setActiveStep(state)
    }

    const deleteSelection = (Id: string) => {
        prepareCsrfToken().then((csrfToken) =>
            deleteWithBodyAndCsrf(
                idMapper(api.analyzeDeleteWageGapSelectionById, Id),
                csrfToken,
                JSON.stringify({ Id })
            )
                .then(() => {
                    setSavedSelection((prevState) => {
                        const index = prevState.findIndex((item) => item.Id === Id)
                        if (index > -1) {
                            prevState.splice(index, 1)
                        }
                        return prevState
                    })
                    setPostError("")
                    setShowWarning(false)
                    setShowConfirmation(true)
                })
                .catch((err) => {
                    setShowWarning(false)
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    useEffect(() => {
        if (!registries.length && !registriesFetched)
            fetchRegistries(
                setRegistries,
                setNoRegisters,
                setSelectedRegistryId,
                setFetchingRegistries,
                setRegistriesFetched,
                setFetchError
            )
    }, [registries, setRegistries, registriesFetched, setRegistriesFetched, setSelectedRegistryId])

    useEffect(() => {
        if (!noRegisters && selectedRegistryId)
            fetchSavedSelections(selectedRegistryId, setSavedSelection, setFetchingSavedSelections, setFetchError)
        if (noRegisters && selectedRegistryId) setFetchingSavedSelections(false)
    }, [selectedRegistryId, noRegisters])

    return (
        <div>
            {(fetchingSavedSelections || fetchingRegistries) && !fetchError && !noRegisters && <ContentSkeleton />}
            {noRegisters && <p>{REGISTER_TEXTS.NO_REGISTERS}</p>}
            {registries && !noRegisters && registriesFetched && (
                <div>
                    <div>
                        <h2>{ANALYZE_WAGE_GAP.S1_H2}</h2>
                        <SelectRegistry
                            registries={registries}
                            setRegistryId={setSelectedRegistryId}
                            defaultValue={selectedRegistryId}
                        />
                    </div>
                    {showWarning && analysisId && (
                        <Warning
                            onClick={() => deleteSelection(analysisId)}
                            showWarning={setShowWarning}
                            variant="delete"
                        >
                            {ANALYZE_WAGE_GAP.S1_WARNING_DELETE_SELECTION}
                        </Warning>
                    )}
                    {showConfirmation && (
                        <div className="mt32">
                            <Confirmation>{ANALYZE_WAGE_GAP.S1_CONFIRMATION_DELETED_SELECTION}</Confirmation>
                        </div>
                    )}
                    {postError && (
                        <div className="mt32">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    {!!savedSelection.length && !fetchingSavedSelections && (
                        <div>
                            <h3>{ANALYZE_WAGE_GAP.S1_H3}</h3>
                            <SelectionsTable
                                selection={ANALYZE_WAGE_GAP.S1_TH1}
                                created={ANALYZE_WAGE_GAP.S1_TH2}
                                administrate={ANALYZE_WAGE_GAP.S1_TH3}
                                savedSelections={savedSelection}
                                chooseCurrentSelection={chooseCurrentSelection}
                                removeCurrentSelection={removeCurrentSelection}
                            />
                        </div>
                    )}
                    {!savedSelection.length && !fetchingSavedSelections && <p>{ANALYZE_WAGE_GAP.S1_NO_SELECTIONS}</p>}
                </div>
            )}
        </div>
    )
}

export default WageGapRegistriesAndSelections
