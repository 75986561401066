import "./LoginPage.css"

import api from "constants/endpoints.constants"
import { LOGIN_TEXTS } from "constants/text.constants"

import { useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"

import Button from "components/atoms/button/Button"

type LoginPageProps = {
    setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
}

interface otpError {
    attempts: boolean
    attemptsLeft: number
    lockedUntil: string | null
    type: boolean
    username: string
}

const LoginPage = ({ setLoggedIn }: LoginPageProps) => {
    const [otpSent, setOtpSent] = useState(false)

    const [email, setEmail] = useState("")
    const [pwrd, setPassword] = useState("")
    const [otpToken, setOtp] = useState("")
    const [loginSessionId, setLoginSid] = useState("")

    const [errorMessage, setErrorMessage] = useState("")
    const [resetPasswordReminderMessage, setResetPasswordReminderMessage] = useState("")
    const [showResendOtpButton, setShowResendOtpButton] = useState(false)
    const [loggingIn, setLoggingIn] = useState(false)

    const otpInputRef = useRef<HTMLInputElement | null>(null)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoggingIn(true)
        fetch(api.login, {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                username: email,
                password: pwrd,
            }),
        })
            .then((response) => {
                if (response.ok) return response.json()
                return Promise.reject(response)
            })
            .then((loginResponse) => {
                setErrorMessage("")
                // set otp visible if it is present in response, this is only on when LDAP is down and BE returns it
                if (loginResponse.oneTimePassword) setErrorMessage(loginResponse.oneTimePassword)
                setResetPasswordReminderMessage("")
                setLoginSid(loginResponse.loginSessionId)
                setOtpSent(true)
                setLoggingIn(false)
            })
            .catch((error) => {
                setLoggingIn(false)
                if (error)
                    error.json().then((json: otpError) => {
                        const { attemptsLeft, lockedUntil } = json
                        if (lockedUntil) {
                            const lockedDate = lockedUntil.slice(0, 10)
                            const lockedTime = lockedUntil.slice(11, 16)
                            setErrorMessage(`${LOGIN_TEXTS.ERROR_LOCKED} ${lockedDate} ${lockedTime}`)
                            setResetPasswordReminderMessage(LOGIN_TEXTS.RESET_PASSWORD_REMINDER)
                        } else if (attemptsLeft > 0) {
                            setErrorMessage(
                                `${LOGIN_TEXTS.ERROR_CREDENTIALS} ${attemptsLeft} ${LOGIN_TEXTS.ERROR_CREDENTIALS_2}`
                            )
                        } else {
                            setErrorMessage(LOGIN_TEXTS.ERROR_GENERIC)
                        }
                    })
                if (error.status === 404) setErrorMessage(LOGIN_TEXTS.ERROR_GENERIC)
            })
    }

    const handleOtp = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoggingIn(true)
        fetch(api.submitOtp, {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                otp: otpToken,
                loginSessionId,
            }),
        })
            .then((response) => {
                if (response.ok) return response.json()
                return Promise.reject(response)
            })
            .then((data: { accessToken: string; refreshToken: string }) => {
                setLoggingIn(false)
                setErrorMessage("")
                setShowResendOtpButton(false)

                setLoggedIn(true)
                localStorage.setItem("username", email)
                localStorage.setItem("authToken", data.accessToken)
                localStorage.setItem("refreshToken", data.refreshToken)
                setShowResendOtpButton(false)
            })
            .catch((error) => {
                setShowResendOtpButton(false)
                setLoggingIn(false)

                if (error)
                    error.json().then((json: otpError) => {
                        const { attemptsLeft, lockedUntil } = json
                        if (lockedUntil) {
                            const lockedDate = lockedUntil.slice(0, 10)
                            const lockedTime = lockedUntil.slice(11, 16)
                            setErrorMessage(`${LOGIN_TEXTS.ERROR_LOCKED} ${lockedDate} ${lockedTime}`)
                            setResetPasswordReminderMessage(LOGIN_TEXTS.RESET_PASSWORD_REMINDER)
                        } else if (attemptsLeft > 0) {
                            setErrorMessage(
                                `${LOGIN_TEXTS.ERROR_WRONG_OTP} ${attemptsLeft} ${LOGIN_TEXTS.ERROR_CREDENTIALS_2}`
                            )
                            setShowResendOtpButton(true)
                        } else {
                            setErrorMessage(LOGIN_TEXTS.ERROR_GENERIC)
                        }
                    })
                if (error.status === 404) setErrorMessage(LOGIN_TEXTS.ERROR_GENERIC)
            })
    }

    const resendOtp = () => {
        setLoggingIn(true)
        fetch(api.resendOtp, { method: "POST" })
            .then((response) => {
                if (response.ok) return response.text()
                return Promise.reject(response)
            })
            .then(() => {
                setLoggingIn(false)
                setErrorMessage("")
                setResetPasswordReminderMessage("")
                setOtpSent(true)
                setShowResendOtpButton(false)
            })
            .catch((error) => {
                setLoggingIn(false)
                window.location.reload()
                if (error) setErrorMessage(LOGIN_TEXTS.ERROR_GENERIC)
            })
    }

    useEffect(() => {
        if (otpSent && otpInputRef.current) {
            otpInputRef.current.focus()
        }
    }, [otpSent])

    return (
        <div className="loginPageContainer">
            <h1 className="loginHeading">{LOGIN_TEXTS.LOGIN_H1}</h1>
            {errorMessage && (
                <div className="errorBox">
                    <p>{errorMessage}</p>
                </div>
            )}
            {resetPasswordReminderMessage && (
                <div className="reminderBox">
                    <p>{resetPasswordReminderMessage}</p>
                </div>
            )}
            {showResendOtpButton && otpSent && (
                <div className="reminderBox">
                    <Button onClick={() => resendOtp()} isLoading={loggingIn}>
                        {LOGIN_TEXTS.BUTTON_RESEND_OTP}
                    </Button>
                </div>
            )}
            {!otpSent && (
                <form method="post" onSubmit={handleSubmit}>
                    <div className="labelInputContainer">
                        <label htmlFor="username">{LOGIN_TEXTS.USERNAME_LABEL}</label>
                        <input
                            type="email"
                            name="username"
                            id="username"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="labelInputContainer">
                        <label htmlFor="password">{LOGIN_TEXTS.PASSWORD_LABEL}</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="buttonLinkContainer">
                        <Link to="/passwordReset">{LOGIN_TEXTS.FORGOT_PASSWORD_LINK}</Link>
                        <Button type="submit" isLoading={loggingIn}>
                            {LOGIN_TEXTS.BUTTON_LOGIN}
                        </Button>
                    </div>
                </form>
            )}
            {otpSent && (
                <form method="post" onSubmit={handleOtp}>
                    <div className="labelInputContainer">
                        <label htmlFor="otp">{LOGIN_TEXTS.OTP_LABEL}</label>
                        <input
                            type="password"
                            name="otp"
                            id="otp"
                            onChange={(e) => setOtp(e.target.value)}
                            required
                            ref={otpInputRef}
                        />
                    </div>
                    <div className="buttonLinkContainer">
                        <Link to="/passwordReset">{LOGIN_TEXTS.FORGOT_PASSWORD_LINK}</Link>
                        <Button type="submit" isLoading={loggingIn}>
                            {LOGIN_TEXTS.BUTTON_LOGIN}
                        </Button>
                    </div>
                </form>
            )}
        </div>
    )
}
export default LoginPage
