import "./Navigation.css"

import { menuAccordion, submenu } from "types/sharedTypes"

import { useContext, useEffect, useState } from "react"

import { useLocation } from "react-router-dom"

import menuBuilder from "utils/menuBuilder"
import { fetchRoles } from "services/fetchers"

import RolesContext from "context/RolesContext"

import MenuSkeleton from "components/atoms/menuSkeleton/MenuSkeleton"

import MenuAccordion from "./accordion/Accordion"
import MenuItem from "./accordionItem/AccordionItem"

const Navigation2 = () => {
    const { roles, setRoles } = useContext(RolesContext)
    const { pathname } = useLocation()

    const [menu, setMenu] = useState<menuAccordion[]>(menuBuilder(roles))
    const [fetchingUserRoles, setFetchingUserRoles] = useState(!roles.rolesFetched)

    const [activeIndex, setActiveIndex] = useState(-1)

    useEffect(() => {
        if (!roles.rolesFetched) fetchRoles(setRoles, setMenu, setFetchingUserRoles)
    }, [roles, setRoles])

    // if there is a path in the url when rendering the page that menu should be expanded until you toggle
    useEffect(() => {
        if (pathname.length > 1) {
            setActiveIndex(menu.findIndex((item) => pathname.toLocaleLowerCase().includes(item.path)))
        }
    }, [pathname, menu])

    return (
        <nav className="minWidth300">
            {fetchingUserRoles && <MenuSkeleton />}
            {!fetchingUserRoles && roles.rolesFetched && (
                <ul role="menubar" className="navigationList">
                    {menu.map((accordion, index) => (
                        <MenuAccordion
                            key={accordion.name}
                            name={accordion.name}
                            isActive={activeIndex === index}
                            onToggle={() => setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index))}
                        >
                            {accordion.submenus.map((accordionItem: submenu) => {
                                const { name, path, icon } = accordionItem
                                return <MenuItem name={name} path={path} Icon={icon} key={accordionItem.name} />
                            })}
                        </MenuAccordion>
                    ))}
                </ul>
            )}
        </nav>
    )
}

export default Navigation2
