import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_SALARY_EVOLUTION } from "constants/text.constants"

import { useEffect, useState } from "react"
import { postWithBodyAndCsrf } from "services/apiService"
import prepareCsrfToken from "utils/prepareCsrfToken"
import {
    tSalaryEvolutionAnalysisType,
    tAnalyzeSalaryEvolutionBody,
    tSalaryEvolutionDiagramViewAnalysisType1,
    tSalaryEvolutionDiagramViewAnalysisType2,
    tSalaryEvolutionDiagramViewAnalysisType3,
} from "types/sharedTypes"

import Select from "components/atoms/select/Select"
import SliderButton from "components/atoms/sliderButton/SliderButton"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import BarDiagram from "./BarDiagram/BarDiagram"
import ColumnDiagram from "./ColumnDiagram/ColumnDiagram"
import BarDiagramIndividual from "./BarDiagram/BarDiagramIndividual"
import SalaryEvolutionType1Table from "./analyzeSalaryEvolutionTables/SalaryEvolutionType1Table"
import SalaryEvolutionType2Table from "./analyzeSalaryEvolutionTables/SalaryEvolutionType2Table"
import SalaryEvolutionType3Table from "./analyzeSalaryEvolutionTables/SalaryEvolutionType3Table"
import SalaryEvolutionScatterDiagram from "./salaryEvolutionScatterDiagram/SalaryEvolutionScatterDiagram"

type tSalaryEvolutionTableRows = string[][]

type tSalaryEvolutionTableView = {
    TableRows: tSalaryEvolutionTableRows
}

interface tSalaryEvolutionDataAnalysisType1 {
    TableView: tSalaryEvolutionTableView
    DiagramView: tSalaryEvolutionDiagramViewAnalysisType1
}
type tSalaryEvolutionDataAnalysisType2 = {
    DiagramView: tSalaryEvolutionDiagramViewAnalysisType2
    TableRows: tSalaryEvolutionTableRows
}
type tSalaryEvolutionDataAnalysisType3 = {
    DiagramView: tSalaryEvolutionDiagramViewAnalysisType3
    SelectedRegistryPeriods: string
    TableRows: tSalaryEvolutionTableRows
}

interface IAnalyzeSelection {
    selectedRegistries: Map<string, string>
    selectedGroupIds: { name: string; ids: string[] }[]
    selectedAnalysisTypeObject: tSalaryEvolutionAnalysisType
    selectedTypeOfSalary: string[]
    selectedTypeOfPopulation: string[]
    selectedGroup: { name: string; count: number }
}

const AnalyzeSelection = ({
    selectedRegistries,
    selectedAnalysisTypeObject,
    selectedGroupIds,
    selectedTypeOfSalary,
    selectedTypeOfPopulation,
    selectedGroup,
}: IAnalyzeSelection) => {
    const [salaryEvolutionTableRows, setSalaryEvolutionTableRows] = useState<tSalaryEvolutionTableRows>()
    const [diagramViewAnalysisType1, setDiagramViewAnalysisType1] = useState<tSalaryEvolutionDiagramViewAnalysisType1>({
        Categories: [],
        SeriesData: [],
    })
    const [individualDiagramViewAnalysisType1, setIndividualDiagramViewAnalysisType1] =
        useState<tSalaryEvolutionDiagramViewAnalysisType1>({
            Categories: [],
            SeriesData: [],
        })
    const [diagramViewAnalysisType2, setDiagramViewAnalysisType2] = useState<tSalaryEvolutionDiagramViewAnalysisType2>({
        Categories: [],
        CountShare: [{ Key: 0, Value: 0 }],
        Data: [],
        SelectedRegistryPeriods: "",
    })
    const [diagramViewAnalysisType3, setDiagramViewAnalysisType3] = useState<tSalaryEvolutionDiagramViewAnalysisType3>({
        RegressionLine: [[0, 0]],
        SalariesMen: [],
        SalariesWomen: [],
    })
    const [selectedRegistryPeriods, setSelectedRegistryPeriods] = useState("")

    const [fetchingDiagramAndTableData, setFetchingDiagramAndTableData] = useState(true)
    const [updatingIntervals, setUpdatingIntervals] = useState(false)
    const [errorCalculateRegressionLine, setErrorCalculateRegressionLine] = useState(false)
    const [fetchError, setFetchError] = useState("")

    const [showMinorGridLines, setShowMinorGridLines] = useState(true)
    const [showIndividualSalaryEvolution, setShowIndividualSalaryEvolution] = useState(false)
    const [fetchingIndividualSalaryEvolution, setFetchingIndividualSalaryEvolution] = useState(false)
    const [selectedIndividualsGroup, setSelectedIndividualsGroup] = useState("")
    const [showInKr, setShowInKr] = useState(false)
    const [incrementOptions, setIncrementOptions] = useState([""])
    const [increment, setIncrement] = useState("0.5")

    // Can't we just use selectedGroup.count as in analysisType3?
    const count = diagramViewAnalysisType2.CountShare.map((value) => value.Key).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
    )

    const getSalaryType = (salaryType: string) => {
        if (salaryType === "Grundlön") {
            return 1
        }
        if (salaryType === "Fast lön") {
            return 4
        }
        return 5
    }

    const analysisType1 = selectedAnalysisTypeObject.Key === 1
    const analysisType2 = selectedAnalysisTypeObject.Key === 2
    const analysisType3 = selectedAnalysisTypeObject.Key === 3

    const requiredDataAnalysis1 = !!salaryEvolutionTableRows && !!diagramViewAnalysisType1.Categories.length
    const requiredDataAnalysis2 = !!salaryEvolutionTableRows && !!diagramViewAnalysisType2.Categories.length
    const requiredDataAnalysis3 =
        !!salaryEvolutionTableRows &&
        (!!diagramViewAnalysisType3.SalariesMen.length || !!diagramViewAnalysisType3.SalariesWomen.length) &&
        !!selectedRegistryPeriods

    useEffect(() => {
        if (showIndividualSalaryEvolution) return
        const url = api.analyzeSalaryEvolutionDiagramById.replace(
            ":analysisType",
            selectedAnalysisTypeObject.Key.toString()
        )

        const ids: string[] = []
        selectedGroupIds.forEach((group) => {
            group.ids.forEach((id) => ids.push(id))
        })

        let body: tAnalyzeSalaryEvolutionBody = {
            GroupIds: ids,
            RegistryIds: Array.from(selectedRegistries.keys()),
            SalaryType: getSalaryType(selectedTypeOfSalary[0]),
        }
        if (selectedAnalysisTypeObject.Key === 1) {
            body = {
                ...body,
                IsIndividual: false, // true när man klickar på gruppnamnet i diagrammet
                Population: selectedTypeOfPopulation[0] === "Kollektiv" ? 1 : 2,
            }
        }
        if (selectedAnalysisTypeObject.Key === 2) {
            body = {
                ...body,
                Increment: 0.5,
                IsPercent: true,
            }
        }

        setFetchError("")
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(url, csrfToken, JSON.stringify(body))
                .then((response) => {
                    if (selectedAnalysisTypeObject.Key === 1) {
                        const typedResponse = response as tSalaryEvolutionDataAnalysisType1
                        setDiagramViewAnalysisType1(typedResponse.DiagramView)
                        setSalaryEvolutionTableRows(typedResponse.TableView.TableRows)
                    }
                    if (selectedAnalysisTypeObject.Key === 2) {
                        const typedResponse = response as tSalaryEvolutionDataAnalysisType2
                        setDiagramViewAnalysisType2(typedResponse.DiagramView)
                        setSalaryEvolutionTableRows(typedResponse.TableRows)
                    }
                    if (selectedAnalysisTypeObject.Key === 3) {
                        const typedResponse = response as tSalaryEvolutionDataAnalysisType3
                        if (response.DiagramView.RegressionLine[0].includes("NaN")) {
                            setErrorCalculateRegressionLine(true)
                        } else {
                            setDiagramViewAnalysisType3(typedResponse.DiagramView)
                            setSelectedRegistryPeriods(typedResponse.SelectedRegistryPeriods)
                            setSalaryEvolutionTableRows(typedResponse.TableRows)
                        }
                    }
                    setFetchingDiagramAndTableData(false)
                })
                .catch(() => {
                    setFetchError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                    setFetchingDiagramAndTableData(false)
                })
        )
    }, [
        selectedAnalysisTypeObject,
        selectedGroupIds,
        selectedRegistries,
        selectedTypeOfPopulation,
        selectedTypeOfSalary,
        showIndividualSalaryEvolution,
    ])

    useEffect(() => {
        if (analysisType1 || analysisType3) return

        const ids: string[] = []
        selectedGroupIds.forEach((group) => {
            group.ids.forEach((id) => ids.push(id))
        })

        const url = api.analyzeSalaryEvolutionDiagramById.replace(":analysisType", "2")
        const body: tAnalyzeSalaryEvolutionBody = {
            GroupIds: ids,
            RegistryIds: Array.from(selectedRegistries.keys()),
            SalaryType: getSalaryType(selectedTypeOfSalary[0]),
            Increment: Number(increment),
            IsPercent: !showInKr,
        }
        setFetchError("")
        setUpdatingIntervals(true)
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(url, csrfToken, JSON.stringify(body))
                .then((response: tSalaryEvolutionDataAnalysisType2) => {
                    setDiagramViewAnalysisType2(response.DiagramView)
                    setSalaryEvolutionTableRows(response.TableRows)
                    setUpdatingIntervals(false)
                })
                .catch(() => {
                    setFetchError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                    setUpdatingIntervals(false)
                })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [increment])

    useEffect(() => {
        if (analysisType2 || analysisType3 || fetchingDiagramAndTableData || !showIndividualSalaryEvolution) return

        const index = selectedGroupIds.findIndex((group) =>
            selectedIndividualsGroup
                ? group.name === selectedIndividualsGroup
                : group.name === diagramViewAnalysisType1.Categories[0]
        )

        const url = api.analyzeSalaryEvolutionDiagramById.replace(":analysisType", "1")
        const body: tAnalyzeSalaryEvolutionBody = {
            GroupIds: selectedGroupIds[index].ids,
            RegistryIds: Array.from(selectedRegistries.keys()),
            SalaryType: getSalaryType(selectedTypeOfSalary[0]),
            IsIndividual: true,
            Population: selectedTypeOfPopulation[0] === "Kollektiv" ? 1 : 2,
        }
        setFetchError("")
        setFetchingIndividualSalaryEvolution(true)
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(url, csrfToken, JSON.stringify(body))
                .then((response: tSalaryEvolutionDataAnalysisType1) => {
                    setIndividualDiagramViewAnalysisType1(response.DiagramView)
                    setSalaryEvolutionTableRows(response.TableView.TableRows)
                    setFetchingIndividualSalaryEvolution(false)
                })
                .catch(() => {
                    setFetchError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                    setFetchingIndividualSalaryEvolution(false)
                })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showIndividualSalaryEvolution, selectedIndividualsGroup])

    useEffect(() => {
        const incrementPercent = ["0.5", "1.0", "1.5", "2.0", "2.5", "3.0", "3.5", "4.0", "4.5", "5.0"]
        const incrementKr = ["250", "500", "750", "1000", "1250", "1500", "1750", "2000", "2250", "2500"]

        if (showInKr) {
            setIncrementOptions(incrementKr)
            setIncrement("250")
        } else {
            setIncrementOptions(incrementPercent)
            setIncrement("0.5")
        }
    }, [showInKr])

    return (
        <div>
            <h2>{ANALYZE_SALARY_EVOLUTION.S4_H2}</h2>
            <div>
                <p className="flex wrap margin0">
                    <strong> {ANALYZE_SALARY_EVOLUTION.S4_LABEL_ANALYSIS_TYPE} </strong>
                    {selectedAnalysisTypeObject.Value}
                </p>
                <p className="flex wrap margin0">
                    <strong> {ANALYZE_SALARY_EVOLUTION.S4_LABEL_REGISTRIES}</strong>
                    {Array.from(selectedRegistries.values()).join(", ")}
                </p>
            </div>
            {errorCalculateRegressionLine && <p className="flex wrap">{ANALYZE_SALARY_EVOLUTION.S4_NO_DIAGRAM_DATA}</p>}
            {fetchingDiagramAndTableData && !fetchError && <ContentSkeleton />}
            {!fetchingDiagramAndTableData &&
                (!!requiredDataAnalysis1 || !!requiredDataAnalysis2 || requiredDataAnalysis3) && (
                    <>
                        <div className="flex wrap gap16 mb16">
                            <p className="flex wrap">
                                <strong> {ANALYZE_SALARY_EVOLUTION.S4_DIAGRAM_SALARY_TYPE}</strong>
                                {selectedTypeOfSalary}
                            </p>
                            {analysisType1 && (
                                <p className="flex wrap">
                                    <strong>{ANALYZE_SALARY_EVOLUTION.S4_DIAGRAM_POPULATION_TYPE}</strong>
                                    {selectedTypeOfPopulation}
                                </p>
                            )}
                            {(analysisType2 || analysisType3) && (
                                <>
                                    <p className="flex wrap">
                                        <strong>{ANALYZE_SALARY_EVOLUTION.S4_DIAGRAM_GROUP}</strong>
                                        {selectedGroup.name}
                                    </p>
                                    <p className="flex wrap">
                                        <strong>{ANALYZE_SALARY_EVOLUTION.S4_DIAGRAM_PERIOD}</strong>
                                        {analysisType2
                                            ? diagramViewAnalysisType2.SelectedRegistryPeriods
                                            : selectedRegistryPeriods}
                                    </p>
                                    <p className="flex wrap">
                                        <strong>{ANALYZE_SALARY_EVOLUTION.S4_COUNT}</strong>
                                        {analysisType2 ? count : selectedGroup.count}
                                    </p>
                                </>
                            )}
                        </div>
                        <div className="flex gap20 ai-center mb24">
                            <div className="sliderButtonsContainer">
                                <SliderButton checked={showMinorGridLines} setChecked={setShowMinorGridLines}>
                                    {ANALYZE_SALARY_EVOLUTION.S4_MINOR_GRID}
                                </SliderButton>
                                {analysisType1 && (
                                    <SliderButton
                                        checked={showIndividualSalaryEvolution}
                                        setChecked={setShowIndividualSalaryEvolution}
                                    >
                                        {ANALYZE_SALARY_EVOLUTION.S4_SELECT_SHOW_INDIVIDUAL}
                                    </SliderButton>
                                )}
                                {analysisType2 && (
                                    <SliderButton
                                        checked={showInKr}
                                        setChecked={setShowInKr}
                                        disabled={updatingIntervals}
                                    >
                                        {ANALYZE_SALARY_EVOLUTION.S4_SELECT_INTERVAL_KR}
                                    </SliderButton>
                                )}
                            </div>
                            {analysisType1 && showIndividualSalaryEvolution && (
                                <Select
                                    optionsArray={diagramViewAnalysisType1.Categories}
                                    setSelectedOption={setSelectedIndividualsGroup}
                                    selected={selectedIndividualsGroup}
                                />
                            )}
                            {analysisType2 && (
                                <Select optionsArray={incrementOptions} setSelectedOption={setIncrement} />
                            )}
                        </div>
                    </>
                )}
            {analysisType1 && !fetchingDiagramAndTableData && !diagramViewAnalysisType1.Categories.length && (
                <p className="flex wrap">{ANALYZE_SALARY_EVOLUTION.S4_NO_DIAGRAM_DATA}</p>
            )}
            <div>
                {!fetchingDiagramAndTableData && analysisType1 && requiredDataAnalysis1 && (
                    <>
                        <div className="mb32">
                            {!showIndividualSalaryEvolution && (
                                <BarDiagram
                                    diagramView={diagramViewAnalysisType1}
                                    diagramTitle={ANALYZE_SALARY_EVOLUTION.S4_DIAGRAM_TITLE_ANALYSISTYPE1}
                                    showMinorGridLines={showMinorGridLines}
                                />
                            )}
                            {showIndividualSalaryEvolution &&
                                !!individualDiagramViewAnalysisType1.Categories.length && (
                                    <BarDiagramIndividual
                                        diagramView={individualDiagramViewAnalysisType1}
                                        showMinorGridLines={showMinorGridLines}
                                        salaryType={selectedTypeOfSalary[0]}
                                    />
                                )}
                        </div>
                        {(!showIndividualSalaryEvolution || fetchingIndividualSalaryEvolution) && (
                            <SalaryEvolutionType1Table
                                seriesData={diagramViewAnalysisType1.SeriesData}
                                tableRows={salaryEvolutionTableRows}
                            />
                        )}
                        {showIndividualSalaryEvolution && !fetchingIndividualSalaryEvolution && (
                            <SalaryEvolutionType1Table
                                seriesData={diagramViewAnalysisType1.SeriesData}
                                tableRows={salaryEvolutionTableRows}
                                isIndividual
                                salaryType={selectedTypeOfSalary[0]}
                            />
                        )}
                    </>
                )}
                {!fetchingDiagramAndTableData && analysisType2 && requiredDataAnalysis2 && (
                    <>
                        <div className="mb32">
                            <ColumnDiagram
                                diagramView={diagramViewAnalysisType2}
                                diagramTitle={ANALYZE_SALARY_EVOLUTION.S4_DIAGRAM_TITLE_ANALYSISTYPE2}
                                showMinorGridLines={showMinorGridLines}
                                isPercent={!showInKr}
                            />
                        </div>
                        <SalaryEvolutionType2Table tableRows={salaryEvolutionTableRows} showKr={showInKr} />
                    </>
                )}
                {!fetchingDiagramAndTableData &&
                    analysisType3 &&
                    requiredDataAnalysis3 &&
                    !errorCalculateRegressionLine && (
                        <>
                            <div className="mb32">
                                <SalaryEvolutionScatterDiagram
                                    diagramData={diagramViewAnalysisType3}
                                    diagramTitle={ANALYZE_SALARY_EVOLUTION.S4_DIAGRAM_TITLE_ANALYSISTYPE3}
                                    showMinorGridLines={showMinorGridLines}
                                    salaryType={selectedTypeOfSalary[0]}
                                />
                            </div>
                            <SalaryEvolutionType3Table
                                tableRows={salaryEvolutionTableRows}
                                selectedRegistryPeriods={selectedRegistryPeriods}
                                salaryType={selectedTypeOfSalary[0]}
                            />
                        </>
                    )}
            </div>
        </div>
    )
}

export default AnalyzeSelection
