import "./SelectRegistryAndSelection.css"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_SALARY_EVOLUTION, REGISTER_TEXTS } from "constants/text.constants"

import {
    tSalaryEvolutionSavedSelection,
    tSalaryEvolutionSelectionGroup,
    tSalaryEvolutionSelectionRegistry,
} from "types/sharedTypes"

import { useContext, useEffect, useState } from "react"

import { RegistriesContext } from "context/RegistriesContext"

import { fetchRegistries } from "services/fetchers"
import { deleteWithCsrf, getJSONrequest } from "services/apiService"

import prepareCsrfToken from "utils/prepareCsrfToken"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Warning from "components/atoms/warning/Warning"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import MultiselectDropdown from "components/cores/multiselectDropdown/MultiselectDropdown"
import MoreInformationModal from "components/cores/moreInformationModal/MoreInformationModal"
import AddRegistriesModal from "./addRegistriesModal/AddRegistriesModal"

type tSalaryEvolutionSelection = {
    Groups: tSalaryEvolutionSelectionGroup[]
    MainRegistry: null
    Population: { Key: number; Value: string }
    Registries: tSalaryEvolutionSelectionRegistry[]
    SalaryType: { Key: number; Value: string }
}

interface ISelectRegistryAndSelection {
    selectedAnalysisTypeObject: { Key: number; Value: string }
    selectedRegistries: Map<string, string>
    setSelectedRegistries: React.Dispatch<React.SetStateAction<Map<string, string>>>
    setSelectedTypeOfPopulation: React.Dispatch<React.SetStateAction<string[]>>
    setSelectedTypeOfSalary: React.Dispatch<React.SetStateAction<string[]>>
    setPreselectedGroups: React.Dispatch<React.SetStateAction<tSalaryEvolutionSelectionGroup[]>>
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
    setActiveStep: React.Dispatch<React.SetStateAction<number>>
    setSelection: React.Dispatch<React.SetStateAction<{ name: string; id: string }>>
}

const SelectRegistryAndSelection = ({
    selectedAnalysisTypeObject,
    selectedRegistries,
    setSelectedRegistries,
    setSelectedTypeOfPopulation,
    setSelectedTypeOfSalary,
    setPreselectedGroups,
    setActiveSteps,
    setActiveStep,
    setSelection,
}: ISelectRegistryAndSelection) => {
    const { registries, setRegistries, registriesFetched, setRegistriesFetched } = useContext(RegistriesContext)
    const [noRegistries, setNoRegistries] = useState(!registries.length && registriesFetched)
    const [fetchingRegistries, setFetchingRegistries] = useState(!registries.length)
    const [errorFetchingRegistries, setErrorFetchingRegistries] = useState("")

    const analysisType = selectedAnalysisTypeObject.Key.toString()
    const [analysisSelections, setAnalysisSelections] = useState<tSalaryEvolutionSavedSelection[]>([])
    const [fetchingAnalysisSelections, setFetchingAnalysisSelections] = useState(false)
    const [errorFetchingAnalysisSelections, setErrorFetchingAnalysisSelections] = useState("")

    const [fetchError, setFetchError] = useState("")
    const [error, setPostError] = useState("")
    const [currentSelection, setCurrentSelection] = useState({ Name: "", Id: "" })
    const [showWarning, setShowWarning] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)

    const [showMoreInformationModal, setShowMoreInformationModal] = useState(false)
    const [showAddRegistriesModal, setShowAddRegistriesModal] = useState(false)
    const [currentSelectionObj, setCurrentSelectionObj] = useState({
        Name: "",
        Id: "",
        Registries: [""],
        Groups: [{ Key: 0, Value: "" }],
        Created: "",
    })

    const handleOpenMoreInfoModal = (selection: tSalaryEvolutionSavedSelection) => {
        setCurrentSelectionObj(selection)
        setShowMoreInformationModal(true)
    }
    const handleAddRegistriesModal = (selection: tSalaryEvolutionSavedSelection) => {
        setCurrentSelectionObj(selection)
        setShowAddRegistriesModal(true)
    }

    const handleChooseSelection = (id: string, name: string) => {
        // This should be a fetcher
        // setFetchingSavedAnalysis(true)
        const url = api.analyzeGetSalaryEvolutionSelectionById.replace(":selectionId", id)
        getJSONrequest(url)
            .then((savedSelection: tSalaryEvolutionSelection) => {
                const savedSelectionRegistries: Map<string, string> = new Map(
                    savedSelection.Registries.map((registry) => [registry.Id, registry.Name])
                )
                setSelectedRegistries(savedSelectionRegistries)
                setSelectedTypeOfPopulation([savedSelection.Population.Value])
                setSelectedTypeOfSalary([savedSelection.SalaryType.Value])
                setPreselectedGroups(savedSelection.Groups)
                setSelection({ name, id })
                setActiveStep(3)
                // setFetchingSavedAnalysis(false)
            })
            .catch(() => {
                // setFetchingSavedAnalysis(false)
                setFetchError(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            })
        // )
    }

    const handleDeleteSelection = (id: string, name: string) => {
        setPostError("")
        setCurrentSelection({ Name: name, Id: id })
        setShowWarning(true)
    }

    const deleteSelection = (id: string) => {
        const url = api.analyzeDeleteSalaryEvolutionSelectionById.replace(":selectionId", id)
        prepareCsrfToken().then((csrfToken) =>
            deleteWithCsrf(url, csrfToken)
                .then(() => {
                    setAnalysisSelections((prevState) => {
                        const index = prevState.findIndex((item) => item.Id === id)
                        if (index > -1) {
                            prevState.splice(index, 1)
                        }
                        return prevState
                    })
                    setPostError("")
                    setShowWarning(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setShowWarning(false)
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    useEffect(() => {
        setFetchingAnalysisSelections(true)
        setErrorFetchingAnalysisSelections("")
        const url = api.analyzeGetSalaryEvolutionSelectionsById.replace(":analysisType", analysisType)
        getJSONrequest(url)
            .then((response: tSalaryEvolutionSavedSelection[]) => {
                setAnalysisSelections(response)
                setFetchingAnalysisSelections(false)
            })
            .catch(() => {
                setFetchingAnalysisSelections(false)
                setErrorFetchingAnalysisSelections(ANALYZE_SALARY_EVOLUTION.ERROR_FETCHING_SELECTIONS)
            })
    }, [analysisType])

    useEffect(() => {
        if (!registries.length && !registriesFetched)
            fetchRegistries(
                setRegistries,
                setNoRegistries,
                undefined,
                setFetchingRegistries,
                setRegistriesFetched,
                setErrorFetchingRegistries
            )
    }, [registries, setRegistries, registriesFetched, setRegistriesFetched])

    useEffect(() => {
        if (selectedAnalysisTypeObject.Key === 1 && selectedRegistries.size > 1 && selectedRegistries.size < 11) {
            setActiveSteps([1, 2, 3])
        } else if (selectedAnalysisTypeObject.Key !== 1 && selectedRegistries.size === 2) {
            setActiveSteps([1, 2, 3])
        } else {
            setActiveSteps([1, 2])
        }
    }, [selectedAnalysisTypeObject.Key, selectedRegistries, setActiveSteps])

    return (
        <div>
            <div className="mb32">
                <p className="labelAlike">{ANALYZE_SALARY_EVOLUTION.S2_LABEL}</p>
                {selectedAnalysisTypeObject.Value && (
                    <p className="flex wrap">
                        <strong> {ANALYZE_SALARY_EVOLUTION.S2_LABEL_ANALYSIS_TYPE} </strong>
                        {selectedAnalysisTypeObject.Value}
                    </p>
                )}
            </div>
            {registries && !noRegistries && registriesFetched && (
                <MultiselectDropdown
                    selectedOptions={selectedRegistries}
                    setSelectedOptions={setSelectedRegistries}
                    options={registries}
                    label={
                        analysisType === "1"
                            ? ANALYZE_SALARY_EVOLUTION.S2_LABEL_SELECT_REGISTRIES
                            : ANALYZE_SALARY_EVOLUTION.S2_LABEL_SELECT_2_REGISTRIES
                    }
                />
            )}
            {(fetchingAnalysisSelections || fetchingRegistries) &&
                (!errorFetchingAnalysisSelections || !errorFetchingRegistries) &&
                !noRegistries && <ContentSkeleton />}
            {noRegistries && <p>{REGISTER_TEXTS.NO_REGISTERS}</p>}
            {errorFetchingAnalysisSelections && <Error>{errorFetchingAnalysisSelections}</Error>}
            {!fetchingAnalysisSelections && !errorFetchingAnalysisSelections && !analysisSelections?.length && (
                <p>{ANALYZE_SALARY_EVOLUTION.NO_SELECTIONS}</p>
            )}
            {showWarning && (
                <Warning
                    onClick={() => deleteSelection(currentSelection.Id)}
                    showWarning={setShowWarning}
                    variant="delete"
                >
                    {`${ANALYZE_SALARY_EVOLUTION.S2_WARNING_DELETE_SELECTION} ${currentSelection.Name}`}
                </Warning>
            )}
            {showConfirmation && (
                <Confirmation>{ANALYZE_SALARY_EVOLUTION.S2_CONFIRMATION_DELETED_SELECTION}</Confirmation>
            )}
            {error && <Error>{error}</Error>}
            {!!analysisSelections.length && !fetchingAnalysisSelections && (
                <div>
                    <h3>{ANALYZE_SALARY_EVOLUTION.S2_H3}</h3>
                    <table className="analysisSelectionsTable">
                        <thead>
                            <tr>
                                <th className="th-bb-1">{ANALYZE_SALARY_EVOLUTION.S2_TH1}</th>
                                <th className="th-bb-1">{ANALYZE_SALARY_EVOLUTION.S2_TH2}</th>
                                <th className="th-bb-1 salaryEvolutionSelectionsTHwidth260">
                                    {ANALYZE_SALARY_EVOLUTION.S2_TH3}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {analysisSelections.map((selection) => (
                                <tr key={selection.Id}>
                                    <td>{selection.Name}</td>
                                    <td>{selection.Created}</td>
                                    <td>
                                        <div className="flex gap8 jc-flex-end">
                                            {selection.AnalysisType !== "Matchning" && (
                                                <Button small onClick={() => handleOpenMoreInfoModal(selection)}>
                                                    {ANALYZE_SALARY_EVOLUTION.S2_BUTTON_MORE_INFO}
                                                </Button>
                                            )}
                                            {selection.AnalysisType !== "Matchning" && (
                                                <Button
                                                    small
                                                    onClick={() => handleChooseSelection(selection.Id, selection.Name)}
                                                >
                                                    {ANALYZE_SALARY_EVOLUTION.S2_BUTTON_CHOOSE}
                                                </Button>
                                            )}
                                            {selection.AnalysisType === "Matchning" && (
                                                <Button
                                                    small
                                                    // onClick={() => handleChooseSelection(selection.Id, selection.Name)}
                                                    // onClick={() => setShowAddRegistriesModal(true)}
                                                    onClick={() => handleAddRegistriesModal(selection)}
                                                >
                                                    {ANALYZE_SALARY_EVOLUTION.S2_BUTTON_CHOOSE}
                                                </Button>
                                            )}
                                            <Button
                                                variant="delete"
                                                small
                                                onClick={() => handleDeleteSelection(selection.Id, selection.Name)}
                                            >
                                                {ANALYZE_SALARY_EVOLUTION.S2_BUTTON_DELETE}
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {showMoreInformationModal && (
                        <MoreInformationModal
                            showModal={showMoreInformationModal}
                            setShowModal={setShowMoreInformationModal}
                            registries={currentSelectionObj.Registries}
                            groups={currentSelectionObj.Groups}
                            name={currentSelectionObj.Name}
                            created={currentSelectionObj.Created}
                            hideExistsIn={selectedAnalysisTypeObject.Key !== 1}
                        />
                    )}
                    {showAddRegistriesModal && (
                        <AddRegistriesModal
                            showModal={showAddRegistriesModal}
                            setShowModal={setShowAddRegistriesModal}
                            selectedRegistries={selectedRegistries}
                            setSelectedRegistries={setSelectedRegistries}
                            mainRegister={currentSelectionObj.Registries[0]}
                            matchingId={currentSelectionObj.Id}
                            registries={registries}
                            setPreselectedGroups={setPreselectedGroups}
                            setActiveStep={setActiveStep}
                            name={currentSelectionObj.Name}
                            created={currentSelectionObj.Created}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default SelectRegistryAndSelection
