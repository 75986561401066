// import "./ManageTimeseriesSelection.css"

import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"

import { AgGridReact } from "ag-grid-react"
import { FirstDataRenderedEvent, RowClickedEvent } from "ag-grid-community"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_TIMESERIES } from "constants/text.constants"

import { useState, useEffect, useCallback, useRef } from "react"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf, putWithBodyAndCsrf } from "services/apiService"

import { dispatchNumber, dispatchString, tTimeseriesGroup, tTimeseriesPreselectedGroup } from "types/sharedTypes"

import Error from "components/atoms/error/Error"
import Input from "components/atoms/input/Input"
import Button from "components/atoms/button/Button"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import FilterRadioButtonsRow from "components/cores/filterRadioButtonsRow/FilterRadioButtonsRow"

import { ReactComponent as SaveIcon } from "assets/save.icon.svg"
import { ReactComponent as UpdateIcon } from "assets/update.icon.svg"
import { ReactComponent as CleanIcon } from "assets/broom-cleaning-icon.svg"

interface IManageTimeseriesSelection {
    selectedRegistries: Map<string, string>
    preselectedGroups: tTimeseriesPreselectedGroup[] | []
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
    selectedGroups: string[]
    setSelectedGroups: React.Dispatch<React.SetStateAction<string[]>>
    diagramType: number
    setDiagramType: dispatchNumber
    salaryType: number
    setSalaryType: dispatchNumber
    savedSelection: { name: string; id: string }
    setMainRegisterName: dispatchString
    mainRegisterName: string
}

const ManageTimeseriesSelection = ({
    selectedRegistries,
    preselectedGroups,
    setActiveSteps,
    selectedGroups,
    setSelectedGroups,
    diagramType,
    setDiagramType,
    salaryType,
    setSalaryType,
    savedSelection,
    setMainRegisterName,
    mainRegisterName,
}: IManageTimeseriesSelection) => {
    const { S2_TH1, S2_TH2, S2_TH3, S2_TH4 } = ANALYZE_TIMESERIES

    const salaryTypes = ["", "Grundlön", "", "", "Fast lön", "Totallön"]
    const diagramTypes = ["", "Lådagram", "Punktdiagram"]

    const salaryTypeInitial = salaryType ? [salaryTypes[salaryType]] : []
    const diagramTypeInitial = diagramType ? [diagramTypes[diagramType]] : []

    const [fetchError, setFetchError] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")
    const [savingSelection, setSavingSelection] = useState(false)
    const [updatingSelection, setUpdatingSelection] = useState(false)

    const [timeseriesGroups, setTimeseriesGroups] = useState<tTimeseriesGroup[]>([])
    const [fetchingTimeseriesGroups, setFetchingTimeseriesGroups] = useState(true)

    const [selectedTypeOfSalary, setSelectedTypeOfSalary] = useState(salaryTypeInitial)
    const [selectedDiagramType, setSelectedDiagramType] = useState(diagramTypeInitial)
    const [selectionName, setSelectionName] = useState(savedSelection.name)
    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>(selectedGroups)

    const maxGroups = Math.floor(20 / selectedRegistries.size)

    const gridRef = useRef<AgGridReact<tTimeseriesGroup>>(null)
    const [columnDefs] = useState([
        {
            field: "Name",
            headerName: S2_TH1,
            flex: 1,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            sortable: true,
            filter: true,
        },
        {
            field: "GroupType",
            headerName: S2_TH2,
            flex: 1,
            sortable: true,
            filter: true,
        },
        { field: "Count", headerName: S2_TH3, flex: 1, sortable: true, filter: true },
        { field: "ExistsIn", headerName: S2_TH4, flex: 1, sortable: true, filter: true },
    ])

    const onSelectionChanged = (event: RowClickedEvent) => {
        let groupIds: string[] = []

        const selectedRows = event.api.getSelectedRows()
        selectedRows.forEach((group: tTimeseriesGroup) => {
            groupIds = groupIds.concat(group.GroupIds)
        })

        setSelectedGroupIds(groupIds)
        setSelectedGroups(groupIds)
    }
    const onRowClicked = (event: RowClickedEvent) => {
        if (event.node.isSelected()) {
            if (event.api.getSelectedRows().length <= maxGroups) {
                event.node.setSelected(true)
            } else event.node.setSelected(false)
        } else event.node.setSelected(false)
    }

    const onFirstDataRendered = useCallback(
        (params: FirstDataRenderedEvent<tTimeseriesGroup>) => {
            // If we come back from step 3
            if (selectedGroupIds.length) {
                gridRef.current?.api.forEachNode((node) => {
                    node.data?.GroupIds.forEach((groupId) => {
                        node.setSelected(!!node.data && selectedGroupIds.includes(groupId))
                    })
                })
            } else {
                // If we come from step 1 by saved selection
                const preselectedGroupNames: string[] = []
                preselectedGroups.forEach((group) => preselectedGroupNames.push(group.Value))
                const preselectedGroupTypes: string[] = []
                preselectedGroups.forEach((group) => preselectedGroupTypes.push(group.Key))
                gridRef.current?.api.forEachNode((node) =>
                    node.setSelected(
                        !!node.data &&
                            preselectedGroupNames.includes(node.data.Name) &&
                            preselectedGroupTypes.includes(node.data.GroupType)
                    )
                )
            }
        },
        [preselectedGroups, selectedGroupIds]
    )

    const deselectAllRows = () => gridRef.current?.api.forEachNode((node) => node.setSelected(false))

    const saveSelection = () => {
        setSavingSelection(true)

        const body = {
            Name: selectionName,
            SalaryType: salaryType,
            DiagramType: diagramType,
            GroupIds: selectedGroupIds,
            Registries: Array.from(selectedRegistries.keys()),
        }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.analyzeTimeseriesSaveAnalysisSelection, csrfToken, JSON.stringify(body))
                .then(() => {
                    setSavingSelection(false)
                    setShowConfirmation(true)
                    setPostError("")
                })
                .catch((err) => {
                    setSavingSelection(false)
                    setPostError("")
                    if (err.status === 403) {
                        // check if correct err.status
                        err.text().then((text: string) => {
                            if (text === "UnvalidName") setPostError(ANALYZE_TIMESERIES.S2_SAVE_SELECTION_UNVALID_NAME)
                            else setPostError(ANALYZE_TIMESERIES.S2_SAVE_SELECTION_ERROR)
                        })
                    } else setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }
    const updateSelection = (id: string) => {
        setUpdatingSelection(true)

        const body = {
            Id: id,
            Name: selectionName,
            SalaryType: salaryType,
            DiagramType: diagramType,
            GroupIds: selectedGroupIds,
        }

        prepareCsrfToken().then((csrfToken) =>
            putWithBodyAndCsrf(idMapper(api.analyzeTimeseriesUpdateSelectionById, id), csrfToken, JSON.stringify(body))
                .then(() => {
                    setUpdatingSelection(false)
                    setShowConfirmation(true)
                    setPostError("")
                })
                .catch((err) => {
                    setUpdatingSelection(false)
                    setPostError("")
                    if (err.status === 403) {
                        // check if correct err.status
                        err.text().then((text: string) => {
                            if (text === "UnvalidName") setPostError(ANALYZE_TIMESERIES.S2_SAVE_SELECTION_UNVALID_NAME)
                            else setPostError(ANALYZE_TIMESERIES.S2_SAVE_SELECTION_ERROR)
                        })
                    } else setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const notChanged = () =>
        !selectionName || !selectedTypeOfSalary[0] || !selectedDiagramType[0] || !selectedGroupIds[0]

    // Update states when you click filterButtons
    useEffect(() => {
        const salType = salaryTypes.findIndex((element) => element === selectedTypeOfSalary[0])
        setSalaryType(salType)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTypeOfSalary])
    useEffect(() => {
        const diagType = diagramTypes.findIndex((element) => element === selectedDiagramType[0])
        setDiagramType(diagType)
        if (diagType === 2) deselectAllRows()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDiagramType])
    useEffect(() => {
        if (selectedDiagramType.length && selectedTypeOfSalary.length && selectedGroupIds.length)
            setActiveSteps([1, 2, 3])
        else setActiveSteps([1, 2])
    }, [selectedDiagramType, selectedGroupIds.length, selectedTypeOfSalary, setActiveSteps])

    useEffect(() => {
        const body = { RegistryIds: Array.from(selectedRegistries.keys()) }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.analyzeGetTimeseriesGroups, csrfToken, JSON.stringify(body))
                .then((response: tTimeseriesGroup[]) => {
                    interface tPreparedTimeseriesGroup extends tTimeseriesGroup {
                        ExistsIn: string
                    }
                    const preparedResponse: tPreparedTimeseriesGroup[] = []
                    response.forEach((timeseriesGroup) => {
                        preparedResponse.push({
                            ...timeseriesGroup,
                            ExistsIn:
                                selectedRegistries.size === timeseriesGroup.GroupIds.length
                                    ? "Alla register"
                                    : `${timeseriesGroup.GroupIds.length} av ${selectedRegistries.size} register`,
                        })
                    })
                    setTimeseriesGroups(preparedResponse)
                    setMainRegisterName(response[0].MainRegistry)
                    setFetchingTimeseriesGroups(false)
                })
                .catch((err) => {
                    setFetchingTimeseriesGroups(false)
                    setFetchError(err)
                })
        )
    }, [selectedRegistries, setMainRegisterName])

    return (
        <>
            {fetchingTimeseriesGroups && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingTimeseriesGroups && !timeseriesGroups.length && <p>{ANALYZE_TIMESERIES.S2_NO_GROUPS}</p>}
            {!fetchingTimeseriesGroups && !fetchError && !!timeseriesGroups.length && (
                <div>
                    <h2>{ANALYZE_TIMESERIES.S2_H2}</h2>
                    <p className="flex wrap">
                        <strong>{ANALYZE_TIMESERIES.S2_MAIN_REGISTERY_IS}</strong>
                        {mainRegisterName}
                    </p>
                    <p>{ANALYZE_TIMESERIES.S2_MAIN_REGISTERY_EXPLANATION}</p>
                    <div className="mb40">
                        <p>{ANALYZE_TIMESERIES.S2_EXPLANATION}</p>
                        {diagramType === 1 && (
                            <p>{`${ANALYZE_TIMESERIES.S2_MAX_GROUPS_P1} ${selectedRegistries.size} ${ANALYZE_TIMESERIES.S2_MAX_GROUPS_P2} ${maxGroups} ${ANALYZE_TIMESERIES.S2_MAX_GROUPS_P3}`}</p>
                        )}
                    </div>
                    {showConfirmation && (
                        <div className="mb24">
                            <Confirmation>{ANALYZE_TIMESERIES.S2_CONFIRMATION_SAVED_SELECTION}</Confirmation>
                        </div>
                    )}
                    {postError && (
                        <div className="mb24">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    <div className="flex column-gap72 wrap">
                        <div>
                            <p className="labelAlike">{ANALYZE_TIMESERIES.S2_LABEL}</p>
                            <FilterRadioButtonsRow
                                setSelection={setSelectedTypeOfSalary}
                                selectedFilter={selectedTypeOfSalary}
                            >
                                {ANALYZE_TIMESERIES.S2_SALARY_TYPE_1}
                                {ANALYZE_TIMESERIES.S2_SALARY_TYPE_4}
                                {ANALYZE_TIMESERIES.S2_SALARY_TYPE_5}
                            </FilterRadioButtonsRow>
                        </div>
                        <div>
                            <p className="labelAlike">{ANALYZE_TIMESERIES.S2_LABEL_DIAGRAM_TYPE}</p>
                            <FilterRadioButtonsRow
                                setSelection={setSelectedDiagramType}
                                selectedFilter={selectedDiagramType}
                            >
                                {ANALYZE_TIMESERIES.S2_DIAGRAM_TYPE_1}
                                {ANALYZE_TIMESERIES.S2_DIAGRAM_TYPE_2}
                            </FilterRadioButtonsRow>
                        </div>
                    </div>
                    <div className="maxWidth300">
                        <label className="gray" htmlFor="createSelection">
                            {ANALYZE_TIMESERIES.S2_LABEL_NAME}
                        </label>
                        <Input
                            type="text"
                            id="createSelection"
                            name="createSelection"
                            value={selectionName}
                            onChange={(e) => setSelectionName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <div
                            className="ag-theme-alpine"
                            style={{ width: "100%", height: "600px", marginBottom: "64px" }}
                        >
                            <h3 className="hInsideAgGrid">{ANALYZE_TIMESERIES.S2_CAPTION_GROUPS}</h3>
                            <AgGridReact
                                ref={gridRef}
                                rowData={timeseriesGroups}
                                columnDefs={columnDefs}
                                rowSelection={diagramType === 1 ? "multiple" : "single"}
                                onFirstDataRendered={onFirstDataRendered}
                                onSelectionChanged={onSelectionChanged}
                                rowMultiSelectWithClick
                                onRowClicked={onRowClicked}
                                alwaysShowHorizontalScroll
                                alwaysShowVerticalScroll
                                suppressMenuHide
                            />
                        </div>
                    </div>
                    <div className="flex jc-space-between">
                        <Button onClick={deselectAllRows} disabled={selectedGroupIds.length === 0} Icon={CleanIcon}>
                            {ANALYZE_TIMESERIES.S2_BUTTON_CLEAR_SELECTION}
                        </Button>
                        <div className="flex gap8">
                            {savedSelection.id && (
                                <Button
                                    Icon={UpdateIcon}
                                    disabled={notChanged()}
                                    onClick={() => updateSelection(savedSelection.id)}
                                    isLoading={updatingSelection}
                                >
                                    {ANALYZE_TIMESERIES.S2_BUTTON_UPDATE_SELECTION}
                                </Button>
                            )}
                            <Button
                                Icon={SaveIcon}
                                disabled={notChanged()}
                                onClick={saveSelection}
                                isLoading={savingSelection}
                            >
                                {ANALYZE_TIMESERIES.S2_BUTTON_SAVE_SELECTION}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ManageTimeseriesSelection
