import "./DeleteAccountPage.css"

import { useState, useEffect } from "react"

import api from "constants/endpoints.constants"
import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import GENERAL_TEXTS, { MAIN_ACCOUNT_TEXTS } from "constants/text.constants"

import { IMainAccount } from "types/sharedTypes"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"

import useScrollToTop from "hooks/useScrollToTop"
import { fetchMainAccounts } from "services/fetchers"
import { deleteWithBodyAndCsrf } from "services/apiService"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Warning from "components/atoms/warning/Warning"
import Skeleton from "components/atoms/skeleton/Skeleton"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"

const DeleteAccountPage = () => {
    const [mainAccounts, setMainAccounts] = useState<IMainAccount[]>()
    const [fetchError, setFetchError] = useState("")
    const [postError, setPostMessage] = useState("")
    const [fetchingMainAccounts, setFetchingMainAccounts] = useState(true)
    const [showWarning, setShowWarning] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [accountId, setAccountId] = useState("")
    const [accountName, setAccountName] = useState("")

    const handleDeleteAccount = (Id: string) => {
        prepareCsrfToken().then((csrfToken) =>
            deleteWithBodyAndCsrf(idMapper(api.deleteMainAccountById, Id), csrfToken, JSON.stringify({ Id }))
                .then(() => {
                    const newList = mainAccounts?.filter((item) => item.Id !== Id)
                    setMainAccounts(newList)
                    setPostMessage("")
                    setShowWarning(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setShowWarning(false)
                    if (err) setPostMessage(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    useEffect(() => fetchMainAccounts(setMainAccounts, setFetchingMainAccounts, setFetchError), [])

    useUpdatePageTitle(pageTitles.DELETE_MAIN_ACCOUNT_PAGE_TITLE)
    useScrollToTop()

    return (
        <div>
            <PageHeader pageId={pageIds.DELETE_MAIN_ACCOUNT_PAGE_ID} />
            {fetchingMainAccounts && <Skeleton />}
            {fetchError && <div>{fetchError}</div>}
            {!fetchingMainAccounts && !fetchError && (
                <div className="maxWidth1000">
                    <ul className="ul">
                        {mainAccounts &&
                            mainAccounts.map((account) => (
                                <li key={account.Id} className="li">
                                    <p className="textPadding">{account.Name} </p>
                                    <Button
                                        type="button"
                                        variant="delete"
                                        onClick={() => {
                                            setShowWarning(true)
                                            setPostMessage("")
                                            setAccountId(account.Id)
                                            setAccountName(account.Name)
                                        }}
                                    >
                                        {MAIN_ACCOUNT_TEXTS.BUTTON_DELETE}
                                    </Button>
                                </li>
                            ))}
                    </ul>
                    {showWarning && (
                        <Warning
                            onClick={() => handleDeleteAccount(accountId)}
                            showWarning={setShowWarning}
                            variant="delete"
                        >
                            {`${MAIN_ACCOUNT_TEXTS.WARNING_DELETE} ${accountName}`}
                        </Warning>
                    )}
                    {showConfirmation && <Confirmation>{MAIN_ACCOUNT_TEXTS.CONFIRMATION_DELETED}</Confirmation>}
                    {postError && <Error>{postError}</Error>}
                </div>
            )}
        </div>
    )
}

export default DeleteAccountPage
