import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { useState, useEffect } from "react"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf } from "services/apiService"
import { fetchAssessmentGroups } from "services/fetchers"

import { dispatchNumber, tGapMenWomenAssessmentGroup, tGroupAssessmentsArray } from "types/sharedTypes"

import { ReactComponent as SaveIcon } from "assets/save.icon.svg"
import { ReactComponent as downloadIcon } from "assets/file-arrow-down.icon.svg"
import { ReactComponent as CleanIcon } from "assets/broom-cleaning-icon.svg"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Warning from "components/atoms/warning/Warning"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import EquivalentWorkValueTable from "./equivalentWorkValueTable/EquivalentWorkValueTable"

interface IEquivalentWorkValueGroups {
    analysisId: string
    setActiveStep: dispatchNumber
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
}

const EquivalentWorkValueGroups = ({ analysisId, setActiveStep, setActiveSteps }: IEquivalentWorkValueGroups) => {
    const [fetchError, setFetchError] = useState("")
    const [showWarning, setShowWarning] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const [assessmentGroups, setAssessmentGroups] = useState<tGapMenWomenAssessmentGroup[]>([])
    const [fetchingAssessmentGroups, setFetchingAssessmentGroups] = useState(true)

    const [savingTableValues, setSavingTableValues] = useState(false)
    const [clearTableValues, setClearTableValues] = useState(false)

    const [groupAssessmentsArray, setGroupAssessmentsArray] = useState<tGroupAssessmentsArray>([
        {
            GroupId: "",
            GroupAssessmentId: "",
            AssessmentValues: [{ AssessmentId: "", AssessmentValueId: "", GroupName: "", Value: 0 }],
        },
    ])

    const [submitAndContinue, setSubmitAndContinue] = useState(false)

    const [downloadingCSV, setDownloadingCSV] = useState(false)

    const checkFieldsNonEmpty = (groupAssessmentsArr: tGroupAssessmentsArray) => {
        let nonEmpty = true
        groupAssessmentsArr.forEach((groupAssessment) => {
            // eslint-disable-next-line consistent-return
            groupAssessment.AssessmentValues.forEach((asseessmentValueObj) => {
                // eslint-disable-next-line no-return-assign
                if (!asseessmentValueObj.Value) {
                    nonEmpty = false
                    return nonEmpty
                }
            })
        })
        return nonEmpty
    }
    const validForSaving = checkFieldsNonEmpty(groupAssessmentsArray)

    const handleSaveValues = (saveAndContinue = false) => {
        setSubmitAndContinue(saveAndContinue)
        setShowConfirmation(false)
        setPostError("")
        setShowWarning(true)
    }

    const saveValues = (saveAndContinue: boolean) => {
        setShowWarning(false)
        setSavingTableValues(true)

        const body = { Id: analysisId, GroupAssessmentRows: groupAssessmentsArray }
        const url = saveAndContinue ? api.analyzeSaveGroupAssessments : api.analyzeSaveGroupAssessmentsDraft

        setShowConfirmation(false)
        setPostError("")

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(idMapper(url, analysisId), csrfToken, JSON.stringify(body))
                .then(() => {
                    setSavingTableValues(false)
                    if (!saveAndContinue) setShowConfirmation(true)
                    if (saveAndContinue) setActiveStep(6)
                    if (validForSaving) setActiveSteps([1, 2, 3, 4, 5, 6])
                    else setActiveSteps([1, 2, 3, 4, 5])
                })
                .catch((err) => {
                    setSavingTableValues(false)
                    if (err.text) {
                        err.text().then((text: string) => {
                            if (text === "ValuesMissing") setPostError(ANALYZE_WAGE_GAP.S5_POST_ERROR_VALUES_MISSING)
                        })
                    } else setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                })
        )
    }

    const downloadCSV = () => {
        setPostError("")
        setDownloadingCSV(true)

        const filename = ANALYZE_WAGE_GAP.S5_EXPORT_FILENAME
        const body = { AnalyseId: analysisId }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.analyzeWageGapStepFiveExportCSV, csrfToken, JSON.stringify(body), true)
                .then((response) => {
                    response.blob().then((blob: any) => {
                        // const url = window.URL.createObjectURL(new Blob([blob], { type: "text/plain;charset=utf-8" }))
                        const url = window.URL.createObjectURL(new Blob([blob]))
                        const link = document.createElement("a")
                        link.setAttribute("href", url)
                        link.setAttribute("download", filename)
                        document.body.appendChild(link)
                        link.click()
                    })
                    setDownloadingCSV(false)
                })
                .catch(() => {
                    setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                    setDownloadingCSV(false)
                })
        )
    }

    useEffect(() => {
        fetchAssessmentGroups(analysisId, setAssessmentGroups, setFetchingAssessmentGroups, setFetchError)
    }, [analysisId])

    return (
        <>
            {fetchingAssessmentGroups && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingAssessmentGroups && !fetchError && (
                <div>
                    <h2>{ANALYZE_WAGE_GAP.S5_H2}</h2>
                    {showConfirmation && <Confirmation>{ANALYZE_WAGE_GAP.S5_CONFIRMATION_SAVED_VALUES}</Confirmation>}
                    {postError && (
                        <div className="mb24">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    {showWarning && (
                        <Warning onClick={() => saveValues(submitAndContinue)} showWarning={setShowWarning}>
                            {ANALYZE_WAGE_GAP.S5_WARNING}
                        </Warning>
                    )}
                    <div>
                        <p>{ANALYZE_WAGE_GAP.S5_STEP_EXPLANATION}</p>
                        <p>{ANALYZE_WAGE_GAP.S5_STEP_EXPLANATION_2}</p>
                        <p>{ANALYZE_WAGE_GAP.S5_STEP_EXPLANATION_3}</p>
                        <div className="flex gap16 jc-flex-end">
                            <Button Icon={CleanIcon} onClick={() => setClearTableValues(true)}>
                                {ANALYZE_WAGE_GAP.S5_BUTTON_CLEAN_TABLE_VALUES}
                            </Button>
                            <Button Icon={downloadIcon} onClick={downloadCSV} isLoading={downloadingCSV}>
                                {ANALYZE_WAGE_GAP.S5_BUTTON_EXPORT_CSV}
                            </Button>
                        </div>
                        <div>
                            <p className="labelALike margin0 mb8">{ANALYZE_WAGE_GAP.S5_LABEL_VALUE_ASSESSMENTS}</p>
                            <div className="mb16">
                                <EquivalentWorkValueTable
                                    assessmentGroups={assessmentGroups}
                                    clearValues={clearTableValues}
                                    setClearValues={setClearTableValues}
                                    setGroupAssessmentsArray={setGroupAssessmentsArray}
                                />
                            </div>
                        </div>
                        <div className="flex gap16 jc-flex-end">
                            <Button
                                onClick={() => handleSaveValues(false)}
                                Icon={SaveIcon}
                                isLoading={savingTableValues}
                            >
                                {ANALYZE_WAGE_GAP.S5_BUTTON_SAVE}
                            </Button>
                            <Button
                                disabled={!validForSaving}
                                onClick={() => handleSaveValues(true)}
                                Icon={SaveIcon}
                                isLoading={savingTableValues}
                            >
                                {ANALYZE_WAGE_GAP.S5_BUTTON_SAVE_N_CONTINUE}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default EquivalentWorkValueGroups
