import "./SalaryRegistriesAndSelections.css"

import { useContext, useEffect, useState } from "react"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_SALARY_DISTRIBUTION, REGISTER_TEXTS } from "constants/text.constants"

import { dispatchNumber, dispatchString, salaryGroup, salarySavedSelection } from "types/sharedTypes"

import { deleteWithBodyAndCsrf } from "services/apiService"
import { fetchRegistries, fetchSalarySavedSelections } from "services/fetchers"

import { RegistriesContext } from "context/RegistriesContext"

import prepareCsrfToken from "utils/prepareCsrfToken"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Warning from "components/atoms/warning/Warning"
import SelectRegistry from "components/cores/selectRegistry/SelectRegistry"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import Confirmation from "components/atoms/confirmation/Confirmation"

interface ISalaryRegistriesAndSelections {
    selectedRegistryId: string
    setSelectedRegistryId: dispatchString
    setSelectedGroups: React.Dispatch<React.SetStateAction<salaryGroup[]>>
    setActiveStep: dispatchNumber
    setDiagramType: dispatchNumber
    setSalaryType: dispatchNumber
    setSelection: React.Dispatch<React.SetStateAction<{ name: string; id: string }>>
}

const SalaryRegistriesAndSelections = ({
    selectedRegistryId,
    setSelectedRegistryId,
    setSelectedGroups,
    setActiveStep,
    setDiagramType,
    setSalaryType,
    setSelection,
}: ISalaryRegistriesAndSelections) => {
    const { registries, setRegistries, registriesFetched, setRegistriesFetched } = useContext(RegistriesContext)
    const [noRegisters, setNoRegisters] = useState(!registries.length && registriesFetched)

    const [fetchError, setFetchError] = useState("")

    const [fetchingRegistries, setFetchingRegistries] = useState(!registries.length)
    const [savedSelection, setSavedSelection] = useState<salarySavedSelection[]>([])
    const [fetchingSavedSelections, setFetchingSavedSelections] = useState(true)

    const [currentSelectionObj, setCurrentSelection] = useState({ Name: "", Id: "" })

    const [showWarning, setShowWarning] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const handleDeleteSelection = (id: string, name: string) => {
        setCurrentSelection({ Name: name, Id: id })
        setShowWarning(true)
    }

    const deleteSelection = (Id: string) => {
        const body = { Id, RegistryId: selectedRegistryId }
        prepareCsrfToken().then((csrfToken) =>
            deleteWithBodyAndCsrf(api.analyzeDeleteSalarySelection, csrfToken, JSON.stringify(body))
                .then(() => {
                    setSavedSelection((prevState) => {
                        const index = prevState.findIndex((item) => item.Id === Id)
                        if (index > -1) {
                            prevState.splice(index, 1)
                        }
                        return prevState
                    })
                    setPostError("")
                    setShowWarning(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setShowWarning(false)
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const handleChooseSelection = (id: string, name: string) => {
        const index = savedSelection.findIndex((el) => el.Id === id)
        setSelectedGroups(savedSelection[index].Groups)
        setSalaryType(savedSelection[index].SalaryType)
        setDiagramType(savedSelection[index].DiagramType)
        setSelection({ name, id })
        setActiveStep(2)
    }

    useEffect(() => {
        if (!registries.length && !registriesFetched)
            fetchRegistries(
                setRegistries,
                setNoRegisters,
                setSelectedRegistryId,
                setFetchingRegistries,
                setRegistriesFetched,
                setFetchError
            )
    }, [registries, setRegistries, registriesFetched, setRegistriesFetched, setSelectedRegistryId])

    useEffect(() => {
        if (!noRegisters && selectedRegistryId)
            fetchSalarySavedSelections(selectedRegistryId, setSavedSelection, setFetchingSavedSelections, setFetchError)
        if (noRegisters && selectedRegistryId) setFetchingSavedSelections(false)
    }, [selectedRegistryId, noRegisters])

    return (
        <div>
            {(fetchingSavedSelections || fetchingRegistries) && !fetchError && !noRegisters && <ContentSkeleton />}
            {noRegisters && <p>{REGISTER_TEXTS.NO_REGISTERS}</p>}
            {registries && !noRegisters && registriesFetched && (
                <div>
                    <div className="mb32">
                        <div className="mb48">
                            <h2>{ANALYZE_SALARY_DISTRIBUTION.S1_H2}</h2>
                        </div>
                        <SelectRegistry
                            registries={registries}
                            setRegistryId={setSelectedRegistryId}
                            defaultValue={selectedRegistryId}
                        />
                    </div>
                    {showWarning && (
                        <Warning
                            onClick={() => deleteSelection(currentSelectionObj.Id)}
                            showWarning={setShowWarning}
                            variant="delete"
                        >
                            {`${ANALYZE_SALARY_DISTRIBUTION.S1_WARNING_DELETE_SELECTION} ${currentSelectionObj.Name}`}
                        </Warning>
                    )}
                    {showConfirmation && (
                        <Confirmation>{ANALYZE_SALARY_DISTRIBUTION.S1_CONFIRMATION_DELETED_SELECTION}</Confirmation>
                    )}
                    {postError && <Error>{postError}</Error>}
                    {!savedSelection.length && !fetchingSavedSelections && (
                        <p>{ANALYZE_SALARY_DISTRIBUTION.S1_NO_SELECTIONS}</p>
                    )}
                    {!!savedSelection.length && !fetchingSavedSelections && (
                        <div>
                            <h3>{ANALYZE_SALARY_DISTRIBUTION.S1_H3}</h3>
                            <table className="salarySelectionsTable">
                                <thead>
                                    <tr>
                                        <th className="th-bb-1">{ANALYZE_SALARY_DISTRIBUTION.S1_TH1}</th>
                                        <th className="salaryDistCellWidth160 th-bb-1">
                                            {ANALYZE_SALARY_DISTRIBUTION.S1_TH2}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {savedSelection.map((selection) => (
                                        <tr key={selection.Id}>
                                            <td>{selection.Name}</td>
                                            <td>
                                                <div className="flex gap8">
                                                    <Button
                                                        small
                                                        onClick={() =>
                                                            handleChooseSelection(selection.Id, selection.Name)
                                                        }
                                                    >
                                                        {ANALYZE_SALARY_DISTRIBUTION.S1_BUTTON_CHOOSE}
                                                    </Button>
                                                    <Button
                                                        variant="delete"
                                                        small
                                                        onClick={() =>
                                                            handleDeleteSelection(selection.Id, selection.Name)
                                                        }
                                                    >
                                                        {ANALYZE_SALARY_DISTRIBUTION.S1_BUTTON_DELETE}
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default SalaryRegistriesAndSelections
