import "./HelpAccordion.css"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion"

import { useEffect, useState } from "react"
import DOMPurify from "dompurify"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { HELP_TEXTS } from "constants/text.constants"

import { dispatchBool, dispatchString, ISupportItem } from "types/sharedTypes"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"

import { deleteWithBodyAndCsrf } from "services/apiService"

import Warning from "components/atoms/warning/Warning"

import { ReactComponent as TrashIcon } from "./icons/trash-can.icon.svg"
import { ReactComponent as EditIcon } from "./icons/edit.icon.svg"

type HelpAccordionProps = {
    supportItemArray: ISupportItem[]
    setSupportItemArray: React.Dispatch<React.SetStateAction<ISupportItem[]>>
    agvAdmin: boolean
    handleEditSupportItem: any
    setShowConfirmation: dispatchBool
    setPostError: dispatchString
    isConceptsAndDefinitions?: boolean
}

const HelpAccordion = ({
    supportItemArray,
    setSupportItemArray,
    agvAdmin,
    handleEditSupportItem,
    setShowConfirmation,
    setPostError,
    isConceptsAndDefinitions = false,
}: HelpAccordionProps) => {
    const [showWarning, setShowWarning] = useState(false)

    const [supportItemId, setSupportItemId] = useState("")
    const [supportItemTitle, setSupportItemTitle] = useState("")

    if (isConceptsAndDefinitions) {
        supportItemArray.sort((a, b) => a.Title.localeCompare(b.Title))
    }

    const deleteSupportItem = (Id: string) => {
        prepareCsrfToken().then((csrfToken) =>
            deleteWithBodyAndCsrf(idMapper(api.deleteAnySupportItemById, Id), csrfToken, JSON.stringify({ Id }))
                .then(() => {
                    const newSupportItemArray = supportItemArray?.filter((item) => item.Id !== Id)
                    setSupportItemArray(newSupportItemArray)
                    setShowWarning(false)
                    setShowConfirmation(true)
                })
                .catch((err) => {
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const handleShowWarning = (item: ISupportItem) => {
        setShowWarning(true)
        setSupportItemId(item.Id)
        setSupportItemTitle(item.Title)
    }

    return (
        <div className="maxWidth800">
            <Accordion allowZeroExpanded className="helpAccordion">
                {supportItemArray.map((supportItem) => (
                    <div className="helpAccordionWrapper" key={supportItem.Id}>
                        <AccordionItem className="helpAccordionFlex">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="helpHeadingTextContainer">
                                        <p className="helpAccordionText">{supportItem.Title}</p>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                {/* Sanitize(DomPurify) first and then put into DSIH -> should be fine, .md filesting from BE is better for safety */}
                                {/* eslint-disable-next-line react/no-danger */}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(supportItem.Body).replace(
                                            /<a /g,
                                            '<a target="_blank" '
                                        ),
                                    }}
                                />
                            </AccordionItemPanel>
                        </AccordionItem>
                        {agvAdmin && (
                            <div className="helpAccordionManageButtonsFlex">
                                <button
                                    type="button"
                                    onClick={() =>
                                        handleEditSupportItem(supportItem.Id, supportItem.Title, supportItem.Body)
                                    }
                                    className="editButton"
                                >
                                    <EditIcon width="26px" height="26px" fill="#003A64" />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => handleShowWarning(supportItem)}
                                    className="editButton"
                                >
                                    <TrashIcon width="24px" height="24px" fill="#aa0e4c" className="icon" />
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </Accordion>
            {showWarning && (
                <Warning variant="delete" onClick={() => deleteSupportItem(supportItemId)} showWarning={setShowWarning}>
                    {`${HELP_TEXTS.DELETE_MANUAL_WARNING} ${supportItemTitle}`}
                </Warning>
            )}
        </div>
    )
}

export default HelpAccordion
