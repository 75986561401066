import api from "constants/endpoints.constants"
import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import GENERAL_TEXTS, { HELP_TEXTS } from "constants/text.constants"

import { ISupportItem, ISupportModalTextObject } from "types/sharedTypes"

import idMapper from "utils/idMapper"
import useScrollToTop from "hooks/useScrollToTop"
import prepareCsrfToken from "utils/prepareCsrfToken"

import RolesContext from "context/RolesContext"

import { useContext, useEffect, useState } from "react"

import { fetchSupportItem } from "services/fetchers"
import { postWithBodyAndCsrf, putWithBodyAndCsrf } from "services/apiService"

import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import { ReactComponent as CirclePlusIcon } from "assets/circle-plus-solid.icon.svg"

import SearchBar from "../components/searchBar/SearchBar"
import HelpAccordion from "../components/helpAccordion/HelpAccordion"
import AlphabetFilter from "../components/alphabetFilter/AlphabetFilter"
import editBodyDataToSave from "../components/tinymceEditor/editBodyDataToSave"
import HelpAndSupportModal from "../components/helpAndSupportModal/HelpAndSupportModal"

const ConceptsAndDefinitionsPage = () => {
    const { agvAdmin } = useContext(RolesContext)

    const [fetchError, setFetchError] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const [fetchingConceptsData, setFetchingConceptsData] = useState(true)
    const [conceptsData, setConceptsData] = useState<ISupportItem[]>([])

    const textsObjectInitialValues = { modalContent: "", h1: "", labelTitle: "", labelContent: "", submit: "" }
    const [textsObject, setTextsObject] = useState<ISupportModalTextObject>(textsObjectInitialValues)

    const [conceptsId, setConceptsId] = useState("")
    const [conceptsTitle, setConceptsTitle] = useState("")
    const [conceptsContent, setConceptsContent] = useState("")

    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)

    const [postingCreateConcept, setPostingCreateConcept] = useState(false)
    const [postingEditConcept, setPostingEditConcept] = useState(false)

    const [filter, setFilter] = useState("")
    const [aFilter, setAlphabeticalFilter] = useState("")
    const [filteredData, setFilteredData] = useState<ISupportItem[]>([])

    const handleCreateConceptsAndDefinitionItem = () => {
        const { MODAL_CONTENT_CREATE, HEADING_CONCEPTS_CREATE, LABEL_CONCEPTS_TITLE, LABEL_CONCEPTS_CONTENT } =
            HELP_TEXTS
        const { BUTTON_ADD } = GENERAL_TEXTS

        setConceptsTitle("")
        setConceptsContent("")

        setTextsObject({
            modalContent: MODAL_CONTENT_CREATE,
            h1: HEADING_CONCEPTS_CREATE,
            labelTitle: LABEL_CONCEPTS_TITLE,
            labelContent: LABEL_CONCEPTS_CONTENT,
            submit: BUTTON_ADD,
        })
        setShowCreateModal(true)
    }
    const handleEditConceptsAndDefinitionItem = (id: string, Concept: string, Definition: string) => {
        const { MODAL_CONTENT_EDIT, HEADING_CONCEPTS_UPDATE, LABEL_CONCEPTS_TITLE, LABEL_CONCEPTS_CONTENT } = HELP_TEXTS
        const { BUTTON_CHANGE } = GENERAL_TEXTS

        setConceptsId(id)
        setConceptsTitle(Concept)
        setConceptsContent(Definition)
        setTextsObject({
            modalContent: MODAL_CONTENT_EDIT,
            h1: HEADING_CONCEPTS_UPDATE,
            labelTitle: LABEL_CONCEPTS_TITLE,
            labelContent: LABEL_CONCEPTS_CONTENT,
            submit: BUTTON_CHANGE,
        })
        setShowEditModal(true)
    }

    const createConceptsItem = (Concept: string, Definition: string) => {
        setPostingCreateConcept(true)

        const body = { Concept, Definition: editBodyDataToSave(Definition) }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.getConceptsAndDefinitions, csrfToken, JSON.stringify(body))
                .then((response: string) => {
                    setPostingCreateConcept(false)
                    setPostError("")

                    conceptsData.push({
                        Id: response,
                        Title: Concept,
                        Body: editBodyDataToSave(Definition),
                        SortBy: Concept,
                    })

                    setShowCreateModal(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setPostingCreateConcept(false)
                    setShowCreateModal(false)
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const updateConceptItem = (Id: string, Concept: string, Definition: string) => {
        setPostingEditConcept(true)
        const body = { Id, Concept, Definition: editBodyDataToSave(Definition) }

        prepareCsrfToken().then((csrfToken) =>
            putWithBodyAndCsrf(idMapper(api.editConceptsAndDefinitionsById, Id), csrfToken, JSON.stringify(body))
                .then((response: string) => {
                    setPostingEditConcept(false)
                    setPostError("")
                    const indexOfUpdateConcept = conceptsData.findIndex(
                        (conceptsObject) => conceptsObject.Id === response
                    )

                    conceptsData[indexOfUpdateConcept] = {
                        ...conceptsData[indexOfUpdateConcept],
                        Title: Concept,
                        Body: editBodyDataToSave(Definition),
                    }

                    setShowEditModal(false)
                    setShowConfirmation(false)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setPostingEditConcept(false)
                    setShowEditModal(false)
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    useEffect(() => {
        fetchSupportItem(api.getConceptsAndDefinitions, setConceptsData, setFetchingConceptsData, setFetchError)
    }, [])

    useUpdatePageTitle(pageTitles.CONCEPTS_AND_DEFINITIONS_PAGE_TITLE)
    useScrollToTop()

    return (
        <div>
            <PageHeader pageId={pageIds.CONCEPTS_AND_DEFINITIONS_PAGE_ID} />
            {fetchingConceptsData && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingConceptsData && !fetchError && conceptsData && (
                <div className="maxWidth800">
                    <div className="searchAndAddButtonContainer">
                        <SearchBar
                            originalData={conceptsData}
                            label={HELP_TEXTS.SEARCH_LABEL}
                            filter={filter}
                            setFilter={setFilter}
                            setFilteredData={setFilteredData}
                        />

                        {agvAdmin && (
                            <Button Icon={CirclePlusIcon} onClick={handleCreateConceptsAndDefinitionItem}>
                                {GENERAL_TEXTS.BUTTON_ADD}
                            </Button>
                        )}
                    </div>

                    <AlphabetFilter
                        originalData={conceptsData}
                        setFilteredData={setFilteredData}
                        selectedLetter={aFilter}
                        setLetter={setAlphabeticalFilter}
                    />

                    {showConfirmation && (
                        <div className="mb16">
                            <Confirmation>{GENERAL_TEXTS.CONFIRMATION_SUCCESSFUL}</Confirmation>
                        </div>
                    )}
                    {postError && (
                        <div className="mb16">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    <HelpAccordion
                        supportItemArray={filter || aFilter ? filteredData : conceptsData}
                        setSupportItemArray={setConceptsData}
                        agvAdmin={agvAdmin}
                        handleEditSupportItem={handleEditConceptsAndDefinitionItem}
                        setShowConfirmation={setShowConfirmation}
                        setPostError={setPostError}
                        isConceptsAndDefinitions
                    />
                    {showCreateModal && (
                        <HelpAndSupportModal
                            showModal={showCreateModal}
                            setShowModal={setShowCreateModal}
                            posting={postingCreateConcept}
                            modalTexts={textsObject}
                            title={conceptsTitle}
                            setTitle={setConceptsTitle}
                            content={conceptsContent}
                            onClick={(content: string) => createConceptsItem(conceptsTitle, content)}
                        />
                    )}
                    {showEditModal && (
                        <HelpAndSupportModal
                            showModal={showEditModal}
                            setShowModal={setShowEditModal}
                            posting={postingEditConcept}
                            modalTexts={textsObject}
                            title={conceptsTitle}
                            setTitle={setConceptsTitle}
                            content={conceptsContent}
                            onClick={(content: string) => updateConceptItem(conceptsId, conceptsTitle, content)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default ConceptsAndDefinitionsPage
