import { useEffect, useState } from "react"

import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"
import defaultSetOptions from "components/cores/highcharts/highchartsDefaultSetOptions"

import { ItimeseriesBoxplotDiagramData } from "utils/Highcharts/prepareTimeseriesBoxplotDiagramData"

const color1520 = [
    "rgba(36, 102, 56, 1)",
    "rgba(42, 120, 67, 1)",
    "rgba(61, 144, 88, 1)",
    "rgba(96, 201, 129, 1)",
    "rgba(107, 221, 145, 1)",
    "rgba(131, 245, 168, 1)",
    "rgba(143, 249, 201, 1)",
    "rgba(156, 250, 223, 1)",
    "rgba(199, 252, 243, 1)",
    "rgba(227, 242, 253, 1)",
    "rgba(187, 222, 251, 1)",
    "rgba(144, 202, 249, 1)",
    "rgba(100, 181, 246, 1)",
    "rgba(66, 165, 245, 1)",
    "rgba(33, 150, 243, 1)",
    "rgba(30, 136, 229, 1)",
    "rgba(25, 118, 210, 1)",
    "rgba(21, 101, 192, 1)",
    "rgba(13, 71, 161, 1)",
]
const color1114 = [
    "rgba(36, 102, 56, 1)",
    "rgba(61, 144, 88, 1)",
    "rgba(81, 178, 112, 1)",
    "rgba(96, 201, 129, 1)",
    "rgba(131, 245, 168, 1)",
    "rgba(156, 250, 223, 1)",
    "rgba(199, 252, 243, 1)",
    "rgba(187, 222, 251, 1)",
    "rgba(144, 202, 249, 1)",
    "rgba(100, 181, 246, 1)",
    "rgba(33, 150, 243, 1)",
    "rgba(25, 118, 210, 1)",
    "rgba(21, 101, 192, 1)",
    "rgba(13, 71, 161, 1)",
]
const color710 = [
    "rgba(36, 102, 56, 1)",
    "rgba(61, 144, 88, 1)",
    "rgba(96, 201, 129, 1)",
    "rgba(131, 245, 168, 1)",
    "rgba(156, 250, 223, 1)",
    "rgba(187, 222, 251, 1)",
    "rgba(144, 202, 249, 1)",
    "rgba(33, 150, 243, 1)",
    "rgba(30, 136, 229, 1)",
    "rgba(21, 101, 192, 1)",
]
const color26 = [
    "rgba(36, 102, 56, 1)",
    "rgba(96, 201, 129, 1)",
    "rgba(156, 250, 223, 1)",
    "rgba(187, 222, 251, 1)",
    "rgba(66, 165, 245, 1)",
    "rgba(21, 101, 192, 1)",
]
const medianColor = ["rgba(255, 240, 175, 1)", "rgba(0, 64, 113, 1)"]
const stemWhiskersColor = ["rgba(14, 70, 31, 1)", "rgba(1, 65, 115, 1)"]

function privateGetFillColor(index: number, length: number) {
    if (length <= 6) {
        return color26[index]
    }
    if (length >= 7 && length <= 10) {
        return color710[index]
    }
    if (length >= 11 && length <= 14) {
        return color1114[index]
    }
    return color1520[index]
}

function privatecolorWhiskerStemColor(index: number, length: number) {
    if (length <= 6) {
        if (index <= 2) {
            return stemWhiskersColor[0]
        }
        return stemWhiskersColor[1]
    }
    if (length >= 7 && length <= 10) {
        if (index <= 4) {
            return stemWhiskersColor[0]
        }
        return stemWhiskersColor[1]
    }
    if (length >= 11 && length <= 14) {
        if (index <= 6) {
            return stemWhiskersColor[0]
        }
        return stemWhiskersColor[1]
    }
    if (index <= 9) {
        return stemWhiskersColor[0]
    }
    return stemWhiskersColor[1]
}

function privateGetMedianColor(index: number, length: number) {
    if (length <= 6 && (index === 0 || index === 5)) {
        return medianColor[0]
    }
    if (length >= 7 && length <= 10 && (index <= 1 || index >= 8)) {
        return medianColor[0]
    }
    if (length >= 11 && length <= 14 && (index <= 1 || index >= 11)) {
        return medianColor[0]
    }
    if (length >= 15 && (index <= 2 || index >= 16)) {
        return medianColor[0]
    }
    return medianColor[1]
}

function privateGetSerieColors(index: number, length: number) {
    return {
        colorWhiskerStem: privatecolorWhiskerStemColor(index, length),
        fill: privateGetFillColor(index, length),
        median: privateGetMedianColor(index, length),
    }
}

function getLegendIndex(name: string) {
    return Number(name.replace("-", "")) * -1
}

function scatterSerie(
    inputName: string,
    inputData: any[],
    boolShowInLegend: boolean,
    inputColor: any,
    inputLegendIndex: number
) {
    return {
        linkedTo: inputColor,
        type: "scatter",
        color: inputColor,
        name: inputName,
        data: inputData,
        legendIndex: inputLegendIndex,
        tooltip: {
            headerFormat: "{point.x}<br>",
            pointFormat: "Lön: {point.y} Kr",
        },
        showInLegend: boolShowInLegend,
        marker: {
            symbol: "circle",
        },
    }
}

function boxPlotSerie(
    inputName: any,
    inputData: any,
    boolShowMinMax: boolean,
    boolIsMoreThan20: boolean,
    boolShowInLegend: boolean,
    colorWhiskerStem: any,
    fill: any,
    median: any,
    inputLegendIndex: any
) {
    const customPointFormatter = boolIsMoreThan20
        ? `P90: {point.high} Kr<br> P75: {point.q3} Kr<br> P50: {point.median} Kr<br> P25: {point.q1} Kr<br> P10: {point.low} Kr`
        : `P75: {point.q3} Kr<br> P50: {point.median} Kr<br> P25: {point.q1} Kr`

    return {
        // id: fill, // this was an issue before because id should be unique. and it seems to be unecessary so i removed it. may cause issues in the future though
        type: "boxplot",
        name: inputName,
        color: fill,
        fillColor: fill,
        medianColor: median,
        whiskerColor: colorWhiskerStem,
        stemColor: colorWhiskerStem,
        lineWidth: 3,
        medianWidth: 2,
        width: 100,
        grouping: false,
        data: inputData,
        legendIndex: inputLegendIndex,
        showInLegend: boolShowInLegend,
        tooltip: { pointFormat: customPointFormatter },
    }
}

const privateGetSeriesData = (diagramData: ItimeseriesBoxplotDiagramData, boolShowMinMax: boolean) => {
    const series: { Key: string; Value: string }[] = []
    const data: {
        name: string
        data: {
            moreThan20: number[][]
            lesThan21: number[][]
            lesThan9: number[][]
        }
    }[] = [
        {
            name: "",
            data: {
                moreThan20: [],
                lesThan21: [],
                lesThan9: [],
            },
        },
    ]

    diagramData.Categories2.forEach((item) => {
        const Item = series.find((a) => a.Key === item.Key)
        if (Item === undefined) {
            series.push(item)
        }
    })

    series.forEach((item) => {
        const moreThan20Array: number[][] = []
        const lesThan21Array: number[][] = []
        const lesThan9Array: number[][] = []
        const items = diagramData.Data.filter((a: any) => a[a.length - 1].Key === item.Key)

        items.forEach((arr: any[]) => {
            if (arr.length === 3) {
                lesThan9Array.push(arr)
            } else if (arr.length === 9) {
                if (boolShowMinMax) {
                    moreThan20Array.push(arr)
                } else {
                    const newArray = [arr[0], arr[2], arr[3], arr[4], arr[5], arr[6]]
                    moreThan20Array.push(newArray)
                }
            } else if (arr.length === 7) {
                if (boolShowMinMax) {
                    const newArray = [arr[0], arr[1], arr[2], arr[2], arr[3], arr[4], arr[4], arr[5], arr[6]]
                    lesThan21Array.push(newArray)
                } else {
                    const newArray = [arr[0], arr[2], arr[2], arr[3], arr[4], arr[4]]
                    lesThan21Array.push(newArray)
                }
            }
        })
        data.push({
            name: item.Value,
            data: {
                moreThan20: moreThan20Array,
                lesThan21: lesThan21Array,
                lesThan9: lesThan9Array,
            },
        })
    })
    return data
}

function privateSerie(diagramData: ItimeseriesBoxplotDiagramData, boolShowMinMax: boolean) {
    const object: any[] = []
    const data = privateGetSeriesData(diagramData, boolShowMinMax)

    const legendIndexArr = data
        .map((obj, index) => ({ key: index, value: getLegendIndex(obj.name) }))
        .sort((a, b) => a.value - b.value)

    data.forEach((d, index: number) => {
        const legendIndexIndex = legendIndexArr.map((keyval) => keyval.key).indexOf(index)
        const legendIndex = legendIndexArr[legendIndexIndex].value
        const serieColor = privateGetSerieColors(legendIndexIndex, data.length)
        if (d.data.moreThan20.length > 0) {
            object.push(
                boxPlotSerie(
                    d.name,
                    d.data.moreThan20,
                    boolShowMinMax,
                    true,
                    d.data.moreThan20.length > 0,
                    serieColor.colorWhiskerStem,
                    serieColor.fill,
                    serieColor.median,
                    legendIndex
                )
            )
        }
        if (d.data.lesThan21.length > 0) {
            object.push(
                boxPlotSerie(
                    d.name,
                    d.data.lesThan21,
                    boolShowMinMax,
                    false,
                    d.data.moreThan20.length === 0,
                    serieColor.colorWhiskerStem,
                    serieColor.fill,
                    serieColor.median,
                    legendIndex
                )
            )
        }
        if (d.data.lesThan9.length > 0) {
            object.push(
                scatterSerie(
                    d.name,
                    d.data.lesThan9,
                    d.data.moreThan20.length === 0 && d.data.lesThan21.length === 0,
                    serieColor.fill,
                    legendIndex
                )
            )
        }
    })
    return object
}

function getDisplayValuesCategories2(keyValuePairList: { Key: string; Value: string }[]) {
    const result: string[] = []
    keyValuePairList.forEach((pair) => {
        result.push(pair.Value)
    })
    return result
}

type TimeseriesBoxplotDiagramProps = {
    diagramData: ItimeseriesBoxplotDiagramData
    showMinorGridLines: boolean
}

const TimeseriesBoxplotDiagram = ({ diagramData, showMinorGridLines }: TimeseriesBoxplotDiagramProps) => {
    HighchartsMore(Highcharts)

    Highcharts.setOptions(defaultSetOptions)

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        chart: {
            type: "boxplot",
            inverted: true,
            height: diagramData.Categories.length > 10 ? "800px" : "400px",
        },
        title: { text: diagramData.ChartTitle },
        yAxis: {
            title: {
                text: diagramData.YAxisTitle,
            },
            lineWidth: 1,
            labels: {
                style: {
                    fontSize: "14px",
                    textOverflow: "none",
                    whiteSpace: "nowrap",
                },
            },
            tickInterval: 5000,
        },
        xAxis: [
            {
                categories: diagramData.Categories,
                min: 0,
                max: diagramData.Categories.length - 1,
                labels: {
                    style: {
                        fontSize: "14px",
                    },
                },
            },
            {
                linkedTo: 0,
                categories: getDisplayValuesCategories2(diagramData.Categories2),
                opposite: true,
                labels: {
                    style: {
                        fontSize: "14px",
                    },
                },
            },
        ],
        series: privateSerie(diagramData, false),
        plotOptions: {
            series: {
                animation: false,
            },
        },
        legend: {
            itemStyle: {
                color: "#444444",
                fontWeight: "regular",
                fontSize: "14px",
                align: "center",
                cursor: "pointer",
            },
        },
        credits: {
            enabled: false,
        },
    })

    useEffect(() => {
        setChartOptions((options) => ({
            ...options,
            yAxis: {
                minorTickInterval: showMinorGridLines ? "auto" : undefined,
            },
        }))
    }, [showMinorGridLines])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default TimeseriesBoxplotDiagram
