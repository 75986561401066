import "./ContactPage.css"

import { HELP_TEXTS } from "constants/text.constants"
import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"

import { useContext, useEffect, useState } from "react"

import textMatcher from "utils/textMatcher"
import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import RolesContext from "context/RolesContext"

import { fetchAgvAdmin, fetchTexts } from "services/fetchers"

import TextContext from "context/TextContext"
import PageHeader from "components/cores/pageHeader/PageHeader"
import EditableText from "components/cores/editableText/EditableText"

const ContactPage = () => {
    const { texts, setTexts } = useContext(TextContext)
    const { agvAdmin, setAgvAdmin, agvAdminFetched, setAgvAdminFetched } = useContext(RolesContext)

    const [fetchingAgvAdmin, setFetchingAgvAdmin] = useState(!texts.length)
    const [fetchingTexts, setFetchingTexts] = useState(!agvAdminFetched)

    const pageId = pageIds.CONTACT_PAGE_ID

    // consider moving getAll(texts) call higher up in the app to avoid multiple calls
    useEffect(() => {
        if (!texts.length) {
            fetchTexts(setTexts, setFetchingTexts)
        } else setFetchingTexts(false)
    }, [texts, setTexts])

    useEffect(() => {
        if (!agvAdminFetched) {
            fetchAgvAdmin(setAgvAdmin, setFetchingAgvAdmin, setAgvAdminFetched)
        } else setFetchingAgvAdmin(false)
    }, [agvAdmin, setAgvAdmin, agvAdminFetched, setAgvAdminFetched])

    useUpdatePageTitle(pageTitles.CONTACT_PAGE_TITLE)
    useScrollToTop()

    return (
        <div>
            <PageHeader pageId={pageIds.CONTACT_PAGE_ID} />
            {!fetchingTexts && !fetchingAgvAdmin && (
                <div className="panelContainer">
                    <section className="panel">
                        <header className="panelHeader">
                            <h2 className="panelTitle">{HELP_TEXTS.HEADING_SUPPORT}</h2>
                        </header>
                        <div className="panelContent">
                            <EditableText
                                text={textMatcher(texts, pageId, "agvParagraph")}
                                elementType="agvParagraph"
                                pageId={pageId}
                                canEdit={agvAdmin}
                            />
                            {/* The phonenumber is currently not displayed */}
                            {/* <span className="span">
                            {HELP_TEXTS.TELEPHONE}
                            <EditableText
                                text={textMatcher(texts, pageId, "agvPhone")}
                                elementType="agvPhone"
                                pageId={pageId}
                                canEdit={agvAdmin}
                            />
                        </span> */}
                            <span className="span">
                                {HELP_TEXTS.EPOST}
                                <EditableText
                                    text={textMatcher(texts, pageId, "agvMail")}
                                    elementType="agvMail"
                                    pageId={pageId}
                                    canEdit={agvAdmin}
                                />
                            </span>
                        </div>
                    </section>
                    <section className="panel">
                        <header className="panelHeader">
                            <h2 className="panelTitle">{HELP_TEXTS.HEADING_TECHNICAL_SUPPORT}</h2>
                        </header>
                        <div className="panelContent">
                            <section className="panelSubtitle">
                                <EditableText
                                    text={textMatcher(texts, pageId, "knowitParagraph")}
                                    elementType="knowitParagraph"
                                    pageId={pageId}
                                    canEdit={agvAdmin}
                                />
                            </section>
                            <span className="span">
                                {HELP_TEXTS.TELEPHONE}
                                <EditableText
                                    text={textMatcher(texts, pageId, "knowitPhone")}
                                    elementType="knowitPhone"
                                    pageId={pageId}
                                    canEdit={agvAdmin}
                                />
                            </span>
                            <span className="span">
                                {HELP_TEXTS.EPOST}
                                <EditableText
                                    text={textMatcher(texts, pageId, "knowitMail")}
                                    elementType="knowitMail"
                                    pageId={pageId}
                                    canEdit={agvAdmin}
                                />
                            </span>
                        </div>
                    </section>
                </div>
            )}
        </div>
    )
}

export default ContactPage
