import "./FaqPage.css"

import pageIds from "constants/pageIds.constants.json"
import api from "constants/endpoints.constants"
import pageTitles from "constants/pageTitles.constants.json"
import GENERAL_TEXTS, { HELP_TEXTS } from "constants/text.constants"

import { ISupportItem, ISupportModalTextObject } from "types/sharedTypes"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { useContext, useEffect, useState } from "react"

import { fetchSupportItem } from "services/fetchers"
import { postWithBodyAndCsrf, putWithBodyAndCsrf } from "services/apiService"

import RolesContext from "context/RolesContext"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import { ReactComponent as CirclePlusIcon } from "assets/circle-plus-solid.icon.svg"

import HelpAccordion from "../components/helpAccordion/HelpAccordion"
import HelpAndSupportModal from "../components/helpAndSupportModal/HelpAndSupportModal"
import editBodyDataToSave from "../components/tinymceEditor/editBodyDataToSave"
import SearchBar from "../components/searchBar/SearchBar"

const FaqPage = () => {
    const { agvAdmin } = useContext(RolesContext)

    const [fetchError, setFetchError] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const [faqData, setFaqData] = useState<ISupportItem[]>([])
    const [fetchingFaqData, setFetchingFaqData] = useState(true)

    const [postingCreateQuestion, setPostingCreateQuestion] = useState(false)
    const [postingEditQuestion, setPostingEditQuestion] = useState(false)

    const textsObjectInitialValues = { modalContent: "", h1: "", labelTitle: "", labelContent: "", submit: "" }
    const [textsObject, setTextsObject] = useState<ISupportModalTextObject>(textsObjectInitialValues)

    const [faqId, setFaqId] = useState("")
    const [faqTitle, setFaqTitle] = useState("")
    const [faqContent, setFaqContent] = useState("")

    const [originalFaqTitle, setOriginalFaqTitle] = useState("")
    const [fieldsChanged, setFieldsChanged] = useState(false)

    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)

    const [filter, setFilter] = useState("")
    const [filteredData, setFilteredData] = useState<ISupportItem[]>([])

    const updateTitleChanged = (changed: boolean) => {
        setFieldsChanged(changed)
    }

    const handleCreateFaqItem = () => {
        const { MODAL_CONTENT_CREATE, HEADING_FAQ_CREATE, LABEL_FAQ_TITLE, LABEL_FAQ_CONTENT } = HELP_TEXTS
        const { BUTTON_ADD } = GENERAL_TEXTS

        setFaqTitle("")
        setFaqContent("")

        setTextsObject({
            modalContent: MODAL_CONTENT_CREATE,
            h1: HEADING_FAQ_CREATE,
            labelTitle: LABEL_FAQ_TITLE,
            labelContent: LABEL_FAQ_CONTENT,
            submit: BUTTON_ADD,
        })
        setShowCreateModal(true)
    }

    const handleEditFaqItem = (id: string, Question: string, Answer: string) => {
        const { MODAL_CONTENT_EDIT, HEADING_FAQ_UPDATE, LABEL_FAQ_TITLE, LABEL_FAQ_CONTENT } = HELP_TEXTS
        const { BUTTON_CHANGE } = GENERAL_TEXTS

        setFaqId(id)
        setFaqTitle(Question)
        setFaqContent(Answer)
        setTextsObject({
            modalContent: MODAL_CONTENT_EDIT,
            h1: HEADING_FAQ_UPDATE,
            labelTitle: LABEL_FAQ_TITLE,
            labelContent: LABEL_FAQ_CONTENT,
            submit: BUTTON_CHANGE,
        })
        setShowEditModal(true)

        setOriginalFaqTitle(Question)

        setFieldsChanged(false)
    }

    const createFaqItem = (Question: string, Answer: string) => {
        setPostingCreateQuestion(true)
        const body = { Question, Answer: editBodyDataToSave(Answer) }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.getFAQ, csrfToken, JSON.stringify(body))
                .then((response: string) => {
                    setPostingCreateQuestion(false)
                    setPostError("")

                    faqData.unshift({
                        Id: response,
                        Title: Question,
                        Body: editBodyDataToSave(Answer),
                        SortBy: Question,
                    })

                    setShowCreateModal(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setPostingCreateQuestion(false)
                    setShowCreateModal(false)
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const updateFaqItem = (Id: string, Question: string, Answer: string) => {
        setPostingEditQuestion(true)

        const body = { Id, Question, Answer: editBodyDataToSave(Answer) }
        prepareCsrfToken().then((csrfToken) =>
            putWithBodyAndCsrf(idMapper(api.editFAQById, Id), csrfToken, JSON.stringify(body))
                .then((response: string) => {
                    setPostingEditQuestion(false)
                    setPostError("")
                    const indexOfUpdatedQuestion = faqData.findIndex((faqObject) => faqObject.Id === response)

                    // Mutate faqData since we have updated it
                    faqData[indexOfUpdatedQuestion] = {
                        ...faqData[indexOfUpdatedQuestion],
                        Title: Question,
                        Body: editBodyDataToSave(Answer),
                    }
                    setShowEditModal(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setPostingEditQuestion(false)
                    setShowEditModal(false)
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    useEffect(() => {
        fetchSupportItem(api.getFAQ, setFaqData, setFetchingFaqData, setFetchError)
    }, [])

    useUpdatePageTitle(pageTitles.FAQ_PAGE_TITLE)
    useScrollToTop()

    return (
        <div>
            <PageHeader pageId={pageIds.FAQ_PAGE_ID} />
            {fetchingFaqData && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingFaqData && !fetchError && faqData && (
                <div className="maxWidth800">
                    <div className="searchAndAddButtonContainer">
                        <SearchBar
                            originalData={faqData}
                            label={HELP_TEXTS.SEARCH_LABEL}
                            filter={filter}
                            setFilter={setFilter}
                            setFilteredData={setFilteredData}
                        />
                        {agvAdmin && (
                            <Button Icon={CirclePlusIcon} onClick={handleCreateFaqItem}>
                                {GENERAL_TEXTS.BUTTON_ADD}
                            </Button>
                        )}
                    </div>
                    {showConfirmation && (
                        <div className="mb16">
                            <Confirmation>{GENERAL_TEXTS.CONFIRMATION_SUCCESSFUL}</Confirmation>
                        </div>
                    )}
                    {postError && (
                        <div className="mb16">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    <HelpAccordion
                        supportItemArray={filter ? filteredData : faqData}
                        setSupportItemArray={setFaqData}
                        agvAdmin={agvAdmin}
                        handleEditSupportItem={handleEditFaqItem}
                        setShowConfirmation={setShowConfirmation}
                        setPostError={setPostError}
                    />
                    {showCreateModal && (
                        <HelpAndSupportModal
                            showModal={showCreateModal}
                            setShowModal={setShowCreateModal}
                            posting={postingCreateQuestion}
                            modalTexts={textsObject}
                            title={faqTitle}
                            setTitle={setFaqTitle}
                            content={faqContent}
                            onClick={(content: string) => createFaqItem(faqTitle, content)}
                        />
                    )}
                    {showEditModal && (
                        <HelpAndSupportModal
                            showModal={showEditModal}
                            setShowModal={setShowEditModal}
                            posting={postingEditQuestion}
                            modalTexts={textsObject}
                            title={faqTitle}
                            setTitle={setFaqTitle}
                            content={faqContent}
                            onClick={(content: string) => updateFaqItem(faqId, faqTitle, content)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default FaqPage
