import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { useState, useEffect } from "react"

import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf } from "services/apiService"
import { fetchEquivalentWorkGroups } from "services/fetchers"

import { dispatchNumber, tEquivalentWork } from "types/sharedTypes"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import CommentsModal from "components/cores/commentsModal/CommentsModal"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import { ReactComponent as downloadIcon } from "assets/file-arrow-down.icon.svg"
import { ReactComponent as CircleIcon } from "assets/circle-exclamation.icon.svg"
import { ReactComponent as TriangleIcon } from "assets/triangle-exclamation.icon.svg"
import { ReactComponent as VenusIcon } from "assets/venus.icon.svg"
import { ReactComponent as CommentsIcon } from "assets/comment-dots.icon.svg"

import EquivalentWorkReviewGroupsTable from "./equivalentWorkReviewGroupsTable/EquivalentWorkReviewGroupsTable"

interface IEquivalentWorkReviewGroups {
    analysisId: string
    isNewBesta: boolean
    setActiveStep: dispatchNumber
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
    employeeType?: "ExcludingManagers" | "Managers" | "All"
}

const EquivalentWorkReviewGroups = ({
    analysisId,
    isNewBesta,
    setActiveStep,
    setActiveSteps,
    employeeType = "All",
}: IEquivalentWorkReviewGroups) => {
    const [fetchError, setFetchError] = useState("")
    const [postError, setPostError] = useState("")

    const [equivalentWorkGroups, setEquivalentWorkGroups] = useState<tEquivalentWork[]>([])
    const [fetchingEquivalentWorkGroups, setFetchingEquivalentWorkGroups] = useState(true)

    const [showCommentsModal, setShowCommentsModal] = useState(false)
    const [currentGroupInfo, setCurrentGroupInfo] = useState({ Name: "", Id: "" })

    const { S6_VENUS_ICON_ALT, S6_COMMENTS_ICON_ALT, S6_IS_HIGH_GRP_LVL_ICON_ALT, S6_IS_LOW_GRP_LVL_ICON_ALT } =
        ANALYZE_WAGE_GAP

    let h2 = ANALYZE_WAGE_GAP.S6_H2_ALL
    if (isNewBesta)
        h2 = employeeType === "Managers" ? ANALYZE_WAGE_GAP.S6_H2_MANAGERS : ANALYZE_WAGE_GAP.S6_H2_NON_MANAGERS

    const [downloadingCSV, setDownloadingCSV] = useState(false)

    const downloadCSV = () => {
        setPostError("")
        setDownloadingCSV(true)

        let filename = ANALYZE_WAGE_GAP.S6_EXPORT_FILENAME_ALL
        if (isNewBesta)
            filename =
                employeeType === "Managers"
                    ? ANALYZE_WAGE_GAP.S6_EXPORT_FILENAME_MANAGERS
                    : ANALYZE_WAGE_GAP.S6_EXPORT_FILENAME_EMPLOYEES
        const body = { AnalyseId: analysisId, EmployeeType: employeeType }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.analyzeWageGapStepSixExportCSV, csrfToken, JSON.stringify(body), true)
                .then((response) => {
                    response.blob().then((blob: any) => {
                        // const url = window.URL.createObjectURL(new Blob([blob], { type: "text/plain;charset=utf-8" }))
                        const url = window.URL.createObjectURL(new Blob([blob]))
                        const link = document.createElement("a")
                        link.setAttribute("href", url)
                        link.setAttribute("download", filename)
                        document.body.appendChild(link)
                        link.click()
                    })
                    setDownloadingCSV(false)
                })
                .catch(() => {
                    setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                    setDownloadingCSV(false)
                })
        )
    }

    useEffect(() => {
        if (isNewBesta) setActiveSteps([1, 2, 3, 4, 5, 6, 7, 8])
        else setActiveSteps([1, 2, 3, 4, 5, 6, 7])
    }, [isNewBesta, setActiveSteps])

    useEffect(() => {
        fetchEquivalentWorkGroups(
            analysisId,
            isNewBesta,
            employeeType,
            setEquivalentWorkGroups,
            setFetchingEquivalentWorkGroups,
            setFetchError
        )
    }, [analysisId, employeeType, isNewBesta])

    // useEffect(() => {
    //     if (fetchingEquivalentWorkGroups) return

    //     if (equivalentWorkGroups.length) {
    //         const body = { AnalyseId: analysisId, CommentType: 2, Groups: equivalentWorkGroups }

    //         prepareCsrfToken().then((csrfToken) =>
    //             postWithBodyAndCsrf(api.analyzeCommentsForAllGroups, csrfToken, JSON.stringify(body))
    //                 .then((commentedGroupsArray: string[]) => {
    //                     if (commentedGroupsArray.length) {
    //                         equivalentWorkGroups.forEach((group) => {
    //                             if (commentedGroupsArray.includes(group.GroupId)) {
    //                                 // eslint-disable-next-line no-param-reassign
    //                                 group.HaveComment = true
    //                             }
    //                         })
    //                     }
    //                     setFetchingAllComments(false)
    //                     setFetchError("")
    //                 })
    //                 .catch(() => {
    //                     setFetchingAllComments(false)
    //                     setFetchError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
    //                 })
    //         )
    //     } else setFetchingAllComments(false)
    // }, [analysisId, equivalentWorkGroups, fetchingEquivalentWorkGroups])

    return (
        <>
            {fetchingEquivalentWorkGroups && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingEquivalentWorkGroups && !fetchError && (
                <div>
                    <h2>{h2}</h2>
                    {postError && (
                        <div className="mb24">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    {!equivalentWorkGroups.length && (
                        <p>
                            {employeeType === "Managers"
                                ? ANALYZE_WAGE_GAP.S6_NO_MANAGERS
                                : ANALYZE_WAGE_GAP.S6_NO_EMPLOYEES}
                        </p>
                    )}
                    {!!equivalentWorkGroups.length && (
                        <div>
                            <p>{ANALYZE_WAGE_GAP.S6_STEP_EXPLANATION}</p>
                            <p>{ANALYZE_WAGE_GAP.S6_STEP_EXPLANATION_2}</p>
                            <div className="pl16 mb32">
                                <p className="flex gap8 ai-center margin0">
                                    <VenusIcon width="1rem" height="1rem" fill="#003a64" title={S6_VENUS_ICON_ALT} />
                                    {ANALYZE_WAGE_GAP.S6_VENUS_ICON_EXPLANATION}
                                </p>
                                <p className="flex gap8 ai-center margin0">
                                    <CommentsIcon
                                        width="1rem"
                                        height="1rem"
                                        fill="#003a64"
                                        title={S6_COMMENTS_ICON_ALT}
                                    />
                                    {ANALYZE_WAGE_GAP.S6_COMMENTS_ICON_EXPLANATION}
                                </p>
                                <div className="flex gap8">
                                    <div>
                                        <CircleIcon
                                            width="1rem"
                                            height="1rem"
                                            fill="#003a64"
                                            title={S6_IS_HIGH_GRP_LVL_ICON_ALT}
                                        />
                                    </div>
                                    <p className="margin0">{ANALYZE_WAGE_GAP.S6_IS_HIGH_GRP_LVL_ICON_EXPLANATION}</p>
                                </div>
                                <div className="flex gap8">
                                    <div className="flex ai-center">
                                        <TriangleIcon
                                            width="1rem"
                                            height="1rem"
                                            fill="#003a64"
                                            title={S6_IS_LOW_GRP_LVL_ICON_ALT}
                                        />
                                    </div>
                                    <p className="margin0">{ANALYZE_WAGE_GAP.S6_IS_LOW_GRP_LVL_ICON_EXPLANATION}</p>
                                </div>
                            </div>
                            <div className="flex gap16 jc-flex-end">
                                <Button Icon={downloadIcon} onClick={downloadCSV} isLoading={downloadingCSV}>
                                    {ANALYZE_WAGE_GAP.S6_BUTTON_EXPORT_CSV}
                                </Button>
                            </div>
                            <div>
                                <p className="labelALike margin0 mb8">{ANALYZE_WAGE_GAP.S6_LABEL_REVIEW_ASSESSMENTS}</p>
                                <div className="mb16">
                                    <EquivalentWorkReviewGroupsTable
                                        equivalentWorkGroups={equivalentWorkGroups}
                                        setShowCommentsModal={setShowCommentsModal}
                                        setCurrentGroupInfo={setCurrentGroupInfo}
                                    />
                                </div>
                            </div>
                            {showCommentsModal && (
                                <CommentsModal
                                    analysisId={analysisId}
                                    workType="equivalent"
                                    groupInfo={currentGroupInfo}
                                    showModal={showCommentsModal}
                                    setShowModal={setShowCommentsModal}
                                    employeeType={employeeType}
                                    setEquivalentWorkGroups={setEquivalentWorkGroups}
                                    setFetchingEquivalentWorkGroups={setFetchingEquivalentWorkGroups}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default EquivalentWorkReviewGroups
