import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { REGISTER_TEXTS } from "constants/text.constants"

import { Employees, Header, ICustomColumns, IGetEmployees, dispatchBool } from "types/sharedTypes"

import { useContext, useEffect, useState, useRef, CSSProperties } from "react"
import ReactModal from "react-modal"

import { RegistriesContext } from "context/RegistriesContext"

import idMapper from "utils/idMapper"
import downloadCSV from "utils/downloadCSV"
import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf, getJSONrequestWithCsrf, deleteWithBodyAndCsrf } from "services/apiService"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import SliderButton from "components/atoms/sliderButton/SliderButton"
import RegisterTable from "components/cores/registerTable/RegisterTable"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import { ReactComponent as xMark } from "assets/xmark.icon.svg"
import { ReactComponent as bookIcon } from "assets/book.icon.svg"
import { ReactComponent as downloadPDFIcon } from "assets/pdf.icon.svg"
import { ReactComponent as editIcon } from "assets/pen-to-square.icon.svg"
import { ReactComponent as deleteIcon } from "assets/trash-solid.icon.svg"
import { ReactComponent as downloadIcon } from "assets/file-arrow-down.icon.svg"

import BoxplotDiagram from "components/cores/highcharts/boxplot/BoxplotDiagram"

import TableBESTA from "../tabels/BESTA/TableBESTA"
import SimpleAmoutTable from "../tabels/simpleAmount/SimpleAmountTable"
import DeleteRegisterWarning from "../deleteRegisterWarning/DeleteRegisterWarning"
import ChangeCustomColumnsForm from "./changeCustomColumnsForm/ChangeCustomColumnsForm"
import EmployeesPercentilesTable from "../tabels/employeesPercentilesTable/EmployeesPercentilesTable"

interface ISalaryDistribution {
    AllEmployees: string[]
    OnlyManagers: string[]
    WithoutManagers: string[]
}
interface ITableHeadData {
    Count: number
    EmployeesWithNoBesta: number
    EmployeesWithNoOrganisation: number
    PrevCount: number
}
interface ITableData {
    salaryDistribution: string[][]
    amountOfIndividuals: string[][]
    bestaCountsWithoutManagers: string[][]
    bestaCountsManagers: string[][]
    lokalKompletteringCounts: string[][]
    consultationCounts: string[][]
    workRelatedCounts: string[][]
    categoryCounts: string[][]
    bestaExtras: {
        noClassificationCounts: number
        notSuitableCodeCount: number
        secretaryGeneralCount: number
    }
}

function prepareT1Values2(object: ITableHeadData) {
    const { Count, EmployeesWithNoBesta, EmployeesWithNoOrganisation, PrevCount } = object
    return [
        [
            Count.toString(),
            EmployeesWithNoBesta.toString(),
            EmployeesWithNoOrganisation.toString(),
            PrevCount.toString(),
        ],
    ]
}
function prepareT2Values(object: ISalaryDistribution) {
    const { AllEmployees, OnlyManagers, WithoutManagers } = object
    const dataArray = [AllEmployees, OnlyManagers, WithoutManagers]

    return dataArray
}

type RegisterContentProps = {
    id: string
    name: string
    registeryOverview: any
    diagramData: any
    fetchingRegisterOverview: boolean
    fetchError: string
    setShowConfirmation: dispatchBool
    isInActiveSalarySimulation: boolean
}

// https://www.highcharts.com/demo/box-plot
const RegisterContent = ({
    id,
    name,
    registeryOverview,
    diagramData,
    fetchingRegisterOverview,
    fetchError,
    setShowConfirmation,
    isInActiveSalarySimulation,
}: RegisterContentProps) => {
    const tableInitialValues = {
        salaryDistribution: [[""]],
        amountOfIndividuals: [[""]],
        bestaCountsWithoutManagers: [[""]],
        bestaCountsManagers: [[""]],
        lokalKompletteringCounts: [[""]],
        consultationCounts: [[""]],
        workRelatedCounts: [[""]],
        categoryCounts: [[""]],
        bestaExtras: {
            noClassificationCounts: 0,
            notSuitableCodeCount: 0,
            secretaryGeneralCount: 0,
        },
    }
    const { registries, setRegistries } = useContext(RegistriesContext)

    const [fetchModalDataError, setFetchModalDataError] = useState("")

    const [postError, setPostError] = useState("")
    const [showWarning, setShowWarning] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [tableData, setTableData] = useState<ITableData>(tableInitialValues)

    const [showMinorGridLines, setShowMinorGridLines] = useState(true)
    const [showMinMax, setShowMinMax] = useState(false)

    const [tableHeaderRows, setTableHeaderRows] = useState<Header>()

    const [customColumnsData, setCustomColumnsData] = useState<ICustomColumns>()
    const [fetchingCustomColumns, setFetchingCustomColumns] = useState(false)

    const [employees, setEmployees] = useState<Employees[]>()
    const [fetchingEmployees, setFetchingEmployees] = useState(false)

    const [downloadingPDF, setDownloadingPDF] = useState(false)

    const [modalContent, setModalContent] = useState("")

    const allRef = useRef<HTMLDivElement>(null)

    // The reason why we use style = {} here is due to how exportPDF endpoint works, it will catch css styled with "style" but not className
    // We use separate .css files or global css through common.css ; style={} to be used ONLY when you need to export via this endpoint
    // When refactoring backend it would be a good idea to rewrite the functionality so it can work with both className and style.
    const styles: { [key: string]: CSSProperties } = {
        textStyle: { display: "flex", gap: 4, marginTop: 0, marginBottom: 0 },
        tablesContainer: {
            display: "flex",
            gap: 24,
            flexWrap: "wrap",
            marginBottom: 24,
        },
    }

    const handleOpenRegisteryModal = (registeryId: string) => {
        setFetchingEmployees(true)
        prepareCsrfToken()
            .then((csrfToken) =>
                postWithBodyAndCsrf(api.getEmployees, csrfToken, JSON.stringify({ id: registeryId })).then(
                    (registerResponse: IGetEmployees) => {
                        setEmployees(registerResponse.Employees)
                        setTableHeaderRows(registerResponse.Header)
                        setModalContent(REGISTER_TEXTS.SHOW_REGISTERY)
                        setFetchingEmployees(false)
                        setShowModal(true)
                    }
                )
            )
            .catch(() => {
                setFetchModalDataError(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
                setFetchingEmployees(false)
            })
    }

    const renameCustomColumns = () => {
        const customColumnsInitialData = {
            Id: "0526d238-0303-cf49-f6e3-08daf8a0e402",
            RegistryId: "00000000-0000-0000-0000-000000000000",
            CustomColumn1: "Fri1",
            CustomColumn2: "Fri2",
            CustomColumn3: "Fri3",
            CustomColumn4: "Fri4",
            CustomColumn5: "Fri5",
            CustomColumn6: "Fri6",
            CustomColumn7: "Fri7",
            CustomColumn8: "Fri8",
            CustomColumn9: "Fri9",
            CustomColumn10: "Fri100",
        }

        setFetchingCustomColumns(true)
        prepareCsrfToken()
            .then((csrfToken) =>
                getJSONrequestWithCsrf(idMapper(api.getCustomColumnsById, id), csrfToken).then(
                    (customColumns: ICustomColumns) => {
                        // setIsLoading(false)
                        // console.log("d", customColumns)
                        // ask BE not to return default values instead null
                        if (customColumns === null) setCustomColumnsData(customColumnsInitialData)
                        else setCustomColumnsData(customColumns)
                        setModalContent(REGISTER_TEXTS.CUSTOM_COLUMNS)
                        setFetchingCustomColumns(false)
                        setShowModal(true)
                    }
                )
            )
            .catch(() => {
                setFetchModalDataError(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
                setFetchingCustomColumns(false)
            })
    }

    const handleDeleteRegister = (deleteAll: boolean) => {
        setPostError("")
        const url = deleteAll ? idMapper(api.deleteRegisterAndCommentsById, id) : idMapper(api.deleteRegisterById, id)

        prepareCsrfToken().then((csrfToken) =>
            deleteWithBodyAndCsrf(url, csrfToken, JSON.stringify({ Id: id }))
                .then((data) => {
                    if (data.status !== 204) return

                    const allRegistries = [...registries]
                    const index = registries.findIndex((item) => item.Id === id)
                    if (index > -1) {
                        allRegistries.splice(index, 1)
                        setRegistries(allRegistries)

                        setShowWarning(false)
                        setShowConfirmation(true)
                        setTimeout(() => {
                            setShowConfirmation(false)
                        }, 5000)
                    }
                })
                .catch((err) => {
                    setShowConfirmation(false)
                    if (err.text === "ReigstryInActiveSimulation") {
                        setPostError(REGISTER_TEXTS.ERROR_IS_IN_SALARY_SIMULATION)
                    } else setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                })
        )
    }

    const handleExportPDF = () => {
        const html: any = []
        let string = ""
        setDownloadingPDF(true)
        setPostError("")

        if (allRef.current) {
            const parent: { id: string }[] = [...allRef.current.children]
            parent.forEach((element) => {
                if (element.id !== "excludeFromPDF") html.push(element)
            })
            html.forEach((el: any) => {
                string += el.outerHTML
            })

            prepareCsrfToken().then((csrfToken) =>
                postWithBodyAndCsrf(api.analyzeSalaryExportPdf, csrfToken, JSON.stringify({ Htmltext: string }), true)
                    .then((response) => {
                        setDownloadingPDF(false)
                        const filename = `Registeröversikt_${name}.pdf`

                        response.blob().then((blob: any) => {
                            const url = window.URL.createObjectURL(new Blob([blob]))
                            const link = document.createElement("a")
                            link.setAttribute("href", url)
                            link.setAttribute("download", filename)
                            document.body.appendChild(link)
                            link.click()
                        })
                    })
                    .catch(() => {
                        setDownloadingPDF(false)
                        setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                    })
            )
        }
    }

    useEffect(() => {
        if (registeryOverview && registeryOverview.length)
            setTableData({
                salaryDistribution: prepareT2Values(registeryOverview[0]),
                amountOfIndividuals: prepareT1Values2(registeryOverview[1]),
                bestaCountsWithoutManagers: registeryOverview[2].BestaCountsWithoutManagers,
                bestaCountsManagers: registeryOverview[2].BestaCountsManagers,
                lokalKompletteringCounts: registeryOverview[2].LokalKompletteringCounts,
                consultationCounts: registeryOverview[2].SamrådCounts,
                workRelatedCounts: registeryOverview[2].YrkesSpecCounts,
                categoryCounts: registeryOverview[2].KompetensKategoriCounts,
                bestaExtras: {
                    noClassificationCounts: registeryOverview[2].NoClassificationCount,
                    notSuitableCodeCount: registeryOverview[2].NotSuitableCodeCount,
                    secretaryGeneralCount: registeryOverview[2].SecretaryGeneralCount,
                },
            })
    }, [registeryOverview])

    return (
        <>
            {fetchingRegisterOverview && !fetchError && <ContentSkeleton />}
            {fetchError && <div>{fetchError}</div>}
            {fetchModalDataError && <div>{fetchModalDataError}</div>}
            {!fetchError && !fetchingRegisterOverview && registeryOverview && diagramData && (
                <div ref={allRef}>
                    {isInActiveSalarySimulation && (
                        <div style={{ marginBottom: 24 }}>
                            <strong>{REGISTER_TEXTS.IN_SALARY_SIMULATION}</strong>
                        </div>
                    )}
                    {showWarning && (
                        <div style={{ marginBottom: 24 }}>
                            <DeleteRegisterWarning onClick={handleDeleteRegister} showWarning={setShowWarning}>
                                {`${REGISTER_TEXTS.WARNING_DELETE_1} ${name} ${REGISTER_TEXTS.WARNING_DELETE_2}`}
                            </DeleteRegisterWarning>
                        </div>
                    )}
                    {postError && (
                        <div style={{ marginBottom: 16 }}>
                            <Error>{postError}</Error>
                        </div>
                    )}
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 16, marginBottom: 32 }}>
                        <div>
                            <p style={styles.textStyle}>
                                {REGISTER_TEXTS.TH_AMOUNT_INDIVIDUALS}
                                <strong style={{ paddingRight: 4 }}>{tableData.amountOfIndividuals[0][0]}</strong>
                            </p>
                            <p style={styles.textStyle}>
                                {REGISTER_TEXTS.TH_AMOUNT_INDIVIDUALS_NO_ORG}
                                <strong style={{ paddingRight: 4 }}>{tableData.amountOfIndividuals[0][2]}</strong>
                            </p>
                        </div>
                        <div>
                            <p style={styles.textStyle}>
                                {REGISTER_TEXTS.TH_AMOUNT_INDIVIDUALS_NO_BESTA}
                                <strong style={{ paddingRight: 4 }}>{tableData.amountOfIndividuals[0][1]}</strong>
                            </p>

                            <p style={styles.textStyle}>
                                {REGISTER_TEXTS.TH_DIFF_PREV_REGISTER}
                                <strong style={{ paddingRight: 4 }}>{tableData.amountOfIndividuals[0][3]}</strong>
                            </p>
                        </div>
                    </div>

                    <div id="excludeFromPDF" className="flex wrap gap36 mb24 ai-flex-start">
                        <div className="sliderButtonsContainer">
                            <SliderButton
                                checked={showMinorGridLines}
                                setChecked={setShowMinorGridLines}
                                uniqueIdentifier={id}
                            >
                                {REGISTER_TEXTS.MINOR_GRID}
                            </SliderButton>
                            <SliderButton checked={showMinMax} setChecked={setShowMinMax} uniqueIdentifier={id}>
                                {REGISTER_TEXTS.SHOW_MAX_MIN}
                            </SliderButton>
                        </div>
                        <div style={{ display: "flex", gap: 8 }}>
                            <Button
                                Icon={bookIcon}
                                onClick={() => handleOpenRegisteryModal(id)}
                                isLoading={fetchingEmployees}
                            >
                                {REGISTER_TEXTS.BUTTON_SHOW_REGISTERY}
                            </Button>
                            <Button Icon={editIcon} onClick={renameCustomColumns} isLoading={fetchingCustomColumns}>
                                {REGISTER_TEXTS.BUTTON_RENAME_CC}
                            </Button>
                        </div>
                    </div>
                    <BoxplotDiagram diagramData={diagramData} showMinorGridLines={showMinorGridLines} />
                    <div id="excludeFromPDF" className="flex gap8 mt8 mb36 jc-flex-end">
                        <Button Icon={downloadIcon} onClick={() => downloadCSV(idMapper(api.exportRegisterById, id))}>
                            {REGISTER_TEXTS.A_EXPORT_REGISTERY}
                        </Button>
                        <Button Icon={downloadPDFIcon} onClick={handleExportPDF} isLoading={downloadingPDF}>
                            {REGISTER_TEXTS.A_EXPORT_REGISTERY_PDF}
                        </Button>
                        {!isInActiveSalarySimulation && (
                            <Button
                                Icon={deleteIcon}
                                onClick={() => setShowWarning(true)}
                                variant="delete"
                                disabled={showWarning}
                            >
                                {REGISTER_TEXTS.BUTTON_REMOVE}
                            </Button>
                        )}
                    </div>

                    <EmployeesPercentilesTable data={registeryOverview[0]} showMinMax={showMinMax} />

                    <div style={styles.tablesContainer}>
                        <div style={{ marginRight: 12 }}>
                            <TableBESTA
                                data={tableData.bestaCountsWithoutManagers}
                                bestaExtras={tableData.bestaExtras}
                                caption={REGISTER_TEXTS.T_BESTA_EMPLOYEES_CAPTION}
                            />
                        </div>
                        <TableBESTA
                            data={tableData.bestaCountsManagers}
                            forManagers
                            caption={REGISTER_TEXTS.T_BESTA_MANAGERS_CAPTION}
                        />
                    </div>
                    <div style={styles.tablesContainer}>
                        <div>
                            <div style={{ marginBottom: 24, marginRight: 12 }}>
                                <SimpleAmoutTable
                                    data={tableData.lokalKompletteringCounts}
                                    caption={REGISTER_TEXTS.T_QUAD_LOCAL_CAPTION}
                                />
                            </div>
                            <div style={{ marginBottom: 24 }}>
                                <SimpleAmoutTable
                                    data={tableData.consultationCounts}
                                    caption={REGISTER_TEXTS.T_QUAD_SAM_CAPTION}
                                />
                            </div>
                            <SimpleAmoutTable
                                data={tableData.categoryCounts}
                                caption={REGISTER_TEXTS.T_QUAD_COMPETENCE_CAPTION}
                            />
                        </div>
                        <SimpleAmoutTable
                            data={tableData.workRelatedCounts}
                            caption={REGISTER_TEXTS.T_QUAD_JOB_SEC_CAPTION}
                        />
                    </div>
                </div>
            )}
            {/* This should be two separate modals in ./registeryOverviewModal and ./renameCustomColumnsModal */}
            {showModal && (
                <ReactModal
                    appElement={document.getElementById("root") as HTMLElement}
                    isOpen={showModal}
                    onRequestClose={() => setShowModal(false)}
                    // String indicating how the content container should be announced to screenreaders
                    contentLabel={
                        modalContent === REGISTER_TEXTS.CUSTOM_COLUMNS
                            ? REGISTER_TEXTS.CUSTOM_COLUMNS_CONTENT
                            : REGISTER_TEXTS.SHOW_REGISTERY_CONTENT
                    }
                >
                    {modalContent === REGISTER_TEXTS.SHOW_REGISTERY && tableHeaderRows && employees && (
                        <>
                            <div className="flex ai-center jc-space-between">
                                <h1>{`${REGISTER_TEXTS.MODAL_EMPLOYEES_H1} ${name}`}</h1>
                                <Button Icon={xMark} iconRight onClick={() => setShowModal(false)}>
                                    {GENERAL_TEXTS.BUTTON_CLOSE}
                                </Button>
                            </div>
                            <RegisterTable header={tableHeaderRows} employees={employees} />
                        </>
                    )}
                    {customColumnsData && modalContent === REGISTER_TEXTS.CUSTOM_COLUMNS && (
                        <ChangeCustomColumnsForm id={id} closeModal={() => setShowModal(false)} />
                    )}
                </ReactModal>
            )}
        </>
    )
}

export default RegisterContent
