import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_TIMESERIES } from "constants/text.constants"

import { tTimeseriesScatterDiagramData } from "types/sharedTypes"

import { useState, useEffect, useMemo } from "react"

import prepareCsrfToken from "utils/prepareCsrfToken"
import prepareTimeseriesBoxplotDiagramData from "utils/Highcharts/prepareTimeseriesBoxplotDiagramData"

import { postWithBodyAndCsrf } from "services/apiService"

import Error from "components/atoms/error/Error"
import SliderButton from "components/atoms/sliderButton/SliderButton"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import ScatterDiagram from "./timeseriesScatterDiagram/TimeseriesScatterDiagram"
import TimeseriesBoxplotDiagram from "./timeseriesBoxplotDiagram/TimeseriesBoxplotDiagram"
import TimeseriesPercentilesTable from "./timeseriesPercentilesTable/TimeseriesPercentilesTable"

interface IAnalyseTimeseries {
    diagramType: number
    selectedRegistries: Map<string, string>
    salaryType: number
    selectedGroups: string[]
    mainRegisterName: string
}

const AnalyseTimeseries = ({
    diagramType,
    selectedRegistries,
    salaryType,
    selectedGroups,
    mainRegisterName,
}: IAnalyseTimeseries) => {
    const [fetchError, setFetchError] = useState("")

    const [tableRows, setTableRows] = useState<any[]>([])
    const [fetchingTableRows, setFetchingTableRows] = useState(true)

    const [diagramData, setDiagramData] = useState<any>([])
    const [scatterDiagramData, setScatterDiagramData] = useState<tTimeseriesScatterDiagramData>()
    const [fetchingDiagramData, setFetchingDiagramData] = useState(true)

    const [showMinMax, setShowMinMax] = useState(false)
    const [showMinorGridLines, setShowMinorGridLines] = useState(true)
    const [groupName, setGroupName] = useState("")
    const [showGrouped, setShowGrouped] = useState(false)

    const [selectedGrouping, setSelectedGrouping] = useState("Anställda")
    const diagramGrouping = [
        { Key: 1, Value: "Åldersgrupp" },
        { Key: 2, Value: "Tid" },
        { Key: 0, Value: "Anställda" },
    ]
    const groupingOptions = ["Åldersgrupp", "Tid"]
    const salaryTypes = useMemo(() => [null, "Grundlön", null, null, "Fast lön", "Totallön"], [])

    // Get Table and Diagram data
    useEffect(() => {
        const body = {
            GroupIds: selectedGroups,
            SalaryType: salaryType,
            RegistryIds: Array.from(selectedRegistries.keys()),
            DiagramType: diagramType,
        }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.analyzeTimeseriesDiagramAndTable, csrfToken, JSON.stringify(body))
                .then((response) => {
                    if (diagramType === 1 || response.Diagram.DiagramData === "A") {
                        const chartTitle = ANALYZE_TIMESERIES.S3_DIAGRAM_TITLE
                        const yAxisTitle = salaryTypes[salaryType] || ""
                        const preparedDiagramData = prepareTimeseriesBoxplotDiagramData(
                            response.Diagram.DiagramDataList,
                            response.Diagram.GroupTypes,
                            response.GroupAndRegistryMatch,
                            chartTitle,
                            yAxisTitle
                        )

                        setDiagramData(preparedDiagramData)
                    }
                    if (diagramType === 2) {
                        setGroupName(response.Tables[0].Name)
                        setDiagramData(JSON.parse(response.Diagram.DiagramData))
                        setScatterDiagramData(JSON.parse(response.Diagram.DiagramData)[0])
                    }
                    setTableRows(response.Tables)
                    setFetchingTableRows(false)
                    setFetchingDiagramData(false)
                    setFetchError("")
                })
                .catch(() => {
                    setFetchingTableRows(false)
                    setFetchingDiagramData(false)
                    setFetchError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                })
        )
    }, [diagramType, salaryType, salaryTypes, selectedGroups, selectedRegistries])

    useEffect(() => {
        if (showGrouped) {
            setSelectedGrouping("Åldersgrupp")
            setScatterDiagramData(diagramData[1])
        } else {
            setSelectedGrouping("Anställda")
            setScatterDiagramData(diagramData[0])
        }
    }, [diagramData, showGrouped])

    useEffect(() => {
        const index = diagramGrouping.findIndex((el) => el.Value === selectedGrouping)

        setSelectedGrouping(diagramGrouping[index].Value)
        setScatterDiagramData(diagramData[diagramGrouping[index].Key])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagramData, selectedGrouping])

    return (
        <>
            {fetchingDiagramData && fetchingTableRows && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingDiagramData && !fetchingTableRows && !fetchError && (
                <div>
                    <h2>{ANALYZE_TIMESERIES.S3_H2}</h2>
                    <p className="flex wrap margin0">
                        <strong>{ANALYZE_TIMESERIES.S3_CHOSEN_REGISTRIES}</strong>
                        {Array.from(selectedRegistries.values()).join(", ")}
                    </p>
                    <div className="flex wrap gap16">
                        {diagramType === 2 && groupName && (
                            <p className="flex wrap">
                                <strong>{ANALYZE_TIMESERIES.S3_DIAGRAM_GROUP}</strong>
                                {groupName}
                            </p>
                        )}
                        <p className="flex wrap">
                            <strong>{ANALYZE_TIMESERIES.S3_DIAGRAM_SALARY_TYPE}</strong>
                            {salaryTypes[salaryType]}
                        </p>
                        <p className="flex wrap">
                            <strong>{ANALYZE_TIMESERIES.S3_DIAGRAM_MAIN_REGISTER}</strong>
                            {mainRegisterName}
                        </p>
                    </div>
                    <div className="flex wrap gap36 mb16 ai-flex-start">
                        <div className="flex gap20 ai-flex-start">
                            <div className="sliderButtonsContainer">
                                <SliderButton checked={showMinorGridLines} setChecked={setShowMinorGridLines}>
                                    {ANALYZE_TIMESERIES.S3_SLIDER_MINOR_GRID}
                                </SliderButton>
                                {diagramType === 1 && (
                                    <SliderButton checked={showMinMax} setChecked={setShowMinMax}>
                                        {ANALYZE_TIMESERIES.S3_SLIDER_MIN_MAX}
                                    </SliderButton>
                                )}
                                {diagramType === 2 && (
                                    <SliderButton checked={showGrouped} setChecked={setShowGrouped}>
                                        {ANALYZE_TIMESERIES.S3_SLIDER_SHOW_GROUPED}
                                    </SliderButton>
                                )}
                            </div>
                            {diagramType === 2 && showGrouped && (
                                <select
                                    onChange={(e) => setSelectedGrouping(e.target.value)}
                                    name="select"
                                    id="select"
                                    className="registryDropdown selectMemberSelect"
                                >
                                    {groupingOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    </div>
                    {diagramType === 1 && !fetchingDiagramData && !!diagramData.Data.length && (
                        <TimeseriesBoxplotDiagram diagramData={diagramData} showMinorGridLines={showMinorGridLines} />
                    )}
                    {diagramType === 2 && !fetchingDiagramData && !!diagramData.length && scatterDiagramData && (
                        <ScatterDiagram
                            diagramData={scatterDiagramData}
                            diagramTitle={ANALYZE_TIMESERIES.S3_DIAGRAM_TITLE}
                            showMinorGridLines={showMinorGridLines}
                            diagramGrouping={diagramGrouping}
                            selectedGrouping={selectedGrouping}
                        />
                    )}
                    {tableRows && (
                        <TimeseriesPercentilesTable groupPercentileData={tableRows} showMinMax={showMinMax} />
                    )}
                </div>
            )}
        </>
    )
}

export default AnalyseTimeseries
