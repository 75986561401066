import "./AddRegistriesModal.css"

import { useState } from "react"
import ReactModal from "react-modal"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_SALARY_EVOLUTION } from "constants/text.constants"

import { IRegister, tSalaryEvolutionSelectionGroup } from "types/sharedTypes"

import prepareCsrfToken from "utils/prepareCsrfToken"
import { postWithBodyAndCsrf } from "services/apiService"

import { ReactComponent as xMark } from "assets/xmark.icon.svg"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import MultiselectDropdown from "components/cores/multiselectDropdown/MultiselectDropdown"

type AddRegistriesModalProps = {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    selectedRegistries: Map<string, string>
    setSelectedRegistries: React.Dispatch<React.SetStateAction<Map<string, string>>>
    registries: IRegister[]
    mainRegister: string
    matchingId: string
    setPreselectedGroups: React.Dispatch<React.SetStateAction<tSalaryEvolutionSelectionGroup[]>>
    setActiveStep: React.Dispatch<React.SetStateAction<number>>
    name: string
    created: string
}

const AddRegistriesModal = ({
    showModal,
    setShowModal,
    selectedRegistries,
    setSelectedRegistries,
    registries,
    mainRegister,
    matchingId,
    setPreselectedGroups,
    setActiveStep,
    name,
    created,
}: AddRegistriesModalProps) => {
    const [fetchError, setFetchError] = useState("")
    const [fetchingMatching, setFetchingMatching] = useState(false)

    const tooManySelected = selectedRegistries.size > 9

    const handleMatching = () => {
        const mainRegisterId = registries[registries.findIndex((register) => register.Name === mainRegister)].Id
        selectedRegistries.set(mainRegisterId, mainRegister) // array mutation? bäää

        const body = {
            MatchingId: matchingId,
            RegistryIds: Array.from(selectedRegistries.keys()),
        }

        type tMatchingGroup = {
            Count: number
            GroupIds: string[]
            GroupType: string
            MainRegistry: string
            Name: string
        }
        interface IGetMatching {
            AllGroups: tMatchingGroup[]
            SelectedGroups: { Key: string; Value: string }[]
        }
        setFetchingMatching(true)
        setFetchError("")
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.analyzeSalaryEvolutionGetMatching, csrfToken, JSON.stringify(body))
                .then((matchingGroup: IGetMatching) => {
                    setPreselectedGroups(matchingGroup.SelectedGroups)
                    setFetchingMatching(false)
                    setShowModal(false)
                    setActiveStep(3)
                })
                .catch((err) => {
                    err.text().then((text: string) => {
                        if (text === "Sequence contains no matching element") {
                            setFetchError(ANALYZE_SALARY_EVOLUTION.ADD_REGISTRIES_MODAL_ERROR_SEQUENCE)
                        } else setFetchError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                    })
                    setFetchingMatching(false)
                })
        )
    }

    return (
        <ReactModal
            appElement={document.getElementById("root") as HTMLElement}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel={ANALYZE_SALARY_EVOLUTION.ADD_REGISTRIES_MODAL_CONTENT}
        >
            <div className="flex jc-space-between ai-center">
                <h1>{ANALYZE_SALARY_EVOLUTION.ADD_REGISTRIES_MODAL_HEADING}</h1>
                <Button Icon={xMark} iconRight onClick={() => setShowModal(false)}>
                    {GENERAL_TEXTS.BUTTON_CLOSE}
                </Button>
            </div>
            <p className="mb32 textMaxWidth800">{ANALYZE_SALARY_EVOLUTION.ADD_REGISTRIES_MODAL_EXPLANATION}</p>
            <p className="flex wrap">
                <strong>{ANALYZE_SALARY_EVOLUTION.ADD_REGISTRIES_MODAL_LABEL_MATCHING}</strong>
                {name}
            </p>
            <p className="flex wrap mb32">
                <strong>{ANALYZE_SALARY_EVOLUTION.ADD_REGISTRIES_MODAL_LABEL_CREATED}</strong>
                {created}
            </p>
            {fetchError && (
                <div className="mb16 textMaxWidth800">
                    <Error>{fetchError}</Error>
                </div>
            )}
            <table className="addRegistriesTableRegistries">
                <thead className="addRegistriesRegistriesTHead">
                    <tr>
                        <th className="addRegistriesRegistriesTH">
                            {ANALYZE_SALARY_EVOLUTION.ADD_REGISTRIES_MODAL_REGISTERS_TH1}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="addRegistriesRegistriesTR" key={`${mainRegister}${Math.random() * 1600}`}>
                        <td className="addRegistriesRegistriesTD">{mainRegister}</td>
                    </tr>
                </tbody>
            </table>
            <div className="mb32">
                <MultiselectDropdown
                    selectedOptions={selectedRegistries}
                    setSelectedOptions={setSelectedRegistries}
                    options={registries}
                    label={ANALYZE_SALARY_EVOLUTION.ADD_REGISTRIES_MODAL_LABEL_MULTISELECT}
                />
                {tooManySelected && <p>{ANALYZE_SALARY_EVOLUTION.ADD_REGISTRIES_MODAL_TOO_MANY_SELECTED}</p>}
            </div>
            <Button
                disabled={tooManySelected || selectedRegistries.size === 0}
                isLoading={fetchingMatching}
                onClick={handleMatching}
            >
                {GENERAL_TEXTS.BUTTON_FORWARD}
            </Button>
        </ReactModal>
    )
}

export default AddRegistriesModal
