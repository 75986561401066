import * as yup from "yup"

const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const createAccountValidationSchema = yup.object({
    name: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().matches(emailRegex).required(),
    mobile: yup
        .string()
        .min(10)
        .max(10)
        .matches(/^[0-9]+$/)
        .required(),
})

export const forgotPasswordValidationSchema = yup.object({
    email: yup.string().email().matches(emailRegex).required(),
})
