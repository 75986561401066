import "./UserAdministrationPage.css"

import { useEffect, useState } from "react"

import api from "constants/endpoints.constants"
import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import GENERAL_TEXTS, { USER_ADMINISTRATION_TEXTS } from "constants/text.constants"

import { tUser, tUserRoles } from "types/sharedTypes"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"

import { fetchAllRoles, fetchUsers } from "services/fetchers"
import { deleteWithBodyAndCsrf } from "services/apiService"

import { ReactComponent as CirclePlusIcon } from "assets/circle-plus-solid.icon.svg"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Warning from "components/atoms/warning/Warning"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import UserCard, { emptyUser } from "./userCard/UserCard"

const UserAdministrationPage = () => {
    const [users, setUsers] = useState<tUser[]>([])
    const [allRoles, setAllRoles] = useState<tUserRoles[]>([])
    const [fetchingUsers, setFetchingUsers] = useState(true)
    const [fetchingAllRoles, setFetchingAllRoles] = useState(true)

    const [fetchError, setFetchError] = useState("")
    const [postError, setPostError] = useState("")

    const [createUser, setCreateUser] = useState(false)

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showCreateConfirmation, setShowCreateConfirmation] = useState(false)
    const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [userToDelete, setUserToDelete] = useState({ Id: "", Name: "" })

    const noUsers = !users.length

    const handleDeleteUser = () => {
        setPostError("")
        if (userToDelete.Id === "") return

        prepareCsrfToken().then((csrfToken) =>
            deleteWithBodyAndCsrf(
                idMapper(api.deleteUserById, userToDelete.Id),
                csrfToken,
                JSON.stringify({ Id: userToDelete.Id })
            )
                .then(() => {
                    setShowWarning(false)
                    setShowDeleteConfirmation(true)
                    setTimeout(() => {
                        setShowDeleteConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setShowDeleteConfirmation(false)
                    if (err.text) {
                        let parsedError = ""
                        err.text().then((text: string) => {
                            parsedError = text
                            setPostError(`${USER_ADMINISTRATION_TEXTS.ERROR_DELETING_USER} ${parsedError}`)
                        })
                    } else setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                })
        )
    }

    useEffect(
        () => fetchUsers(setUsers, setFetchingUsers, setFetchError),
        [showDeleteConfirmation, showCreateConfirmation, showUpdateConfirmation]
    )

    useEffect(() => fetchAllRoles(setAllRoles, setFetchingAllRoles, setFetchError), [])

    useUpdatePageTitle(pageTitles.USER_ADMINISTRATION_PAGE_TITLE)
    useScrollToTop()

    return (
        <div className="maxWidth1000">
            <PageHeader pageId={pageIds.USER_ADMINISTRATION_PAGE_ID} />
            {fetchingUsers && fetchingAllRoles && !fetchError && <ContentSkeleton />}
            {noUsers && !fetchingUsers && <p>{USER_ADMINISTRATION_TEXTS.NO_USERS}</p>}
            {fetchError && <Error>{fetchError}</Error>}
            {postError && (
                <div className="mb16">
                    <Error>{postError}</Error>
                </div>
            )}
            {showDeleteConfirmation && (
                <div className="mb32">
                    <Confirmation>{USER_ADMINISTRATION_TEXTS.CONFIRMATION_DELETED}</Confirmation>
                </div>
            )}
            {showUpdateConfirmation && (
                <div className="mb32">
                    <Confirmation>{USER_ADMINISTRATION_TEXTS.CONFIRMATION_UPDATED}</Confirmation>
                </div>
            )}
            {showCreateConfirmation && (
                <div className="mb32">
                    <Confirmation>{USER_ADMINISTRATION_TEXTS.CONFIRMATION_CREATED}</Confirmation>
                </div>
            )}
            {!fetchingUsers && !fetchingAllRoles && (
                <div className="flex jc-flex-end mb32">
                    <Button onClick={() => setCreateUser(true)} Icon={CirclePlusIcon}>
                        {USER_ADMINISTRATION_TEXTS.BUTTON_CREATE_USER}
                    </Button>
                </div>
            )}
            {!fetchingUsers && !fetchingAllRoles && createUser && (
                <UserCard
                    user={emptyUser}
                    allRoles={allRoles}
                    setCreating={setCreateUser}
                    setShowConfirmation={setShowCreateConfirmation}
                    setPostError={setPostError}
                />
            )}
            {showWarning && (
                <div className="mb16">
                    <Warning onClick={handleDeleteUser} showWarning={setShowWarning} variant="delete" onTop>
                        {`${USER_ADMINISTRATION_TEXTS.WARNING_REMOVE} ${userToDelete.Name}`}
                    </Warning>
                </div>
            )}
            {!!users.length && !fetchingUsers && !fetchingAllRoles && (
                <>
                    <div className="stickyHeader">
                        {/* <strong className="hItem">{USER_ADMINISTRATION_TEXTS.FIRSTNAME}</strong> */}
                        {/* <strong className="hItem">{USER_ADMINISTRATION_TEXTS.LASTNAME}</strong> */}
                        <strong className="hItem mw200 pl4">{USER_ADMINISTRATION_TEXTS.NAME}</strong>
                        <strong className="hItem mw210">{USER_ADMINISTRATION_TEXTS.EMAIL}</strong>
                        <strong className="hItem">{USER_ADMINISTRATION_TEXTS.MOBILE}</strong>
                        <strong className="hItem mw165">{USER_ADMINISTRATION_TEXTS.ROLE}</strong>
                        <strong className="hItem">{USER_ADMINISTRATION_TEXTS.ADMINISTER}</strong>
                    </div>
                    <ul className="ulUserAdmin">
                        {users.map((user) => (
                            <li key={user.Id} className="userListItem">
                                <UserCard
                                    user={user}
                                    allRoles={allRoles}
                                    setShowConfirmation={setShowUpdateConfirmation}
                                    setPostError={setPostError}
                                    setShowWarning={setShowWarning}
                                    setUserToDelete={setUserToDelete}
                                />
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    )
}

export default UserAdministrationPage
