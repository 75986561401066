import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

import "./index.css"
import App from "./App"

import "./fonts/OpenSans/OpenSans-Bold.ttf"
import "./fonts/OpenSans/OpenSans-BoldItalic.ttf"
import "./fonts/OpenSans/OpenSans-ExtraBold.ttf"
import "./fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf"
import "./fonts/OpenSans/OpenSans-Italic.ttf"
import "./fonts/OpenSans/OpenSans-LightItalic.ttf"
import "./fonts/OpenSans/OpenSans-Medium.ttf"
import "./fonts/OpenSans/OpenSans-MediumItalic.ttf"
import "./fonts/OpenSans/OpenSans-Regular.ttf"
import "./fonts/OpenSans/OpenSans-SemiBold.ttf"
import "./fonts/OpenSans/OpenSans-SemiBoldItalic.ttf"

// import worker from "./mocks/browser.js"

// This is a local mock of data
// if (process.env.NODE_ENV === "development") {
//     worker.start()
// }

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
)
