import { Routes, Route } from "react-router-dom"
import CreateAccountPage from "components/main/pages/account/createAccount/CreateAccountPage"
import AdministerAccountPage from "components/main/pages/account/adminsterAccount/AdministerAccountPage"
import DeleteAccountPage from "components/main/pages/account/deleteAccount/DeleteAccountPage"
// import OrgAdministrationPage from "components/main/pages/administration/orgAdmin/OrgAdministrationPage"
import UserAdministrationPage from "components/main/pages/administration/userAdmin/UserAdministrationPage"
// import AnalyzeNewSalariesPage from "components/main/pages/analyze/analyzeNewSalaries/AnalyzeNewSalariesPage"
import AnalyzeReferencestatisticsPage from "components/main/pages/analyze/analyzeReferenceStatistics/AnalyzeReferencestatisticsPage"
import AnalyzeSalaryDifferencePage from "components/main/pages/analyze/analyzeSalaryDifference/AnalyzeSalaryDifferencePage"
import AnalyzeSalaryDistributionPage from "components/main/pages/analyze/analyzeSalaryDistribution/AnalyzeSalaryDistributionPage"
import AnalyzeSalaryEvolutionPage from "components/main/pages/analyze/analyzeSalaryEvolution/AnalyzeSalaryEvolutionPage"
import AnalyzeTimeseriesPage from "components/main/pages/analyze/analyzeTimeSeries/AnalyzeTimeseriesPage"
import CreateGroupManuallyPage from "components/main/pages/groups/createGroupManually/CreateGroupManuallyPage"
import CreateGroupsUsingFilterPage from "components/main/pages/groups/createGroupUsingFilter/CreateGroupsUsingFilterPage"
import ShowGroupsPage from "components/main/pages/groups/showGroup/ShowGroupsPage"
import ConceptsAndDefinitionsPage from "components/main/pages/help/conceptsAndDefinition/ConceptsAndDefinitionsPage"
import ContactPage from "components/main/pages/help/contact/ContactPage"
import FaqPage from "components/main/pages/help/faq/FaqPage"
import ManualPage from "components/main/pages/help/manual/ManualPage"
import ImportReferenceStatisticPage from "components/main/pages/referencestatistics/importReferenceStatistic/ImportReferenceStatisticPage"
import ShowReferenceStatisticsPage from "components/main/pages/referencestatistics/showReferenceStatistics/ShowReferenceStatisticsPage"
import ImportRegisterPage from "components/main/pages/register/importRegister/ImportRegisterPage"
import ShowRegisterPage from "components/main/pages/register/showRegister/ShowRegisterPage"
import HomePage from "components/main/pages/home/HomePage"
import ForgotPasswordPage from "components/main/pages/forgotPassword/ForgotPasswordPage"

// we prolly should abstract strings into routerPaths.constants.json
// import { routerPaths } from x
// routerPaths.home, routerPaths.register, routerPaths.register.view

const MainContent = () => (
    <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/passwordReset" element={<ForgotPasswordPage />} />
        <Route path="/account">
            <Route path="create" element={<CreateAccountPage />} />
            <Route path="delete" element={<DeleteAccountPage />} />
            <Route path="administer" element={<AdministerAccountPage />} />
        </Route>
        <Route path="/register">
            <Route path="import" element={<ImportRegisterPage />} />
            <Route path="show" element={<ShowRegisterPage />} />
        </Route>
        <Route path="/referencestatistics">
            <Route path="import" element={<ImportReferenceStatisticPage />} />
            <Route path="show" element={<ShowReferenceStatisticsPage />} />
        </Route>
        <Route path="/groups">
            <Route path="show" element={<ShowGroupsPage />} />
            <Route path="createusingfilters" element={<CreateGroupsUsingFilterPage />} />
            <Route path="createmanually" element={<CreateGroupManuallyPage />} />
            <Route path="createmanually/edit" element={<CreateGroupManuallyPage />} />
        </Route>
        <Route path="/analyze">
            <Route path="salarydistribution" element={<AnalyzeSalaryDistributionPage />} />
            <Route path="salarydifference" element={<AnalyzeSalaryDifferencePage />} />
            <Route path="referencestatistics" element={<AnalyzeReferencestatisticsPage />} />
            {/* <Route path="newsalaries" element={<AnalyzeNewSalariesPage />} /> */}
            <Route path="timeseries" element={<AnalyzeTimeseriesPage />} />
            <Route path="salaryevolution" element={<AnalyzeSalaryEvolutionPage />} />
        </Route>
        <Route path="/administrate">
            <Route path="users" element={<UserAdministrationPage />} />
            {/* <Route path="organisation" element={<OrgAdministrationPage />} /> */}
        </Route>
        <Route path="/help">
            <Route path="manual" element={<ManualPage />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="concepts" element={<ConceptsAndDefinitionsPage />} />
            <Route path="contact" element={<ContactPage />} />
        </Route>
    </Routes>
)

export default MainContent
