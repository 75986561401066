import "./ReferencestatisticsRegistriesAndSelections.css"

import { useContext, useEffect, useState } from "react"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_REFERENCESTATISTICS, REGISTER_TEXTS } from "constants/text.constants"

import {
    dispatchNumber,
    dispatchString,
    IcomparisonstatisticAnalysis,
    tComparisonstatisticsAnalysisObject,
    tSavedComparisonAnalysisObject,
} from "types/sharedTypes"

import { deleteWithBodyAndCsrf } from "services/apiService"
import {
    fetchCurrentSalarySimulation,
    fetchReferencestatisticSavedSelections,
    fetchRegistries,
    fetchSavedComparisonAnalysis,
} from "services/fetchers"

import { RegistriesContext } from "context/RegistriesContext"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Warning from "components/atoms/warning/Warning"
import Confirmation from "components/atoms/confirmation/Confirmation"
import SelectRegistry from "components/cores/selectRegistry/SelectRegistry"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

interface IReferencestatisticsRegistriesAndSelections {
    selectedRegistryId: string
    setSelectedRegistryId: dispatchString
    setActiveStep: dispatchNumber
    setAnalysisObject: React.Dispatch<React.SetStateAction<tComparisonstatisticsAnalysisObject>>
    setSelectedDiagramType: dispatchNumber
    setPreselectedGroups: React.Dispatch<React.SetStateAction<string[]>>
    setSelectedReferenceRegisterId: dispatchString
}

const ReferencestatisticsRegistriesAndSelections = ({
    selectedRegistryId,
    setSelectedRegistryId,
    setActiveStep,
    setAnalysisObject,
    setSelectedDiagramType,
    setPreselectedGroups,
    setSelectedReferenceRegisterId,
}: IReferencestatisticsRegistriesAndSelections) => {
    const { registries, setRegistries, registriesFetched, setRegistriesFetched } = useContext(RegistriesContext)
    const [noRegisters, setNoRegisters] = useState(!registries.length && registriesFetched)

    const [fetchError, setFetchError] = useState("")

    const [fetchingRegistries, setFetchingRegistries] = useState(!registries.length)
    const [savedSelection, setSavedSelection] = useState<IcomparisonstatisticAnalysis[]>([])
    const [fetchingSavedSelections, setFetchingSavedSelections] = useState(true)

    const [salarySimulation, setSalarySimulation] = useState({
        Id: "",
        RegistryId: "",
        RegistryName: "",
        IsActive: false,
        IsReadyForSimulation: false,
        IsReadyForCompletion: false,
        UserCanCompleteSimulation: false,
        RootDeapartmentForecastId: "",
    })
    const [fetchingSalarySimulation, setFetchingSalarySimulation] = useState(true)

    const [currentSelectionObj, setCurrentSelection] = useState({ Name: "", Id: "" })

    const [savedAnalysis, setSavedAnalysis] = useState<tSavedComparisonAnalysisObject>()
    const [fetchingSavedAnalysis, setFetchingSavedAnalysis] = useState(false)

    const [showWarning, setShowWarning] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const handleDeleteSelection = (id: string, name: string) => {
        setPostError("")
        setCurrentSelection({ Name: name, Id: id })
        setShowWarning(true)
    }

    const deleteSelection = (Id: string) => {
        prepareCsrfToken().then((csrfToken) =>
            deleteWithBodyAndCsrf(
                idMapper(api.analyzeDeleteComparisonStatisticsById, Id),
                csrfToken,
                JSON.stringify({})
            )
                .then(() => {
                    setSavedSelection((prevState) => {
                        const index = prevState.findIndex((item) => item.Id === Id)
                        if (index > -1) {
                            prevState.splice(index, 1)
                        }
                        return prevState
                    })
                    setPostError("")
                    setShowWarning(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setShowWarning(false)
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const handleChooseSelection = (id: string, name: string) => {
        setFetchingSavedAnalysis(true)

        fetchSavedComparisonAnalysis(
            id,
            setSavedAnalysis,
            setAnalysisObject,
            setSelectedDiagramType,
            setPreselectedGroups,
            setSelectedReferenceRegisterId,
            setFetchingSavedAnalysis,
            setFetchError
        )

        setAnalysisObject((state) => ({
            ...state,
            selectionName: name,
        }))
        setActiveStep(3)
    }

    useEffect(() => {
        if (!registries.length && !registriesFetched)
            fetchRegistries(
                setRegistries,
                setNoRegisters,
                setSelectedRegistryId,
                setFetchingRegistries,
                setRegistriesFetched,
                setFetchError
            )
    }, [registries, setRegistries, registriesFetched, setRegistriesFetched, setSelectedRegistryId])

    useEffect(() => fetchCurrentSalarySimulation(setSalarySimulation, setFetchingSalarySimulation, setFetchError), [])

    useEffect(() => {
        if (!noRegisters && selectedRegistryId)
            fetchReferencestatisticSavedSelections(
                selectedRegistryId,
                setSavedSelection,
                setFetchingSavedSelections,
                setFetchError
            )
        if (noRegisters && selectedRegistryId) setFetchingSavedSelections(false)
    }, [selectedRegistryId, noRegisters])

    return (
        <div>
            {(fetchingSavedSelections || fetchingRegistries || fetchingSalarySimulation || fetchingSavedAnalysis) &&
                !fetchError &&
                !noRegisters && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {noRegisters && <p>{REGISTER_TEXTS.NO_REGISTERS}</p>}
            {registries && !noRegisters && registriesFetched && (
                <div>
                    <div className="mb32">
                        <div className="mb48">
                            <h2>{ANALYZE_REFERENCESTATISTICS.S1_H2}</h2>
                        </div>
                        {!!salarySimulation.RegistryName && (
                            <p className="flex wrap">
                                <strong>{ANALYZE_REFERENCESTATISTICS.S1_CURRENT_SALARY_SIMULATION_REGISTER}</strong>
                                {salarySimulation.RegistryName}
                            </p>
                        )}
                        {salarySimulation.RegistryName === null && !fetchingSalarySimulation && (
                            <p className="flex wrap">
                                <strong>{ANALYZE_REFERENCESTATISTICS.S1_CURRENT_SALARY_SIMULATION_REGISTER}</strong>
                                {ANALYZE_REFERENCESTATISTICS.S1_NO_REGISER_SALARY_SIMULATION}
                            </p>
                        )}
                        <SelectRegistry
                            registries={registries}
                            setRegistryId={setSelectedRegistryId}
                            defaultValue={selectedRegistryId}
                        />
                    </div>
                    {showWarning && (
                        <Warning
                            onClick={() => deleteSelection(currentSelectionObj.Id)}
                            showWarning={setShowWarning}
                            variant="delete"
                        >
                            {`${ANALYZE_REFERENCESTATISTICS.S1_WARNING_DELETE_SELECTION} ${currentSelectionObj.Name}`}
                        </Warning>
                    )}
                    {showConfirmation && (
                        <Confirmation>{ANALYZE_REFERENCESTATISTICS.S1_CONFIRMATION_DELETED_SELECTION}</Confirmation>
                    )}
                    {postError && <Error>{postError}</Error>}
                    {!!savedSelection.length && !fetchingSavedSelections && (
                        <div>
                            <h3>{ANALYZE_REFERENCESTATISTICS.S1_H3}</h3>
                            <table className="refstatTable">
                                <thead>
                                    <tr>
                                        <th className="th-bb-1">{ANALYZE_REFERENCESTATISTICS.S1_TH1}</th>
                                        <th className="th-bb-1">{ANALYZE_REFERENCESTATISTICS.S1_TH2}</th>
                                        <th className="th-bb-1 refstatCellWidth160">
                                            {ANALYZE_REFERENCESTATISTICS.S1_TH3}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {savedSelection.map((selection) => (
                                        <tr key={selection.Id}>
                                            <td>{selection.Name}</td>
                                            <td>{selection.Created}</td>
                                            <td>
                                                <div className="flex gap8">
                                                    <Button
                                                        small
                                                        onClick={() =>
                                                            handleChooseSelection(selection.Id, selection.Name)
                                                        }
                                                    >
                                                        {ANALYZE_REFERENCESTATISTICS.S1_BUTTON_CHOOSE}
                                                    </Button>
                                                    <Button
                                                        variant="delete"
                                                        small
                                                        onClick={() =>
                                                            handleDeleteSelection(selection.Id, selection.Name)
                                                        }
                                                    >
                                                        {ANALYZE_REFERENCESTATISTICS.S1_BUTTON_DELETE}
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default ReferencestatisticsRegistriesAndSelections
