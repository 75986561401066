import api from "constants/endpoints.constants"
import { AuthHandler } from "services/authHandler"

// Returns null either when the cookie doesn't exist, or when it doesn't contain the requested name.
// Otherwise, the value (of the requested name) is returned.
function getCookie(name: string) {
    const match = document.cookie.match(RegExp(`(?:^|;\\s*)${name}=([^;]*)`))
    return match ? match[1] : null
}

function prepareCsrfToken(): Promise<string> {
    return new Promise((resolve) => {
        // If no cookie called csrfToken present = token is no longer valid and should be refetched
        const tokenExpired = getCookie("csrfToken") === null
        // const noToken = !sessionStorage.getItem("csrfToken")

        // if (tokenExpired || noToken) {
        if (tokenExpired) {
            AuthHandler.ins()
                .request(
                    () =>
                        fetch(api.getCSRFtoken, {
                            credentials: "include",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                            },
                        }),
                    api.getCSRFtoken
                )
                .then((response) => {
                    if (response.ok) return response.text()
                    return Promise.reject(response)
                })
                .then((token) => {
                    // sessionStorage.setItem("csrfToken", token)

                    // expiration time = 1 hour
                    const expirationTime = new Date(Date.now() + 1 * 3600 * 1000).toUTCString()
                    // const cookieValue = true
                    // document.cookie = `validToken=${cookieValue};`
                    document.cookie = `csrfToken=${token}; expires=${expirationTime};`
                    // document.cookie = `expires=${expirationTime};`

                    // return sessionStorage.getItem("csrfToken")
                    resolve(token)
                })
            // } else resolve(sessionStorage.getItem("csrfToken") as string)
        } else resolve(getCookie("csrfToken") as string)
    })
}

export default prepareCsrfToken
