import "./MenuPanel.css"

import { useLocation } from "react-router-dom"

import LoginPage from "./login/LoginPage"
import Navigation from "./nav/Navigation"
import MenuFooter from "./nav/menuFooter/MenuFooter"

type MenuPanelProps = {
    loggedIn: boolean
    setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
}

const MenuPanel = ({ loggedIn = false, setLoggedIn }: MenuPanelProps) => {
    const { pathname } = useLocation()
    const forgotPassword = pathname === "/passwordReset"
    const forgotPasswordStyling = forgotPassword ? "forgotPassword" : ""

    return (
        <div className={`menuPanel ${forgotPasswordStyling}`}>
            {!loggedIn && !forgotPassword && <LoginPage setLoggedIn={setLoggedIn} />}
            {loggedIn && <Navigation />}
            {loggedIn && <MenuFooter />}
            {forgotPassword && <MenuFooter />}
        </div>
    )
}

export default MenuPanel
