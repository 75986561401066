import "./ReferenceStatisticsContent.css"

import { AgGridReact } from "ag-grid-react"
import { useContext, useMemo, useState } from "react"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { REFERENCESTATISTICS_TEXTS } from "constants/text.constants"

import { dispatchBool, Icomparisonstatistic, ITableView, ITableViewRow } from "types/sharedTypes"

import idMapper from "utils/idMapper"
import downloadCSV from "utils/downloadCSV"
import prepareCsrfToken from "utils/prepareCsrfToken"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

import RolesContext from "context/RolesContext"
import { deleteWithBodyAndCsrf } from "services/apiService"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Warning from "components/atoms/warning/Warning"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import { ReactComponent as downloadIcon } from "assets/file-arrow-down.icon.svg"
import { ReactComponent as deleteIcon } from "assets/trash-solid.icon.svg"

type ReferenceStatisticsContentProps = {
    tableView: ITableView[] | undefined
    tableViewRows: ITableViewRow[] | undefined
    importedBy: string
    fetchingTableView: boolean
    fetchError: string
    setComparisonstatistics: React.Dispatch<React.SetStateAction<Icomparisonstatistic[] | undefined>>
    setShowConfirmation: dispatchBool
}

const ReferenceStatisticsContent = ({
    tableView,
    tableViewRows,
    importedBy,
    fetchingTableView,
    fetchError,
    setComparisonstatistics,
    setShowConfirmation,
}: ReferenceStatisticsContentProps) => {
    const { agvAdmin, roles } = useContext(RolesContext)

    const [postError, setPostError] = useState("")
    const [showWarning, setShowWarning] = useState(false)

    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    const [columnDefs] = useState([
        { field: REFERENCESTATISTICS_TEXTS.TH_BESTA, width: 120, sortable: true, filter: true, resizable: true },
        {
            field: REFERENCESTATISTICS_TEXTS.TH_AGEGROUP,
            width: 150,
            flex: 1,
            sortable: true,
            filter: true,
            resizable: true,
        },
        { field: REFERENCESTATISTICS_TEXTS.TH_AMOUNT, width: 120, sortable: true, filter: true, resizable: true },
        { field: REFERENCESTATISTICS_TEXTS.TH_P10, width: 100, sortable: true, filter: true, resizable: true },
        { field: REFERENCESTATISTICS_TEXTS.TH_P25, width: 100, sortable: true, filter: true, resizable: true },
        { field: REFERENCESTATISTICS_TEXTS.TH_P50, width: 100, sortable: true, filter: true, resizable: true },
        { field: REFERENCESTATISTICS_TEXTS.TH_P75, width: 100, sortable: true, filter: true, resizable: true },
        { field: REFERENCESTATISTICS_TEXTS.TH_P90, width: 100, sortable: true, filter: true, resizable: true },
        { field: REFERENCESTATISTICS_TEXTS.TH_REGION, width: 100, sortable: true, filter: true, resizable: true }, // dessa ska inte synas om employeecategory typ 0
        { field: REFERENCESTATISTICS_TEXTS.TH_VI, width: 120, sortable: true, filter: true, resizable: true }, // dessa ska inte synas om employeecategory typ 0
    ])

    const showLastTwoColumns = tableView && tableView[0].StatisticType.Value === "Partsgemensam referensstatistik"

    // checks if the chosen statistic type is partgemensam or not and displays region and vi depending on that
    const modifiedColumnDefs = columnDefs.map((col) => {
        if (col.field === REFERENCESTATISTICS_TEXTS.TH_REGION || col.field === REFERENCESTATISTICS_TEXTS.TH_VI) {
            return { ...col, hide: !showLastTwoColumns }
        }
        return col
    })

    const handleDeleteComparisonstatistics = (id: string) => {
        const nationalStatisticType = importedBy === REFERENCESTATISTICS_TEXTS.AGV && agvAdmin
        const url = nationalStatisticType
            ? idMapper(api.deleteNationalReferencestatisticsById, id)
            : idMapper(api.deleteCustomReferencestatisticsById, id)

        prepareCsrfToken().then((csrfToken) =>
            deleteWithBodyAndCsrf(url, csrfToken, JSON.stringify({ id }))
                .then(() => {
                    setComparisonstatistics((prevState) => {
                        if (!prevState) return prevState

                        const index = prevState.findIndex((item) => item.Id === id)
                        if (index > -1) {
                            prevState.splice(index, 1) // To be honest .splice() works not as I understand it -_-
                            return prevState
                        }
                        return prevState
                    })

                    setShowWarning(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setShowConfirmation(false)
                    if (err) setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                })
        )
    }
    const HR = roles.importcomparissonfile
    const importedByAgv = importedBy === REFERENCESTATISTICS_TEXTS.AGV

    return (
        <>
            {fetchingTableView && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingTableView && !fetchError && tableView && tableViewRows && (
                <div>
                    <h2>{tableView[0].StatisticType.Value}</h2>
                    <div className="ag-theme-alpine" style={{ width: "100%", height: "50vh", marginTop: "16px" }}>
                        <AgGridReact
                            rowData={tableViewRows}
                            columnDefs={modifiedColumnDefs}
                            animateRows
                            localeText={localeText}
                            alwaysShowHorizontalScroll
                            alwaysShowVerticalScroll
                            suppressMenuHide
                        />
                    </div>
                    <div className="flex gap8 jc-flex-end mt8">
                        <Button
                            Icon={downloadIcon}
                            onClick={() => downloadCSV(idMapper(api.exportReferencestatisticsById, tableView[0].Id))}
                        >
                            {REFERENCESTATISTICS_TEXTS.BUTTON_EXPORT_TABLE}
                        </Button>
                        <Button
                            Icon={downloadIcon}
                            onClick={() =>
                                downloadCSV(idMapper(api.exportReferencestatisticsForImportById, tableView[0].Id))
                            }
                        >
                            {REFERENCESTATISTICS_TEXTS.BUTTON_EXPORT_TABLE_IMPORT}
                        </Button>
                        {agvAdmin && importedByAgv && (
                            <Button
                                Icon={deleteIcon}
                                onClick={() => setShowWarning(true)}
                                disabled={showWarning}
                                variant="delete"
                            >
                                {REFERENCESTATISTICS_TEXTS.BUTTON_REMOVE}
                            </Button>
                        )}
                        {HR && !importedByAgv && (
                            <Button
                                Icon={deleteIcon}
                                onClick={() => setShowWarning(true)}
                                disabled={showWarning}
                                variant="delete"
                            >
                                {REFERENCESTATISTICS_TEXTS.BUTTON_REMOVE}
                            </Button>
                        )}
                    </div>
                </div>
            )}
            {showWarning && tableView && (
                <Warning
                    onClick={() => handleDeleteComparisonstatistics(tableView[0].Id)}
                    showWarning={setShowWarning}
                    variant="delete"
                >
                    {REFERENCESTATISTICS_TEXTS.WARNING_DELETE}
                </Warning>
            )}
            {postError && <Error>{postError}</Error>}
        </>
    )
}

export default ReferenceStatisticsContent
