import "./Header.css"

import { Link } from "react-router-dom"

import { ALT_TEXTS } from "constants/text.constants"

import deleteAllCookies from "utils/deleteAllCookies"

import logoutIcon from "./icons/logout_40.icon.svg"
import profileIcon from "./icons/profile_40.icon.svg"
import analyskraftLogo from "./analyskraft_logo.png"

type HeaderProps = {
    setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
}

const handleLogout = (setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>) => {
    deleteAllCookies()
    localStorage.clear()
    setLoggedIn(false)
    // Instead of reload a finer solution would be to set rolesContext to default values
    // Reload is a "cheap" solution because this clears all the context automatically
    window.location.reload()
}

const Header = ({ setLoggedIn }: HeaderProps) => {
    const username = localStorage.getItem("username")
    if (!username) setLoggedIn(false)

    return (
        <header>
            <Link to="/">
                <img src={analyskraftLogo} alt={ALT_TEXTS.TO_HOMEPAGE_OF_ANALYSKRAFT} />
            </Link>
            {username && (
                <div className="loggedInCountainer">
                    <span>{username}</span>
                    <img src={profileIcon} alt="" className="profileIcon" />
                    <button type="button" onClick={() => handleLogout(setLoggedIn)} className="logoutButton">
                        <img src={logoutIcon} alt={ALT_TEXTS.LOG_OUT} className="logoutIcon" />
                    </button>
                </div>
            )}
        </header>
    )
}

export default Header
