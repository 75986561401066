import "components/main/pages/account/createAccount/CreateAccountPage.css"

import api from "constants/endpoints.constants"
import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import GENERAL_TEXTS, { MAIN_ACCOUNT_TEXTS } from "constants/text.constants"

import React, { useState } from "react"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { createAccountValidationSchema } from "utils/yupSchemas"
import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf } from "services/apiService"

import Input from "components/atoms/input/Input"
import Button from "components/atoms/button/Button"
import Error from "components/atoms/error/Error"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"

import { ReactComponent as CirclePlusIcon } from "assets/circle-plus-solid.icon.svg"

const CreateAccountPage = () => {
    const [email, setEmail] = useState("")
    const [accountName, setAccountName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")

    const [errorMessage, setErrorMessage] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)

    const [validationError, setValidationError] = useState({
        name: false,
        firstName: false,
        lastName: false,
        email: false,
        mobile: false,
    })
    const [creatingNewAccount, setCreatingNewAccount] = useState(false)

    const handelSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const requestBody = { name: accountName, firstName, lastName, email, mobile: mobileNumber }

        createAccountValidationSchema
            .validate(requestBody, { abortEarly: false })
            .then(() => {
                setCreatingNewAccount(true)
                prepareCsrfToken().then((csrfToken) =>
                    postWithBodyAndCsrf(api.postMainAccount, csrfToken, JSON.stringify(requestBody))
                        .then(() => {
                            setCreatingNewAccount(false)
                            setShowConfirmation(true)
                        })
                        .catch(() => {
                            setCreatingNewAccount(false)
                            setErrorMessage(MAIN_ACCOUNT_TEXTS.ERROR_CREATE)
                        })
                )
            })
            .catch((valErr) => {
                type path = "name" | "firstName" | "lastName" | "email" | "mobile"

                valErr.inner.forEach((error: { path: path }) =>
                    setValidationError({ ...validationError, [error.path]: true })
                )
            })
    }

    useUpdatePageTitle(pageTitles.CREATE_MAIN_ACCOUNT_PAGE_TITLE)
    useScrollToTop()

    return (
        <div>
            <PageHeader pageId={pageIds.CREATE_MAIN_ACCOUNT_PAGE_ID} />
            {errorMessage && <Error>{errorMessage}</Error>}
            {showConfirmation && <Confirmation>{MAIN_ACCOUNT_TEXTS.CONFIRMATION_CREATED}</Confirmation>}
            {!errorMessage && !showConfirmation && (
                <form className="createMainAccountForm" onSubmit={handelSubmitForm}>
                    <label htmlFor="account">{MAIN_ACCOUNT_TEXTS.CREATE_NAME_LABEL}</label>
                    <Input
                        type="text"
                        id="account"
                        name="account"
                        placeholder={MAIN_ACCOUNT_TEXTS.CREATE_NAME_PLACEHOLDER}
                        onChange={(e) => setAccountName(e.target.value)}
                        required
                    />

                    <label htmlFor="firstname">{MAIN_ACCOUNT_TEXTS.CREATE_USERNAME}</label>
                    <Input
                        type="text"
                        name="firstname"
                        id="firstname"
                        placeholder={MAIN_ACCOUNT_TEXTS.CREATE_USERNAME}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />

                    <label htmlFor="efternamn">{MAIN_ACCOUNT_TEXTS.CREATE_LASTNAME}</label>
                    <Input
                        type="text"
                        name="efternamn"
                        id="efternamn"
                        placeholder={MAIN_ACCOUNT_TEXTS.CREATE_LASTNAME}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />

                    <label htmlFor="email">{MAIN_ACCOUNT_TEXTS.CREATE_EMAIL}</label>
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        placeholder={MAIN_ACCOUNT_TEXTS.CREATE_EMAIL}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        isErroneous={validationError.email}
                        errorMessage={GENERAL_TEXTS.VALIDATION_EMAIL}
                    />

                    <label htmlFor="mobileNumber">{MAIN_ACCOUNT_TEXTS.CREATE_MOBILE}</label>
                    <Input
                        type="tel"
                        id="mobileNumber"
                        name="mobileNumber"
                        placeholder={MAIN_ACCOUNT_TEXTS.CREATE_MOBILE}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        required
                        isErroneous={validationError.mobile}
                        errorMessage={GENERAL_TEXTS.VALIDATION_MOBILE}
                    />

                    <div className="checkboxContainer">
                        <label className="checkboxLabel" htmlFor="checkbox">
                            {MAIN_ACCOUNT_TEXTS.CHECKBOX_LABEL}
                        </label>
                        <input type="checkbox" name="checkbox" id="checkbox" required />
                    </div>

                    <Button type="submit" variant="default" Icon={CirclePlusIcon} isLoading={creatingNewAccount}>
                        {MAIN_ACCOUNT_TEXTS.BUTTON_CREATE}
                    </Button>
                </form>
            )}
        </div>
    )
}
export default CreateAccountPage
