import "./ManageSelection.css"

import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"

import { AgGridReact } from "ag-grid-react"
import { FirstDataRenderedEvent, RowClickedEvent, ValueGetterParams } from "ag-grid-community"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_SALARY_DISTRIBUTION } from "constants/text.constants"

import { useState, useEffect, useCallback, useRef, useMemo } from "react"
import prepareCsrfToken from "utils/prepareCsrfToken"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

import { postWithBodyAndCsrf } from "services/apiService"
import { fetchGroups } from "services/fetchers"

import { dispatchNumber, IRegisterGroup, salaryGroup } from "types/sharedTypes"

import Error from "components/atoms/error/Error"
import Input from "components/atoms/input/Input"
import Button from "components/atoms/button/Button"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import FilterRadioButtonsRow from "components/cores/filterRadioButtonsRow/FilterRadioButtonsRow"

import { ReactComponent as SaveIcon } from "assets/save.icon.svg"
import { ReactComponent as UpdateIcon } from "assets/update.icon.svg"
import { ReactComponent as CleanIcon } from "assets/broom-cleaning-icon.svg"

interface IManageSelections {
    registryId: string
    preselectedGroups: salaryGroup[] | []
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
    selectedGroups: string[]
    setSelectedGroups: React.Dispatch<React.SetStateAction<string[]>>
    diagramType: number
    setDiagramType: dispatchNumber
    salaryType: number
    setSalaryType: dispatchNumber
    savedSelection: { name: string; id: string }
}

const ManageSelection = ({
    registryId,
    preselectedGroups,
    setActiveSteps,
    selectedGroups,
    setSelectedGroups,
    diagramType,
    setDiagramType,
    salaryType,
    setSalaryType,
    savedSelection,
}: IManageSelections) => {
    const { S2_TH1, S2_TH2, S2_TH3 } = ANALYZE_SALARY_DISTRIBUTION

    const salaryTypes = ["", "Grundlön", "", "", "Fast lön", "Totallön"]
    const diagramTypes = ["", "Lådagram", "Punktdiagram"]

    const salaryTypeInitial = salaryType ? [salaryTypes[salaryType]] : []
    const diagramTypeInitial = diagramType ? [diagramTypes[diagramType]] : []

    const [fetchError, setFetchError] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")
    const [savingSelection, setSavingSelection] = useState(false)
    const [updatingSelection, setUpdatingSelection] = useState(false)

    const [registerGroups, setRegisterGroups] = useState<IRegisterGroup[]>([])
    const [fetchingRegisterGroups, setFetchingRegisterGroups] = useState(true)

    const [selectedTypeOfSalary, setSelectedTypeOfSalary] = useState(salaryTypeInitial)
    const [selectedDiagramType, setSelectedDiagramType] = useState(diagramTypeInitial)
    const [selectionName, setSelectionName] = useState(savedSelection.name)
    // Can remove this state and use selectedGroups, setSelectedGroups instead -> less complexity
    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>(selectedGroups)

    const gridRef = useRef<AgGridReact<salaryGroup>>(null)
    // ooouf, I love ag grid <3
    const [columnDefs] = useState([
        {
            field: "Name",
            headerName: S2_TH1,
            flex: 1,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            sortable: true,
            filter: true,
        },
        {
            field: "GroupType",
            headerName: S2_TH2,
            valueGetter: (params: ValueGetterParams) => params.data.GroupType.Value,
            flex: 1,
            sortable: true,
            filter: true,
        },
        { field: "Count", headerName: S2_TH3, flex: 0.5, sortable: true, filter: true },
    ])

    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    const onSelectionChanged = (event: RowClickedEvent) => {
        const groupIds: string[] = []

        const selectedRows = event.api.getSelectedRows()
        selectedRows.forEach((group: IRegisterGroup) => {
            groupIds.push(group.Id)
        })

        setSelectedGroupIds(groupIds)
        setSelectedGroups(groupIds)
    }

    const onFirstDataRendered = useCallback(
        (params: FirstDataRenderedEvent<salaryGroup>) => {
            // If we come back from step 3
            if (selectedGroupIds.length) {
                gridRef.current?.api.forEachNode((node) =>
                    node.setSelected(!!node.data && selectedGroupIds.includes(node.data.Id))
                )
            } else {
                // If we come from step 1 by saved selection
                const preselectedGroupNames: string[] = []
                preselectedGroups.forEach((group) => preselectedGroupNames.push(group.Name))
                gridRef.current?.api.forEachNode((node) =>
                    node.setSelected(!!node.data && preselectedGroupNames.includes(node.data.Name))
                )
            }
        },
        [preselectedGroups, selectedGroupIds]
    )

    const deselectAllRows = () => gridRef.current?.api.forEachNode((node) => node.setSelected(false))

    const saveSelection = (id = "") => {
        if (id) setUpdatingSelection(true)
        else setSavingSelection(true)

        const body = {
            Id: id,
            RegistryId: registryId,
            SalaryType: salaryType,
            Name: selectionName,
            DiagramType: diagramType,
            GroupIds: selectedGroupIds,
        }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.analyzeSaveSalarySelection, csrfToken, JSON.stringify(body))
                .then(() => {
                    setSavingSelection(false)
                    setUpdatingSelection(false)
                    setShowConfirmation(true)
                    setPostError("")
                })
                .catch((err) => {
                    setSavingSelection(false)
                    setUpdatingSelection(false)
                    setPostError("")
                    if (err.status === 403) {
                        err.text().then((text: string) => {
                            if (text === "UnvalidName")
                                setPostError(ANALYZE_SALARY_DISTRIBUTION.S2_SAVE_SELECTION_INVALID_NAME)
                            else setPostError(ANALYZE_SALARY_DISTRIBUTION.S2_SAVE_SELECTION_ERROR)
                        })
                    } else setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const notChanged = () =>
        !selectionName || !selectedTypeOfSalary[0] || !selectedDiagramType[0] || !selectedGroupIds[0]

    // Update states when you click filterButtons
    useEffect(() => {
        const salType = salaryTypes.findIndex((element) => element === selectedTypeOfSalary[0])
        setSalaryType(salType)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTypeOfSalary])
    useEffect(() => {
        const diagType = diagramTypes.findIndex((element) => element === selectedDiagramType[0])
        setDiagramType(diagType)
        if (diagType === 2) deselectAllRows()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDiagramType])
    useEffect(() => {
        if (selectedDiagramType.length && selectedTypeOfSalary.length && selectedGroupIds.length)
            setActiveSteps([1, 2, 3])
        else setActiveSteps([1, 2])
    }, [selectedDiagramType, selectedGroupIds.length, selectedTypeOfSalary, setActiveSteps])

    useEffect(() => {
        fetchGroups(
            registryId,
            setRegisterGroups,
            undefined,
            undefined,
            undefined,
            setFetchingRegisterGroups,
            setFetchError
        )
    }, [registryId])

    return (
        <>
            {fetchingRegisterGroups && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingRegisterGroups && !registerGroups.length && <p>{ANALYZE_SALARY_DISTRIBUTION.S2_NO_GROUPS}</p>}
            {!fetchingRegisterGroups && !fetchError && registerGroups.length && (
                <div>
                    <h2>{ANALYZE_SALARY_DISTRIBUTION.S2_H2}</h2>
                    <div className="mb40">
                        <p>{ANALYZE_SALARY_DISTRIBUTION.S2_EXPLANATION}</p>
                    </div>
                    {showConfirmation && (
                        <div className="mb24">
                            <Confirmation>{ANALYZE_SALARY_DISTRIBUTION.S2_CONFIRMATION_SAVED_SELECTION}</Confirmation>
                        </div>
                    )}
                    {postError && (
                        <div className="mb24">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    <div className="flex column-gap72 wrap">
                        <div>
                            <p className="labelAlike">{ANALYZE_SALARY_DISTRIBUTION.S2_LABEL}</p>
                            <FilterRadioButtonsRow
                                setSelection={setSelectedTypeOfSalary}
                                selectedFilter={selectedTypeOfSalary}
                            >
                                {ANALYZE_SALARY_DISTRIBUTION.S2_SALARY_TYPE_1}
                                {ANALYZE_SALARY_DISTRIBUTION.S2_SALARY_TYPE_4}
                                {ANALYZE_SALARY_DISTRIBUTION.S2_SALARY_TYPE_5}
                            </FilterRadioButtonsRow>
                        </div>
                        <div>
                            <p className="labelAlike">{ANALYZE_SALARY_DISTRIBUTION.S2_LABEL_DIAGRAM_TYPE}</p>
                            <FilterRadioButtonsRow
                                setSelection={setSelectedDiagramType}
                                selectedFilter={selectedDiagramType}
                            >
                                {ANALYZE_SALARY_DISTRIBUTION.S2_DIAGRAM_TYPE_1}
                                {ANALYZE_SALARY_DISTRIBUTION.S2_DIAGRAM_TYPE_2}
                            </FilterRadioButtonsRow>
                        </div>
                    </div>
                    <div className="maxWidth300">
                        <label className="gray" htmlFor="createSelection">
                            {ANALYZE_SALARY_DISTRIBUTION.S2_LABEL_NAME}
                        </label>
                        <Input
                            type="text"
                            id="createSelection"
                            name="createSelection"
                            value={selectionName}
                            onChange={(e) => setSelectionName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <div
                            className="ag-theme-alpine"
                            style={{ width: "100%", height: "600px", marginBottom: "64px" }}
                        >
                            <h3 className="hInsideAgGrid">{ANALYZE_SALARY_DISTRIBUTION.S2_CAPTION_GROUPS}</h3>
                            <AgGridReact
                                ref={gridRef}
                                rowData={registerGroups}
                                columnDefs={columnDefs}
                                localeText={localeText}
                                rowSelection={diagramType === 1 ? "multiple" : "single"}
                                onFirstDataRendered={onFirstDataRendered}
                                onSelectionChanged={onSelectionChanged}
                                rowMultiSelectWithClick
                                alwaysShowHorizontalScroll
                                alwaysShowVerticalScroll
                                suppressMenuHide
                            />
                        </div>
                    </div>
                    <div className="flex jc-space-between">
                        <Button onClick={deselectAllRows} disabled={selectedGroupIds.length === 0} Icon={CleanIcon}>
                            {ANALYZE_SALARY_DISTRIBUTION.S2_BUTTON_CLEAR_SELECTION}
                        </Button>
                        <div className="flex gap8">
                            {savedSelection.id && (
                                <Button
                                    Icon={UpdateIcon}
                                    disabled={notChanged()}
                                    onClick={() => saveSelection(savedSelection.id)}
                                    isLoading={updatingSelection}
                                >
                                    {ANALYZE_SALARY_DISTRIBUTION.S2_BUTTON_UPDATE_SELECTION}
                                </Button>
                            )}
                            <Button
                                Icon={SaveIcon}
                                disabled={notChanged()}
                                onClick={() => saveSelection()}
                                isLoading={savingSelection}
                            >
                                {ANALYZE_SALARY_DISTRIBUTION.S2_BUTTON_SAVE_SELECTION}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default ManageSelection
